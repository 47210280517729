import { lang } from "../common/lang_enum";

const faq_install_app = {
    [lang.RU]: `Установите приложение:`,
    [lang.EN]: `Install application:`,
    [lang.DE]: `Installieren Sie die Anwendung:`,
}

const faq_download_config = {
    [lang.RU]: `Скачайте файл конфигурации с сайта или Telegram-бота:`,
    [lang.EN]: `Download configuration file from website or Telegram bot:`,
    [lang.DE]: `Laden Sie die Konfigurationsdatei vom der Website oder Telegram-Bot herunter:`,
}

const faq_website = {
    [lang.RU]: `Сайт`,
    [lang.EN]: `Website`,
    [lang.DE]: `Webseite`,
}

const faq_import_and_connect = {
    [lang.RU]: `Импортируйте файл конфигурации и подключитесь:`,
    [lang.EN]: `Import configuration file and connect:`,
    [lang.DE]: `Importieren Sie die Konfigurationsdatei und verbinden Sie:`,
}

const faq_choose_file = {
    [lang.RU]: `Выберите загруженный файл конфигурации или скопируйте его содержимое и подключитесь`,
    [lang.EN]: `Choose downloaded configuration file or copy file content and connect`,
    [lang.DE]: `Wählen Sie die heruntergeladene Konfigurationsdatei oder kopieren Sie den Dateiinhalt und stellen Sie eine Verbindung her`,
}

const faq_contact_support = {
    [lang.RU]: `Если Вам нужна помощь с импортом файла конфигурации, обращайтесь в нашу поддержку`,
    [lang.EN]: `Feel free to contact our support in case you need assistance with importing configuration file`,
    [lang.DE]: `Wenn Sie Hilfe beim Importieren der Konfigurationsdatei benötigen, können Sie sich gerne an unseren Support wenden`,
}

const faq_note_shadowsocks_windows = {
    [lang.RU]: `Windows: скопируйте содержимое конфигурации. Щелкните правой кнопкой мыши значок в трее -> "Серверы" -> "Импорт адреса из буфера обмена". "Системный прокси-сервер" -> "Для всей системы".`,
    [lang.EN]: `Windows: Copy config contents. Right click tray icon -> "Servers" -> "Import URL from clipboard". "System Proxy" -> "Global".`,
    [lang.DE]: `Windows: Konfigurationsinhalte kopieren. Klicken Sie mit der rechten Maustaste auf das Taskleistensymbol -> "Server" -> "Import URL from clipboard". "System-Proxy" -> "Global".`,
}

const faq_note_nekoray = {
    [lang.RU]: `Nekoray: Скопируйте содержимое файла конфигурации. Щелкните правой кнопкой мыши -> "Add profile from clipboard". Щелкните правой кнопкой мыши по профилю -> "Start". Нажмите "System Proxy" в правом верхнем углу.`,
    [lang.EN]: `Nekoray: Copy configuration file contents. Right click -> "Add profile from clipboard". Right click on profile -> "Start". Click "System Proxy" in top right corner.`,
    [lang.DE]: `Nekoray: Inhalte der Konfigurationsdatei kopieren. Rechtsklick -> "Add profile from clipboard". Klicken Sie mit der rechten Maustaste auf Profil -> "Start". Klicken Sie oben rechts auf "System-Proxy".`,
}

const faq_openvpn_long = {
    [lang.RU]: `Благодаря своим надежным функциям безопасности, гибкости и широкому спектру приложений OpenVPN стал одним из самых популярных и надёжных VPN протоколов.
Вы легко найдёте подходящее приложение-клиент на любую платформу, а подключение не займёт много времени.`,
    [lang.EN]: `OpenVPN is an open-source software application that implements virtual private network (VPN) techniques for creating secure point-to-point or site-to-site connections in routed or bridged configurations and remote access facilities. OpenVPN has become one of the most popular choices for creating secure VPN connections due to its robust security features, flexibility, and wide range of applications. It is widely adopted in various industries for ensuring private and encrypted communication over the internet. Here are some key points about OpenVPN:`,
    [lang.DE]: `OpenVPN ist eine Open-Source-Softwareanwendung, die VPN-Techniken (Virtual Private Network) zum Erstellen sicherer Punkt-zu-Punkt- oder Site-to-Site-Verbindungen in gerouteten oder überbrückten Konfigurationen und Fernzugriffseinrichtungen implementiert. OpenVPN ist aufgrund seiner robusten Sicherheitsfunktionen, seiner Flexibilität und seines breiten Anwendungsspektrums zu einer der beliebtesten Optionen für die Erstellung sicherer VPN-Verbindungen geworden. Es wird in verschiedenen Branchen weithin eingesetzt, um private und verschlüsselte Kommunikation über das Internet sicherzustellen. Hier sind einige wichtige Punkte zu OpenVPN:`,
}

const faq_openvpn_1_title = {
    [lang.RU]: `Open-Source`,
    [lang.EN]: `Open-Source`,
    [lang.DE]: `Open-Source`,
}
const faq_openvpn_1_text = {
    [lang.RU]: `OpenVPN имеет открытый исходный код, то есть его исходный код находится в свободном доступе. Это позволяет пользователям просматривать и проверять код на предмет безопасности и прозрачности.`,
    [lang.EN]: `OpenVPN is open-source, meaning its source code is freely available to the public. This allows users to review and audit the code for security and transparency.`,
    [lang.DE]: `OpenVPN ist Open Source, das heißt, sein Quellcode ist für die Öffentlichkeit frei verfügbar. Dadurch können Benutzer den Code auf Sicherheit und Transparenz überprüfen und prüfen.`,
}

const faq_openvpn_2_title = {
    [lang.RU]: `Безопасное соединение`,
    [lang.EN]: `Secure Connection`,
    [lang.DE]: `Sichere Verbindung`,
}
const faq_openvpn_2_text = {
    [lang.RU]: `Он использует собственный протокол безопасности, который использует SSL/TLS для обмена ключами. Это делает его очень безопасным и подходящим для различных приложений, где конфиденциальность и целостность данных имеют решающее значение.`,
    [lang.EN]: `It uses a custom security protocol that utilizes SSL/TLS for key exchange. This makes it highly secure and suitable for various applications where privacy and data integrity are crucial.`,
    [lang.DE]: `Es verwendet ein benutzerdefiniertes Sicherheitsprotokoll, das SSL/TLS für den Schlüsselaustausch nutzt. Dies macht es äußerst sicher und eignet sich für verschiedene Anwendungen, bei denen Datenschutz und Datenintegrität von entscheidender Bedeutung sind.`,
}

const faq_openvpn_3_title = {
    [lang.RU]: `Межплатформенная совместимость`,
    [lang.EN]: `Cross-Platform Compatibility`,
    [lang.DE]: `Plattformübergreifende Kompatibilität`,
}
const faq_openvpn_3_text = {
    [lang.RU]: `OpenVPN совместим с различными операционными системами, включая Windows, macOS, Linux, iOS и Android. Эта кросс-платформенная поддержка делает его широко доступным для пользователей на разных устройствах.`,
    [lang.EN]: `OpenVPN is compatible with various operating systems, including Windows, macOS, Linux, iOS, and Android. This cross-platform support makes it widely accessible for users on different devices.`,
    [lang.DE]: `OpenVPN ist mit verschiedenen Betriebssystemen kompatibel, darunter Windows, macOS, Linux, iOS und Android. Diese plattformübergreifende Unterstützung macht es für Benutzer auf verschiedenen Geräten allgemein zugänglich.`,
}

const faq_openvpn_4_title = {
    [lang.RU]: `Портативность`,
    [lang.EN]: `Portability`,
    [lang.DE]: `Portabilität`,
}
const faq_openvpn_4_text = {
    [lang.RU]: `Он работает через стандартные порты TCP и UDP, что делает его безопасным для брандмауэра. Использование этих общих портов позволяет трафику OpenVPN без проблем проходить через большинство сетевых конфигураций.`,
    [lang.EN]: `It operates over the standard TCP and UDP ports, making it firewall-friendly. The use of these common ports allows OpenVPN traffic to pass through most network configurations without issues.`,
    [lang.DE]: `Es arbeitet über die Standard-TCP- und UDP-Ports und ist daher Firewall-freundlich. Durch die Verwendung dieser gemeinsamen Ports kann der OpenVPN-Verkehr die meisten Netzwerkkonfigurationen problemlos passieren.`,
}

const faq_openvpn_5_title = {
    [lang.RU]: `Поддержка сообщества`,
    [lang.EN]: `Community Support`,
    [lang.DE]: `Gemeinschaftliche Unterstützung`,
}
const faq_openvpn_5_text = {
    [lang.RU]: `Будучи открытым исходным кодом, OpenVPN имеет сильное сообщество разработчиков и пользователей, которые вносят свой вклад в его развитие и оказывают поддержку. Это активное сообщество обеспечивает постоянные улучшения и обновления.`,
    [lang.EN]: `Being open-source, OpenVPN has a strong community of developers and users who contribute to its development and provide support. This active community ensures ongoing improvements and updates.`,
    [lang.DE]: `Da OpenVPN Open-Source ist, verfügt es über eine starke Community von Entwicklern und Benutzern, die zu seiner Entwicklung beitragen und Unterstützung leisten. Diese aktive Community sorgt für laufende Verbesserungen und Updates.`,
}

const faq_openvpn_6_title = {
    [lang.RU]: `SSL/TLS шифрование`,
    [lang.EN]: `SSL/TLS Encryption`,
    [lang.DE]: `SSL/TLS Verschlüsselung`,
}
const faq_openvpn_6_text = {
    [lang.RU]: `OpenVPN использует протокол SSL/TLS для обмена ключами и аутентификации. Это помогает установить безопасное и зашифрованное соединение между клиентом и сервером.`,
    [lang.EN]: `OpenVPN uses the SSL/TLS protocol for key exchange and authentication. This helps establish a secure and encrypted connection between the client and the server.`,
    [lang.DE]: `OpenVPN verwendet das SSL/TLS-Protokoll für den Schlüsselaustausch und die Authentifizierung. Dies hilft dabei, eine sichere und verschlüsselte Verbindung zwischen dem Client und dem Server herzustellen.`,
}


const faq_wireguard_long = {
    [lang.RU]: `WireGuard — это современный и легкий протокол VPN (виртуальная частная сеть) с открытым исходным кодом и программная реализация, разработанная для простоты использования, высокой производительности и надежной безопасности. WireGuard завоевал популярность благодаря своему инновационному подходу к технологии VPN, предоставляющему оптимизированное и эффективное решение для безопасной связи через Интернет. Его простота, высокая производительность и надежные функции безопасности делают его привлекательным выбором для многих пользователей и организаций. Вот некоторые ключевые функции и аспекты WireGuard:`,
    [lang.EN]: `WireGuard is a modern and lightweight open-source VPN (Virtual Private Network) protocol and software implementation designed for ease of use, high performance, and strong security. WireGuard has gained popularity for its innovative approach to VPN technology, providing a streamlined and efficient solution for secure communication over the internet. Its simplicity, high performance, and strong security features make it a compelling choice for many users and organizations. Here are some key features and aspects of WireGuard:`,
    [lang.DE]: `WireGuard ist ein modernes und leichtes Open-Source-VPN-Protokoll (Virtual Private Network) und eine Softwareimplementierung, die auf Benutzerfreundlichkeit, hohe Leistung und hohe Sicherheit ausgelegt ist. WireGuard erfreut sich aufgrund seines innovativen Ansatzes bei der VPN-Technologie großer Beliebtheit und bietet eine optimierte und effiziente Lösung für die sichere Kommunikation über das Internet. Seine Einfachheit, hohe Leistung und starken Sicherheitsfunktionen machen es für viele Benutzer und Organisationen zu einer überzeugenden Wahl. Hier sind einige wichtige Funktionen und Aspekte von WireGuard:`,
}

const faq_wireguard_1_title = {
    [lang.RU]: `Простота`,
    [lang.EN]: `Simplicity`,
    [lang.DE]: `Einfachheit`,
}
const faq_wireguard_1_text = {
    [lang.RU]: `WireGuard прост и удобен в использовании. Его кодовая база значительно меньше по сравнению с традиционными протоколами VPN, что упрощает понимание, аудит и обслуживание.`,
    [lang.EN]: `WireGuard is designed to be simple and easy to use. Its codebase is significantly smaller compared to traditional VPN protocols, making it easier to understand, audit, and maintain.`,
    [lang.DE]: `WireGuard ist so konzipiert, dass es einfach und benutzerfreundlich ist. Seine Codebasis ist im Vergleich zu herkömmlichen VPN-Protokollen deutlich kleiner, was das Verständnis, die Prüfung und die Wartung erleichtert.`,
}

const faq_wireguard_2_title = {
    [lang.RU]: `Производительность`,
    [lang.EN]: `Performance`,
    [lang.DE]: `Leistung`,
}
const faq_wireguard_2_text = {
    [lang.RU]: `WireGuard известен своей высокой производительностью. Он разработан так, чтобы работать быстрее, чем многие традиционные протоколы VPN, обеспечивая эффективную связь с малой задержкой.`,
    [lang.EN]: `WireGuard is known for its high performance. It is designed to be faster than many traditional VPN protocols, providing efficient and low-latency communication.`,
    [lang.DE]: `WireGuard ist für seine hohe Leistung bekannt. Es ist schneller als viele herkömmliche VPN-Protokolle und ermöglicht eine effiziente Kommunikation mit geringer Latenz.`,
}

const faq_wireguard_3_title = {
    [lang.RU]: `Безопасность`,
    [lang.EN]: `Security`,
    [lang.DE]: `Sicherheit`,
}
const faq_wireguard_3_text = {
    [lang.RU]: `WireGuard использует самые современные криптографические протоколы для обеспечения безопасности. Он опирается на структуру протокола Noise для обмена ключами и шифрования, обеспечивая прочную основу для безопасной связи.`,
    [lang.EN]: `WireGuard uses state-of-the-art cryptographic protocols to ensure security. It relies on the Noise protocol framework for key exchange and encryption, providing a solid foundation for secure communication.`,
    [lang.DE]: `WireGuard verwendet modernste kryptografische Protokolle, um die Sicherheit zu gewährleisten. Es basiert auf dem Noise-Protokoll-Framework für den Schlüsselaustausch und die Verschlüsselung und bietet eine solide Grundlage für sichere Kommunikation.`,
}

const faq_wireguard_4_title = {
    [lang.RU]: `Минимальная поверхность атаки`,
    [lang.EN]: `Minimal Attack Surface`,
    [lang.DE]: `Minimale Angriffsfläche`,
}
const faq_wireguard_4_text = {
    [lang.RU]: `Благодаря минимальной кодовой базе WireGuard имеет меньшую поверхность атаки по сравнению со старыми протоколами VPN. Это может способствовать повышению безопасности, поскольку существует меньше потенциальных уязвимостей.`,
    [lang.EN]: `Due to its minimal codebase, WireGuard has a smaller attack surface compared to older VPN protocols. This can contribute to better security as there are fewer potential vulnerabilities.`,
    [lang.DE]: `Aufgrund seiner minimalen Codebasis verfügt WireGuard im Vergleich zu älteren VPN-Protokollen über eine kleinere Angriffsfläche. Dies kann zu einer besseren Sicherheit beitragen, da weniger potenzielle Schwachstellen bestehen.`,
}

const faq_wireguard_5_title = {
    [lang.RU]: `Межплатформенная совместимость`,
    [lang.EN]: `Cross-Platform Compatibility`,
    [lang.DE]: `Plattformübergreifende Kompatibilität`,
}
const faq_wireguard_5_text = {
    [lang.RU]: `WireGuard поддерживается в различных операционных системах, включая Linux, Windows, macOS, Android и iOS. Его доступность на разных платформах делает его универсальным для пользователей с различными устройствами.`,
    [lang.EN]: `WireGuard is supported on various operating systems, including Linux, Windows, macOS, Android, and iOS. Its availability across different platforms makes it versatile for users with diverse devices.`,
    [lang.DE]: `WireGuard wird auf verschiedenen Betriebssystemen unterstützt, darunter Linux, Windows, macOS, Android und iOS. Seine Verfügbarkeit auf verschiedenen Plattformen macht es für Benutzer mit unterschiedlichen Geräten vielseitig einsetzbar.`,
}

const faq_wireguard_6_title = {
    [lang.RU]: `Быстрое рукопожатие`,
    [lang.EN]: `Quick Handshake`,
    [lang.DE]: `Schneller Händedruck`,
}
const faq_wireguard_6_text = {
    [lang.RU]: `WireGuard стремится быстро устанавливать соединения. Процесс рукопожатия разработан таким образом, чтобы быть эффективным и способствовать уменьшению задержки соединения.`,
    [lang.EN]: `WireGuard aims to establish connections quickly. The handshake process is designed to be efficient, contributing to reduced connection latency.`,
    [lang.DE]: `WireGuard zielt darauf ab, Verbindungen schnell herzustellen. Der Handshake-Prozess ist auf Effizienz ausgelegt und trägt zu einer geringeren Verbindungslatenz bei.`,
}

const faq_wireguard_7_title = {
    [lang.RU]: `Активное развитие`,
    [lang.EN]: `Active development`,
    [lang.DE]: `Aktive Entwicklung`,
}
const faq_wireguard_7_text = {
    [lang.RU]: `WireGuard активно развивается и поддерживается. Его разработка прозрачна, обсуждения и предложения происходят открыто в публичных списках рассылки. Это способствует постоянным улучшениям и повышению безопасности.`,
    [lang.EN]: `WireGuard is actively developed and maintained. Its development is transparent, with discussions and contributions happening in the open on public mailing lists. This contributes to ongoing improvements and security enhancements.`,
    [lang.DE]: `WireGuard wird aktiv weiterentwickelt und gepflegt. Seine Entwicklung ist transparent, Diskussionen und Beiträge finden öffentlich auf öffentlichen Mailinglisten statt. Dies trägt zu kontinuierlichen Verbesserungen und Sicherheitsverbesserungen bei.`,
}

const faq_wireguard_8_title = {
    [lang.RU]: `Поддержка сообщества`,
    [lang.EN]: `Community Support`,
    [lang.DE]: `Gemeinschaftliche Unterstützung`,
}
const faq_wireguard_8_text = {
    [lang.RU]: `Сообщество WireGuard активно участвует в обсуждениях, устранении неполадок и разработке дополнительных инструментов и расширений. Эта среда совместной работы помогает решать проблемы и расширять экосистему вокруг WireGuard.`,
    [lang.EN]: `The WireGuard community actively contributes to discussions, troubleshooting, and the development of additional tools and extensions. This collaborative environment helps address issues and expand the ecosystem around WireGuard.`,
    [lang.DE]: `Die WireGuard-Community beteiligt sich aktiv an Diskussionen, Fehlerbehebungen und der Entwicklung zusätzlicher Tools und Erweiterungen. Diese kollaborative Umgebung hilft dabei, Probleme anzugehen und das Ökosystem rund um WireGuard zu erweitern.`,
}

const faq_shadowsocks_long = {
    [lang.RU]: `Shadowsocks — это легкое прокси-программное обеспечение с открытым исходным кодом, предназначенное для предоставления пользователям безопасного и конфиденциального доступа к Интернету. Первоначально он был разработан в Китае для обхода цензуры и интернет-ограничений страны, но завоевал популярность во всем мире благодаря своей эффективности и универсальности. Важно отметить, что хотя Shadowsocks может повысить конфиденциальность и предоставить доступ к ограниченному контенту, его эффективность может зависеть от конкретных действующих мер цензуры. Пользователи должны знать юридические аспекты использования таких технологий, как Shadowsocks. Вот ключевые аспекты Shadowsocks:`,
    [lang.EN]: `Shadowsocks is a lightweight and open-source proxy software designed to provide users with a secure and private way to access the internet. It was initially developed in China to bypass the country's censorship and internet restrictions, but it has gained popularity worldwide due to its effectiveness and versatility. It's important to note that while Shadowsocks can enhance privacy and provide access to restricted content, its effectiveness may depend on the specific censorship measures in place. Users should be aware of legal considerations when using technologies like Shadowsocks. Here are key aspects of Shadowsocks:`,
    [lang.DE]: `Shadowsocks ist eine leichte und Open-Source-Proxy-Software, die Benutzern eine sichere und private Möglichkeit bietet, auf das Internet zuzugreifen. Es wurde ursprünglich in China entwickelt, um die Zensur und Internetbeschränkungen des Landes zu umgehen, hat aber aufgrund seiner Wirksamkeit und Vielseitigkeit weltweit an Popularität gewonnen. Es ist wichtig zu beachten, dass Shadowsocks zwar die Privatsphäre verbessern und Zugang zu eingeschränkten Inhalten ermöglichen kann, seine Wirksamkeit jedoch von den spezifischen Zensurmaßnahmen abhängen kann. Benutzer sollten sich der rechtlichen Aspekte bewusst sein, wenn sie Technologien wie Shadowsocks verwenden. Hier sind die wichtigsten Aspekte von Shadowsocks:`,
}

const faq_shadowsocks_1_title = {
    [lang.RU]: `Прокси протокол`,
    [lang.EN]: `Proxy Protocol`,
    [lang.DE]: `Proxy Protokoll`,
}
const faq_shadowsocks_1_text = {
    [lang.RU]: `Shadowsocks работает как прокси-сервер, который облегчает передачу данных между устройством пользователя и Интернетом. Он использует собственный протокол, часто называемый «протоколом Shadowsocks», для шифрования и передачи данных.`,
    [lang.EN]: `Shadowsocks operates as a proxy server that facilitates the transfer of data between the user's device and the internet. It uses a custom protocol, often referred to as the "Shadowsocks protocol" to encrypt and transfer data.`,
    [lang.DE]: `Shadowsocks fungiert als Proxyserver, der die Datenübertragung zwischen dem Gerät des Benutzers und dem Internet erleichtert. Es verwendet ein benutzerdefiniertes Protokoll, das oft als "Shadowsocks-Protokoll" bezeichnet wird, um Daten zu verschlüsseln und zu übertragen.`,
}

const faq_shadowsocks_2_title = {
    [lang.RU]: `Цензура`,
    [lang.EN]: `Censorship`,
    [lang.DE]: `Zensur`,
}
const faq_shadowsocks_2_text = {
    [lang.RU]: `Основная цель Shadowsocks — обойти цензуру и получить доступ к контенту, который может быть ограничен или заблокирован интернет-провайдерами.`,
    [lang.EN]: `The primary purpose of Shadowsocks is to circumvent censorship and access content that might be restricted or blocked by internet service providers.`,
    [lang.DE]: `Der Hauptzweck von Shadowsocks besteht darin, die Zensur zu umgehen und auf Inhalte zuzugreifen, die möglicherweise von Internetdienstanbietern eingeschränkt oder blockiert werden.`,
}

const faq_shadowsocks_3_title = {
    [lang.RU]: `Шифрование`,
    [lang.EN]: `Encryption`,
    [lang.DE]: `Verschlüsselung`,
}
const faq_shadowsocks_3_text = {
    [lang.RU]: `Shadowsocks использует шифрование для защиты связи между клиентом и сервером. Хотя сам протокол не определяет конкретный метод шифрования, для этой цели обычно используются такие методы, как AES (расширенный стандарт шифрования).`,
    [lang.EN]: `Shadowsocks uses encryption to secure the communication between the client and the server. While the protocol itself does not specify a particular encryption method, it is common to use methods such as AES (Advanced Encryption Standard) for this purpose.`,
    [lang.DE]: `Shadowsocks verwendet Verschlüsselung, um die Kommunikation zwischen dem Client und dem Server zu sichern. Während das Protokoll selbst keine bestimmte Verschlüsselungsmethode vorgibt, werden zu diesem Zweck häufig Methoden wie AES (Advanced Encryption Standard) verwendet.`,
}

const faq_shadowsocks_4_title = {
    [lang.RU]: `Легкий и ресурсоэффективный`,
    [lang.EN]: `Lightweight and Resource-Efficient`,
    [lang.DE]: `Leicht und ressourcenschonend`,
}
const faq_shadowsocks_4_text = {
    [lang.RU]: `Одним из преимуществ Shadowsocks является его легкий вес. Он потребляет меньше системных ресурсов по сравнению с некоторыми другими решениями VPN или прокси, что делает его подходящим для устройств с ограниченной вычислительной мощностью.`,
    [lang.EN]: `One of the advantages of Shadowsocks is its lightweight nature. It consumes fewer system resources compared to some other VPN or proxy solutions, making it suitable for devices with limited processing power.`,
    [lang.DE]: `Einer der Vorteile von Shadowsocks ist ihr geringes Gewicht. Im Vergleich zu einigen anderen VPN- oder Proxy-Lösungen verbraucht es weniger Systemressourcen und eignet sich daher für Geräte mit begrenzter Rechenleistung.`,
}

const faq_shadowsocks_5_title = {
    [lang.RU]: `Развитие, управляемое сообществом`,
    [lang.EN]: `Community-Driven Development`,
    [lang.DE]: `Community-gesteuerte Entwicklung`,
}
const faq_shadowsocks_5_text = {
    [lang.RU]: `Shadowsocks — это проект с открытым исходным кодом, и его развитие осуществляется сообществом. Это означает, что разработчики со всего мира могут внести свой вклад в его улучшение и решить проблемы, создавая среду для совместной работы.`,
    [lang.EN]: `Shadowsocks is an open-source project, and its development is community-driven. This means that developers worldwide can contribute to its improvement and address issues, fostering a collaborative environment.`,
    [lang.DE]: `Shadowsocks ist ein Open-Source-Projekt und seine Entwicklung wird von der Community vorangetrieben. Dies bedeutet, dass Entwickler auf der ganzen Welt zur Verbesserung beitragen und Probleme angehen können, wodurch eine kollaborative Umgebung gefördert wird.`,
}

const faq_shadowsocks_6_title = {
    [lang.RU]: `Конфиденциальность использования`,
    [lang.EN]: `Usage Privacy`,
    [lang.DE]: `Nutzungsdatenschutz`,
}
const faq_shadowsocks_6_text = {
    [lang.RU]: `Поскольку Shadowsocks шифрует трафик между клиентом и сервером, он обеспечивает уровень конфиденциальности пользователей, защищая их данные от перехвата и мониторинга со стороны третьих лиц.`,
    [lang.EN]: `Since Shadowsocks encrypts the traffic between the client and server, it provides a level of privacy for users, protecting their data from interception and monitoring by third parties.`,
    [lang.DE]: `Da Shadowsocks den Datenverkehr zwischen Client und Server verschlüsselt, bietet es Benutzern ein gewisses Maß an Privatsphäre und schützt ihre Daten vor dem Abfangen und Überwachen durch Dritte.`,
}

const faq_vless_long = {
    [lang.RU]: `VLESS, что означает «без VMess», представляет собой протокол, используемый для связи между клиентом и сервером в контексте служб VPN (виртуальной частной сети). VLESS связан с проектом V2Ray, платформой с открытым исходным кодом, предназначенной для улучшения и предоставления дополнительных функций, выходящих за рамки возможностей других протоколов VPN. VLESS, являясь частью экосистемы V2Ray, предоставляет альтернативу другим протоколам VPN и выбирают пользователи и провайдеры, которые ценят его возможности, гибкость и эффективность для безопасной связи через Интернет. Вот некоторые ключевые моменты о VLESS:`,
    [lang.EN]: `VLESS, which stands for "VMess-less," is a protocol used for communication between a client and a server in the context of VPN (Virtual Private Network) services. VLESS is associated with the V2Ray project, an open-source platform designed to improve upon and provide additional features beyond the capabilities of other VPN protocols. VLESS, being part of the V2Ray ecosystem, provides an alternative to other VPN protocols and is chosen by users and providers who value its features, flexibility, and efficiency for secure communication over the internet. Here are some key points about VLESS:`,
    [lang.DE]: `VLESS, was für „VMess-less“ steht, ist ein Protokoll, das für die Kommunikation zwischen einem Client und einem Server im Rahmen von VPN-Diensten (Virtual Private Network) verwendet wird. VLESS ist mit dem V2Ray-Projekt verbunden, einer Open-Source-Plattform, die darauf ausgelegt ist, die Fähigkeiten anderer VPN-Protokolle zu verbessern und zusätzliche Funktionen bereitzustellen. Als Teil des V2Ray-Ökosystems stellt VLESS eine Alternative zu anderen VPN-Protokollen dar und wird von Benutzern und Anbietern gewählt, die seine Funktionen, Flexibilität und Effizienz für die sichere Kommunikation über das Internet schätzen. Hier sind einige wichtige Punkte zu VLESS:`,
}

const faq_vless_1_title = {
    [lang.RU]: `Происхождение и связь с V2Ray`,
    [lang.EN]: `Origin and Relationship with V2Ray`,
    [lang.DE]: `Herkunft und Beziehung zu V2Ray`,
}
const faq_vless_1_text = {
    [lang.RU]: `VLESS является частью проекта V2Ray, инициативы по созданию платформы, предлагающей расширенные функции конфиденциальности и безопасности для VPN-связи. V2Ray стремится предоставить более гибкую и расширяемую структуру по сравнению с традиционными протоколами VPN.`,
    [lang.EN]: `VLESS is part of the V2Ray project, an initiative to create a platform that offers enhanced privacy and security features for VPN communication. V2Ray aims to provide a more flexible and extensible framework compared to traditional VPN protocols.`,
    [lang.DE]: `VLESS ist Teil des V2Ray-Projekts, einer Initiative zur Schaffung einer Plattform, die verbesserte Datenschutz- und Sicherheitsfunktionen für die VPN-Kommunikation bietet. V2Ray zielt darauf ab, im Vergleich zu herkömmlichen VPN-Protokollen ein flexibleres und erweiterbares Framework bereitzustellen.`,
}

const faq_vless_2_title = {
    [lang.RU]: `Протокол VMess`,
    [lang.EN]: `VMess Protocol`,
    [lang.DE]: `VMess Protokoll`,
}
const faq_vless_2_text = {
    [lang.RU]: `VLESS — это вариант протокола VMess, используемый V2Ray. VMess — это универсальный и расширяемый протокол связи, поддерживающий различные функции, включая шифрование, обфускацию и мультиплексирование.`,
    [lang.EN]: `VLESS is a variant of the VMess protocol used by V2Ray. VMess is a versatile and extensible communication protocol that supports various features, including encryption, obfuscation, and multiplexing.`,
    [lang.DE]: `VLESS ist eine Variante des von V2Ray verwendeten VMess-Protokolls. VMess ist ein vielseitiges und erweiterbares Kommunikationsprotokoll, das verschiedene Funktionen unterstützt, darunter Verschlüsselung, Verschleierung und Multiplexing.`,
}

const faq_vless_3_title = {
    [lang.RU]: `Простота и эффективность`,
    [lang.EN]: `Simplicity and Efficiency`,
    [lang.DE]: `Einfachheit und Effizienz`,
}
const faq_vless_3_text = {
    [lang.RU]: `"LESS" в VLESS означает упрощенную и более эффективную реализацию. VLESS спроектирован так, чтобы быть проще и потреблять меньше ресурсов по сравнению с полным протоколом VMess, что делает его более легким вариантом для конкретных случаев использования.`,
    [lang.EN]: `The "LESS" in VLESS refers to a streamlined and more efficient implementation. VLESS is designed to be simpler and consume fewer resources compared to the full VMess protocol, making it a more lightweight option for specific use cases.`,
    [lang.DE]: `Das "LESS" in VLESS bezieht sich auf eine optimierte und effizientere Implementierung. VLESS ist im Vergleich zum vollständigen VMess-Protokoll einfacher und verbraucht weniger Ressourcen, was es zu einer leichteren Option für bestimmte Anwendungsfälle macht.`,
}

const faq_vless_4_title = {
    [lang.RU]: `Шифрование и безопасность`,
    [lang.EN]: `Encryption and Security`,
    [lang.DE]: `Verschlüsselung und Sicherheit`,
}
const faq_vless_4_text = {
    [lang.RU]: `Как и VMess, VLESS включает шифрование для защиты связи между клиентом и сервером. Он обеспечивает уровень конфиденциальности и безопасности для защиты пользовательских данных от перехвата и несанкционированного доступа.`,
    [lang.EN]: `Like VMess, VLESS incorporates encryption to secure the communication between the client and the server. It provides a layer of privacy and security to protect user data from interception and unauthorized access.`,
    [lang.DE]: `Wie VMess integriert VLESS eine Verschlüsselung, um die Kommunikation zwischen dem Client und dem Server zu sichern. Es bietet eine Ebene der Privatsphäre und Sicherheit, um Benutzerdaten vor Abfangen und unbefugtem Zugriff zu schützen.`,
}

const faq_vless_5_title = {
    [lang.RU]: `Поддержка мультиплексирования`,
    [lang.EN]: `Multiplexing Support`,
    [lang.DE]: `Multiplexing-Unterstützung`,
}
const faq_vless_5_text = {
    [lang.RU]: `VLESS поддерживает мультиплексирование, которое позволяет передавать несколько потоков данных по одному соединению. Это может способствовать повышению эффективности и уменьшению задержек при обмене данными между клиентом и сервером.`,
    [lang.EN]: `VLESS supports multiplexing, which allows multiple streams of data to be transmitted over a single connection. This can contribute to improved efficiency and reduced latency in communication between the client and the server.`,
    [lang.DE]: `VLESS unterstützt Multiplexing, wodurch mehrere Datenströme über eine einzige Verbindung übertragen werden können. Dies kann zu einer verbesserten Effizienz und einer geringeren Latenz bei der Kommunikation zwischen Client und Server beitragen.`,
}

const faq_vless_6_title = {
    [lang.RU]: `Обфускация`,
    [lang.EN]: `Obfuscation`,
    [lang.DE]: `Verschleierung`,
}
const faq_vless_6_text = {
    [lang.RU]: `Обфускация — это практика представления VPN-трафика как обычного интернет-трафика в обход мер цензуры. VLESS, как и VMess, имеет возможность запутывания, что может быть важно в регионах, где использование VPN ограничено.`,
    [lang.EN]: `Obfuscation is the practice of making VPN traffic appear as regular internet traffic to bypass censorship measures. VLESS, like VMess, has the capability for obfuscation, which can be important in regions where VPN usage is restricted.`,
    [lang.DE]: `Unter Verschleierung versteht man die Praxis, den VPN-Verkehr als normalen Internetverkehr erscheinen zu lassen, um Zensurmaßnahmen zu umgehen. VLESS verfügt wie VMess über die Fähigkeit zur Verschleierung, was in Regionen wichtig sein kann, in denen die VPN-Nutzung eingeschränkt ist.`,
}

const faq_vless_7_title = {
    [lang.RU]: `Постоянное развитие`,
    [lang.EN]: `Ongoing Development`,
    [lang.DE]: `Laufende Entwicklung`,
}
const faq_vless_7_text = {
    [lang.RU]: `Проект V2Ray, включая VLESS, активно развивается и поддерживается. Это гарантирует, что протокол будет оставаться актуальным, решая проблемы безопасности и добавляя новые функции по мере необходимости.`,
    [lang.EN]: `The V2Ray project, including VLESS, is actively developed and maintained. This ensures that the protocol stays current, addressing security concerns and incorporating new features as needed.`,
    [lang.DE]: `Das V2Ray-Projekt, einschließlich VLESS, wird aktiv weiterentwickelt und gepflegt. Dadurch wird sichergestellt, dass das Protokoll aktuell bleibt, Sicherheitsbedenken berücksichtigt und bei Bedarf neue Funktionen integriert werden.`,
}


const faq_vmess_long = {
    [lang.RU]: `VMess, что означает "Универсальный поток сообщений", представляет собой протокол связи, используемый в контексте служб VPN (виртуальной частной сети). VMess связан с проектом V2Ray, платформой с открытым исходным кодом, целью которой является предоставление расширенных функций для безопасной связи. VMess в экосистеме V2Ray выбирают пользователи и провайдеры VPN, которым нужны расширенные функции, возможности настройки и надежная безопасность своих VPN-подключений. Он обеспечивает надежную основу для создания безопасных и универсальных каналов связи через Интернет. Вот ключевые моменты о VMess:`,
    [lang.EN]: `VMess, which stands for "Versatile Messaging Stream" is a communication protocol used in the context of VPN (Virtual Private Network) services. VMess is associated with the V2Ray project, an open-source platform that aims to provide advanced features for secure communication. VMess, within the V2Ray ecosystem, is chosen by users and VPN providers who seek advanced features, customization options, and strong security for their VPN connections. It provides a robust framework for creating secure and versatile communication channels over the internet. Here are key points about VMess:`,
    [lang.DE]: `VMess, was für "Versatile Messaging Stream" steht, ist ein Kommunikationsprotokoll, das im Zusammenhang mit VPN-Diensten (Virtual Private Network) verwendet wird. VMess ist mit dem V2Ray-Projekt verbunden, einer Open-Source-Plattform, die darauf abzielt, erweiterte Funktionen für sichere Kommunikation bereitzustellen. VMess wird innerhalb des V2Ray-Ökosystems von Benutzern und VPN-Anbietern ausgewählt, die erweiterte Funktionen, Anpassungsoptionen und starke Sicherheit für ihre VPN-Verbindungen suchen. Es bietet einen robusten Rahmen für die Schaffung sicherer und vielseitiger Kommunikationskanäle über das Internet. Hier sind die wichtigsten Punkte zu VMess:`,
}

const faq_vmess_1_title = {
    [lang.RU]: `Универсальность и расширяемость`,
    [lang.EN]: `Versatility and Extensibility`,
    [lang.DE]: `Vielseitigkeit und Erweiterbarkeit`,
}
const faq_vmess_1_text = {
    [lang.RU]: `Как следует из названия, VMess спроектирован так, чтобы быть универсальным и расширяемым. Он предлагает гибкую структуру для создания VPN-подключений и поддерживает ряд функций, помимо базового шифрования, включая запутывание, мультиплексирование и многое другое.`,
    [lang.EN]: `As the name suggests, VMess is designed to be versatile and extensible. It offers a flexible framework for creating VPN connections and supports a range of features beyond basic encryption, including obfuscation, multiplexing, and more.`,
    [lang.DE]: `Wie der Name schon sagt, ist VMess vielseitig und erweiterbar. Es bietet ein flexibles Framework zum Erstellen von VPN-Verbindungen und unterstützt eine Reihe von Funktionen, die über die grundlegende Verschlüsselung hinausgehen, einschließlich Verschleierung, Multiplexing und mehr.`,
}

const faq_vmess_2_title = {
    [lang.RU]: `Проект V2Ray`,
    [lang.EN]: `V2Ray Project`,
    [lang.DE]: `V2Ray-Projekt`,
}
const faq_vmess_2_text = {
    [lang.RU]: `VMess — ключевой компонент проекта V2Ray, который был инициирован для создания платформы, выходящей за рамки возможностей традиционных протоколов VPN. V2Ray включает VMess в качестве одного из своих основных протоколов, а также другие функции для повышения конфиденциальности и безопасности.`,
    [lang.EN]: `VMess is a key component of the V2Ray project, which was initiated to create a platform that goes beyond the capabilities of traditional VPN protocols. V2Ray includes VMess as one of its core protocols, along with other features for enhanced privacy and security.`,
    [lang.DE]: `VMess ist eine Schlüsselkomponente des V2Ray-Projekts, das initiiert wurde, um eine Plattform zu schaffen, die über die Fähigkeiten herkömmlicher VPN-Protokolle hinausgeht. V2Ray umfasst VMess als eines seiner Kernprotokolle sowie weitere Funktionen für mehr Datenschutz und Sicherheit.`,
}

const faq_vmess_3_title = {
    [lang.RU]: `Поддержка мультиплексирования`,
    [lang.EN]: `Multiplexing Support`,
    [lang.DE]: `Multiplexing-Unterstützung`,
}
const faq_vmess_3_text = {
    [lang.RU]: `VLESS поддерживает мультиплексирование, которое позволяет передавать несколько потоков данных по одному соединению. Это может способствовать повышению эффективности и уменьшению задержек при обмене данными между клиентом и сервером.`,
    [lang.EN]: `VLESS supports multiplexing, which allows multiple streams of data to be transmitted over a single connection. This can contribute to improved efficiency and reduced latency in communication between the client and the server.`,
    [lang.DE]: `VLESS unterstützt Multiplexing, wodurch mehrere Datenströme über eine einzige Verbindung übertragen werden können. Dies kann zu einer verbesserten Effizienz und einer geringeren Latenz bei der Kommunikation zwischen Client und Server beitragen.`,
}

const faq_vmess_4_title = {
    [lang.RU]: `Обфускация`,
    [lang.EN]: `Obfuscation`,
    [lang.DE]: `Verschleierung`,
}
const faq_vmess_4_text = {
    [lang.RU]: `Обфускация — это практика представления VPN-трафика как обычного интернет-трафика в обход мер цензуры. VLESS, как и VMess, имеет возможность запутывания, что может быть важно в регионах, где использование VPN ограничено.`,
    [lang.EN]: `Obfuscation is the practice of making VPN traffic appear as regular internet traffic to bypass censorship measures. VLESS, like VMess, has the capability for obfuscation, which can be important in regions where VPN usage is restricted.`,
    [lang.DE]: `Unter Verschleierung versteht man die Praxis, den VPN-Verkehr als normalen Internetverkehr erscheinen zu lassen, um Zensurmaßnahmen zu umgehen. VLESS verfügt wie VMess über die Fähigkeit zur Verschleierung, was in Regionen wichtig sein kann, in denen die VPN-Nutzung eingeschränkt ist.`,
}

const faq_vmess_5_title = {
    [lang.RU]: `Постоянное развитие`,
    [lang.EN]: `Ongoing Development`,
    [lang.DE]: `Laufende Entwicklung`,
}
const faq_vmess_5_text = {
    [lang.RU]: `Проект V2Ray, включая VLESS, активно развивается и поддерживается. Это гарантирует, что протокол будет оставаться актуальным, решая проблемы безопасности и добавляя новые функции по мере необходимости.`,
    [lang.EN]: `The V2Ray project, including VLESS, is actively developed and maintained. This ensures that the protocol stays current, addressing security concerns and incorporating new features as needed.`,
    [lang.DE]: `Das V2Ray-Projekt, einschließlich VLESS, wird aktiv weiterentwickelt und gepflegt. Dadurch wird sichergestellt, dass das Protokoll aktuell bleibt, Sicherheitsbedenken berücksichtigt und bei Bedarf neue Funktionen integriert werden.`,
}

const faq_trojan_long = {
    [lang.RU]: `Trojan — современный протокол VPN, маскирующий свой трафик под обычный HTTPS-трафик. Это обеспечивает его эффективность в обходе DPI (Deep Packet Inspection). Будучи молодым и развивающимся проектом, это идеальное решение в ситуациях, когда другие протоколы не работают.`,
    [lang.EN]: `Trojan is a modern VPN protocol that disguises its traffic as regular HTTPS traffic. This ensures its effectiveness in circumventing DPI (Deep Packet Inspection). Being young and developing project it is perfect solution in situations where other protocols don't work.`,
    [lang.DE]: `Trojan ist ein modernes VPN-Protokoll, das seinen Datenverkehr als regulären HTTPS-Datenverkehr tarnt. Dies stellt die Wirksamkeit bei der Umgehung von DPI (Deep Packet Inspection) sicher. Als junges und sich entwickelndes Projekt ist es die perfekte Lösung in Situationen, in denen andere Protokolle nicht funktionieren.`,
}

export const FaqProtocols = {
    faq_install_app,
    faq_download_config,
    faq_website,
    faq_import_and_connect,
    faq_choose_file,
    faq_contact_support,
    faq_note_shadowsocks_windows,
    faq_note_nekoray,
    faq_openvpn_long,
    faq_openvpn_1_title,
    faq_openvpn_1_text,
    faq_openvpn_2_title,
    faq_openvpn_2_text,
    faq_openvpn_3_title,
    faq_openvpn_3_text,
    faq_openvpn_4_title,
    faq_openvpn_4_text,
    faq_openvpn_5_title,
    faq_openvpn_5_text,
    faq_openvpn_6_title,
    faq_openvpn_6_text,
    faq_wireguard_long,
    faq_wireguard_1_title,
    faq_wireguard_1_text,
    faq_wireguard_2_title,
    faq_wireguard_2_text,
    faq_wireguard_3_title,
    faq_wireguard_3_text,
    faq_wireguard_4_title,
    faq_wireguard_4_text,
    faq_wireguard_5_title,
    faq_wireguard_5_text,
    faq_wireguard_6_title,
    faq_wireguard_6_text,
    faq_wireguard_7_title,
    faq_wireguard_7_text,
    faq_wireguard_8_title,
    faq_wireguard_8_text,
    faq_shadowsocks_long,
    faq_shadowsocks_1_title,
    faq_shadowsocks_1_text,
    faq_shadowsocks_2_title,
    faq_shadowsocks_2_text,
    faq_shadowsocks_3_title,
    faq_shadowsocks_3_text,
    faq_shadowsocks_4_title,
    faq_shadowsocks_4_text,
    faq_shadowsocks_5_title,
    faq_shadowsocks_5_text,
    faq_shadowsocks_6_title,
    faq_shadowsocks_6_text,
    faq_vless_long,
    faq_vless_1_title,
    faq_vless_1_text,
    faq_vless_2_title,
    faq_vless_2_text,
    faq_vless_3_title,
    faq_vless_3_text,
    faq_vless_4_title,
    faq_vless_4_text,
    faq_vless_5_title,
    faq_vless_5_text,
    faq_vless_6_title,
    faq_vless_6_text,
    faq_vless_7_title,
    faq_vless_7_text,
    faq_vmess_long,
    faq_vmess_1_title,
    faq_vmess_1_text,
    faq_vmess_2_title,
    faq_vmess_2_text,
    faq_vmess_3_title,
    faq_vmess_3_text,
    faq_vmess_4_title,
    faq_vmess_4_text,
    faq_vmess_5_title,
    faq_vmess_5_text,
    faq_trojan_long
}