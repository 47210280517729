import React from "react";

import { AboutLang } from "js/languages/about/about";

import whoSpiesPic from "resources/images/about/who_spies.webp"
import weCanPic from "resources/images/about/we_can.webp"
import tgPic from "resources/images/about/tg.webp"
import whyWePic from "resources/images/about/why_we.webp"
import whoAreWePic from "resources/images/about/who_are_we.webp";
import whatWeDontDoPic from "resources/images/about/what_we_dont_do.webp";
import whatWeDoPic from "resources/images/about/what_we_do.webp";
import dontBelievePic from "resources/images/about/what_we_dont_believe.webp";
import protocolsPic from "resources/images/about/protocols.webp";


export default function AboutPage({ state }) {
    let language = state.language;
    let theme = state.themeLight;
    return (
        <>
            <WhoAreWe lang={language} theme={theme} />
            <WhatWeDo lang={language} theme={theme} />
            <WhatWeDontDo lang={language} theme={theme} />
            <WeHaveTelegramBot lang={language} theme={theme} />
            <WhatWeDontBelieve lang={language} theme={theme} />
            <WhatCanVpn lang={language} theme={theme} />
            <WhoSpiesOnYou lang={language} theme={theme} />
            <WhyWeAreBetter lang={language} theme={theme} />
            <WhyWeUseOpenVpn lang={language} theme={theme} />
        </>
    );
}

function WhoAreWe({ lang, theme }) {

    return (
        <section className="about_section">
            <div className={theme ? "common_container" : "common_container about_section_white_letters"}>
                <div className="about_who_we_are_container">
                    <div className="about_grid">
                        <div className="about_side_sector_big">
                            <div className="about_who_we_are_title">{AboutLang.whoAreWe_header[lang]}</div>
                            <div className="about_who_we_are_1">{AboutLang.whoAreWe_main[lang]}</div>
                            <div className="who_we_are_3">
                                <div className="common_phrase">
                                    <strong>{AboutLang.whoAreWe_private[lang]}</strong>{AboutLang.whoAreWe_private_phrase[lang]}
                                </div>
                                <div className="common_phrase">
                                    <strong>{AboutLang.whoAreWe_open[lang]}</strong>{AboutLang.whoAreWe_open_phrase[lang]}
                                </div>
                                <div className="common_phrase">
                                    <strong>{AboutLang.whoAreWe_novelness[lang]}</strong>{AboutLang.whoAreWe_novelness_phrase[lang]}
                                </div>
                            </div>
                        </div>
                        <div className="about_side_sector">
                            <div className="about_who_we_are_image"><img alt="#" src={whoAreWePic} /></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

function WhatWeDo({ lang, theme }) {
    return (
        <section className={theme ? "about_section about_section_colored" : "about_section_dark"}>
            <div className="common_container">
                <div className="about_who_we_are_container">
                    <div className="about_grid2">

                        <div className="about_side_sector">
                            <div className="about_who_we_are_image"><img alt="#" src={whatWeDoPic} /></div>
                        </div>

                        <div className="about_side_sector_big">
                            <div className="about_who_we_are_title">{AboutLang.whatWeDo_header[lang]}</div>
                            <div className="who_we_are_3">
                                <div className="common_phrase">
                                    &#10004; {AboutLang.whatWeDo_1[lang]}
                                </div>
                                <div className="common_phrase">
                                    &#10004; {AboutLang.whatWeDo_2[lang]}
                                </div>
                                <div className="common_phrase">
                                    &#10004; {AboutLang.whatWeDo_3[lang]}
                                </div>
                                <div className="common_phrase">
                                    &#10004; {AboutLang.whatWeDo_4[lang]}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

function WhatWeDontDo({ lang, theme }) {
    return (
        <section className="about_section">
            <div className={theme ? "common_container" : "common_container about_section_white_letters"}>
                <div className="about_who_we_are_container">
                    <div className="about_grid">

                        <div className="about_side_sector_big">
                            <div className="about_who_we_are_title">{AboutLang.whatWeDontDo_header[lang]}</div>
                            <div className="who_we_are_3">
                                <div className="common_phrase">
                                    &#10060; {AboutLang.whatWeDontDo_1[lang]}
                                </div>
                                <div className="common_phrase">
                                    &#10060; {AboutLang.whatWeDontDo_2[lang]}
                                </div>
                                <div className="common_phrase">
                                    &#10060; {AboutLang.whatWeDontDo_3[lang]}
                                </div>
                                <div className="common_phrase">
                                    &#10060; {AboutLang.whatWeDontDo_4[lang]}
                                </div>
                            </div>
                        </div>


                        <div className="about_side_sector">
                            <div className="about_who_we_are_image"><img alt="#" src={whatWeDontDoPic} /></div>
                        </div>


                    </div>
                </div>
            </div>
        </section>
    );
}

function WeHaveTelegramBot({ lang, theme }) {
    return (
        <section className={theme ? "about_section about_section_colored" : "about_section_dark"}>

            <div className="common_container">
                <div className="about_who_we_are_container">
                    <div className="about_grid2">

                        <div className="about_side_sector">
                            <div className="about_who_we_are_image"><img alt="#" src={tgPic} /></div>
                        </div>

                        <div className="about_side_sector_big">
                            <div className="about_who_we_are_title">{AboutLang.weHaveTelegramBot_header[lang]}</div>
                            <div className="who_we_are_3">
                                <div className="common_phrase">
                                    &#10004; {AboutLang.weHaveTelegramBot_1[lang]}
                                </div>
                                <div className="common_phrase">
                                    &#10004; {AboutLang.weHaveTelegramBot_2[lang]}
                                </div>
                                <div className="common_phrase">
                                    &#10004; {AboutLang.weHaveTelegramBot_3[lang]}
                                </div>
                                <div className="common_phrase">{AboutLang.weHaveTelegramBot_of_course[lang]}</div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </section>
    );
}

function WhatWeDontBelieve({ lang, theme }) {
    return (
        <section className="about_section">

            <div className={theme ? "common_container" : "common_container about_section_white_letters"}>
                <div className="about_who_we_are_container">
                    <div className="about_grid">

                        <div className="about_side_sector_big">
                            <div className="about_who_we_are_title">{AboutLang.whatWeDontBelieve_header[lang]}</div>
                            <div className="who_we_are_3">
                                <div className="common_phrase">
                                    &#10060; <strong>{AboutLang.whatWeDontBelieve_1_strong[lang]}</strong>{AboutLang.whatWeDontBelieve_1[lang]}
                                </div>
                                <div className="common_phrase">
                                    &#10060; <strong>{AboutLang.whatWeDontBelieve_2_strong[lang]}</strong>{AboutLang.whatWeDontBelieve_2[lang]}
                                </div>
                                <div className="common_phrase">
                                    &#10060; <strong>{AboutLang.whatWeDontBelieve_3_strong[lang]}</strong>{AboutLang.whatWeDontBelieve_3[lang]}
                                </div>
                            </div>
                        </div>


                        <div className="about_side_sector">
                            <div className="about_who_we_are_image"><img alt="#" src={dontBelievePic} /></div>
                        </div>


                    </div>
                </div>
            </div>
        </section>
    );
}

function WhatCanVpn({ lang, theme }) {
    return (
        <section className={theme ? "about_section about_section_colored" : "about_section_dark"}>

            <div className="common_container">
                <div className="about_who_we_are_container">
                    <div className="about_grid2">

                        <div className="about_side_sector">
                            <div className="about_who_we_are_image"><img alt="#" src={weCanPic} /></div>
                        </div>

                        <div className="about_side_sector_big">
                            <div className="about_who_we_are_title">{AboutLang.whatCanVpn_header[lang]}</div>
                            <div className="who_we_are_3">
                                <div className="common_phrase">
                                    &#10004; {AboutLang.whatCanVpn_1[lang]}
                                </div>
                                <div className="common_phrase">
                                    &#10004; {AboutLang.whatCanVpn_2[lang]}
                                </div>
                                <div className="common_phrase">
                                    &#10004; {AboutLang.whatCanVpn_3[lang]}
                                </div>
                                <div className="common_phrase">
                                    &#10004; {AboutLang.whatCanVpn_4[lang]}
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </section>
    );
}

function WhoSpiesOnYou({ lang, theme }) {
    return (
        <section className="about_section">

            <div className={theme ? "common_container" : "common_container about_section_white_letters"}>
                <div className="about_who_we_are_container">
                    <div className="about_grid">

                        <div className="about_side_sector_big">
                            <div className="about_who_we_are_title">{AboutLang.whoSpiesOnYou_header[lang]}</div>
                            <div className="who_we_are_3">
                                <div className="common_phrase">
                                    <strong>{AboutLang.whoSpiesOnYou_1_strong[lang]}</strong> {AboutLang.whoSpiesOnYou_1[lang]}
                                </div>
                                <div className="common_phrase">
                                    <strong>{AboutLang.whoSpiesOnYou_2_strong[lang]}</strong> {AboutLang.whoSpiesOnYou_2[lang]}
                                </div>
                                <div className="common_phrase">
                                    <strong>{AboutLang.whoSpiesOnYou_3_strong[lang]}</strong> {AboutLang.whoSpiesOnYou_3[lang]}
                                </div>
                                <div className="common_phrase">
                                    <strong>{AboutLang.whoSpiesOnYou_4_strong[lang]}</strong> {AboutLang.whoSpiesOnYou_4[lang]}
                                </div>
                                <div className="common_phrase">
                                    <strong>{AboutLang.whoSpiesOnYou_5_strong[lang]}</strong> {AboutLang.whoSpiesOnYou_5[lang]}
                                </div>
                            </div>
                        </div>


                        <div className="about_side_sector">
                            <div className="about_who_we_are_image"><img alt="#" src={whoSpiesPic} /></div>
                        </div>


                    </div>
                </div>
            </div>
        </section>
    );
}

function WhyWeAreBetter({ lang, theme }) {
    return (
        <section className={theme ? "about_section about_section_colored" : "about_section_dark"}>

            <div className="common_container">
                <div className="about_who_we_are_container">
                    <div className="about_grid2">

                        <div className="about_side_sector">
                            <div className="about_who_we_are_image"><img alt="#" src={whyWePic} /></div>
                        </div>

                        <div className="about_side_sector_big">
                            <div className="about_who_we_are_title">{AboutLang.whyWeAreBetter_header[lang]}</div>
                            <div className="who_we_are_3">
                                <div className="common_phrase">
                                    &#10004; {AboutLang.whyWeAreBetter_1[lang]}
                                </div>
                                <div className="common_phrase">
                                    &#10004; {AboutLang.whyWeAreBetter_2[lang]}
                                </div>
                                <div className="common_phrase">
                                    &#10004; {AboutLang.whyWeAreBetter_3[lang]}
                                </div>
                                <div className="common_phrase">
                                    &#10004; {AboutLang.whyWeAreBetter_4[lang]}
                                </div>
                                <div className="common_phrase">
                                    &#10004; {AboutLang.whyWeAreBetter_5[lang]}
                                </div>
                                <div className="common_phrase">
                                    &#10004; {AboutLang.whyWeAreBetter_6[lang]}
                                </div>
                                <div className="common_phrase">
                                    &#10004; {AboutLang.whyWeAreBetter_7[lang]}
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </section>
    );
}

function WhyWeUseOpenVpn({ lang, theme }) {
    return (
        <section className="about_section">

            <div className={theme ? "common_container" : "common_container about_section_white_letters"}>
                <div className="about_who_we_are_container">
                    <div className="about_grid">

                        <div className="about_side_sector_big">
                            <div className="about_who_we_are_title">{AboutLang.whyWeUseOpenvpn_header[lang]}</div>
                            <div className="who_we_are_3">
                                <div className="common_phrase">{AboutLang.whyWeUseOpenvpn_main[lang]}</div>
                            </div>
                        </div>


                        <div className="about_side_sector">
                            <div className="about_who_we_are_image"><img alt="#" src={protocolsPic} /></div>
                        </div>


                    </div>
                </div>
            </div>
        </section>
    );
}