import {lang} from "../common/lang_enum";

export const main_ = {
    [lang.RU]: ``,
    [lang.EN]: ``,
    [lang.DE]: ``,
}

export const broken = {
    [lang.RU]: `Что-то пошло не так. Попробуйте обновить страницу позже.`,
    [lang.EN]: `Something went wrong. Try refreshing the page later.`,
    [lang.DE]: `Etwas ist schief gelaufen. Versuchen Sie später, die Seite zu aktualisieren.`,
}


const top = {
    [lang.RU]: "Лучший анонимный VPN. Оплата в криптовалюте. Низкие цены. Отсутствие логов. Множество протоколов на выбор. Защити себя и свои данные!",
    [lang.EN]: "The best anonymous VPN. Payment in cryptocurrency. Low prices. No logs. Many protocols to choose from. Protect yourself and your data!",
    [lang.DE]: `Das beste anonyme VPN. Zahlung in Kryptowährung. Niedrige Preise. Keine Logs. Viele Protokolle zur Auswahl. Schützen Sie sich und Ihre Daten!`,
}

const topPrice = {
    [lang.RU]: `От 1$/месяц.`,
    [lang.EN]: `From 1$/month.`,
    [lang.DE]: `Ab 1$/Monat.`,
}

const topGetButton = {
    [lang.RU]: `Получить PonVPN`,
    [lang.EN]: `Get PonVPN`,
    [lang.DE]: `PonVPN erhalten`,
}

const speechAboutProtocols = {
    [lang.RU]: `Для предоставления нашего сервиса мы используем открытые и надёжные протоколы - OpenVPN, WireGuard, Shadowsocks, VLESS, VMess и Trojan. Чтобы пользоваться нашим VPN Вам необходимо лишь выбрать нужный протокол, скачать и импортировать конфигурационный файл.`,
    [lang.EN]: `To provide our service, we use open and reliable protocols - OpenVPN, WireGuard, Shadowsocks, VLESS, VMess and Trojan. To use our best VPN, you only need to select the protocol you need, download and import the configuration file.`,
    [lang.DE]: `Um unseren Service bereitzustellen, verwenden wir offene und zuverlässige Protokolle – OpenVPN, WireGuard, Shadowsocks, VLESS, VMess und Trojan. Um unser bestes VPN zu nutzen, müssen Sie nur das benötigte Protokoll auswählen, die Konfigurationsdatei herunterladen und importieren.`,
}

const pros_noLogsTitle = {
    [lang.RU]: `Отсутствие логов`,
    [lang.EN]: `No logs`,
    [lang.DE]: `No Logs`,
}
const pros_noLogs = {
    [lang.RU]: `Мы не ведём логи сессий и подключений. Таким образом мы не имеем возможности видеть, куда, откуда и когда Вы подключались через наш VPN.`,
    [lang.EN]: `We do not keep logs of sessions and connections. Thus, we are not able to see where, from where and when you connected through our VPN.`,
    [lang.DE]: `Wir führen keine Sitzungs- und Verbindungsprotokolle. Daher können wir nicht sehen, wo, von wo und wann Sie sich über unser VPN verbunden haben.`,
}

const pros_dataCollectionTitle = {
    [lang.RU]: `Мы не собираем данные о Вас`,
    [lang.EN]: `We do not collect your data`,
    [lang.DE]: `Wir erfassen Ihre Daten nicht`,
}

const pros_dataCollection = {
    [lang.RU]: `Мы не собираем информацию о Вас больше минимально необходимой для работы с личным кабинетом и уведомлений. Никаких трекеров или вредоносной рекламы!`,
    [lang.EN]: `We do not collect information about you more than the minimum necessary to work with your personal account and notifications. No trackers or malicious ads!`,
    [lang.DE]: `Wir erfassen keine Informationen über Sie, die über das für die Arbeit mit Ihrem persönlichen Konto und Ihren Benachrichtigungen erforderliche Minimum hinausgehen. Keine Tracker oder schädliche Werbung!`,
}

const pros_opensourceTitle = {
    [lang.RU]: `Открытые технологии`,
    [lang.EN]: `Opensource solutions`,
    [lang.DE]: `OpenSource-Lösungen`,
}
const pros_opensource = {
    [lang.RU]: `Для работы сервиса используются только известные, открытые и надёжные технологии. Никакого проприетарного ПО на Ваших устройствах!`,
    [lang.EN]: `Only well-known, open and reliable technologies are used for the operation of the service. No proprietary software on your devices!`,
    [lang.DE]: `Für den Betrieb des Dienstes werden ausschließlich bekannte, offene und zuverlässige Technologien eingesetzt. Keine proprietäre Software auf Ihren Geräten!`,
}

const pros_cryptoTitle = {
    [lang.RU]: `Оплата в криптовалюте`,
    [lang.EN]: `Payment in cryptocurrency`,
    [lang.DE]: `Zahlung in Kryptowährung`,
}
const pros_crypto = {
    [lang.RU]: `Мы ценим Вашу приватность, поэтому Вы можете оплатить услуги нашего сервиса с помощью криптовалюты, оставаясь полностью анонимным.`,
    [lang.EN]: `We value your privacy, so you can pay for our service using cryptocurrency, while remaining completely anonymous.`,
    [lang.DE]: `Wir legen Wert auf Ihre Privatsphäre, sodass Sie für unseren Service mit Kryptowährung bezahlen können und dabei völlig anonym bleiben.`,
}

const pros_botTitle = {
    [lang.RU]: `У нас есть Телеграм бот`,
    [lang.EN]: `We have Telegram bot`,
    [lang.DE]: `Wir haben einen Telegram-Bot`,
}

const pros_bot = {
    [lang.RU]: `Телеграм всегда доступен и всегда под рукой. Вы можете использовать нашего удобного бота для простого и удобного управления Вашими услугами.`,
    [lang.EN]: `Telegram is always available and always at hand. You can use our handy bot for easy and convenient management of your services.`,
    [lang.DE]: `Telegram ist immer verfügbar und immer zur Hand. Für die einfache und bequeme Verwaltung Ihrer Dienste können Sie unseren praktischen Bot nutzen.`,
}

const pros_countriesTitle = {
    [lang.RU]: `Локации на выбор`,
    [lang.EN]: `Locations to choose from`,
    [lang.DE]: `Standorte zur Auswahl`,
}

const pros_countries = {
    [lang.RU]: `Наши сервера находятся в нескольких странах. Сотрудничая с нами, Вы можете выбрать подходящую конкретно Вам локацию.`,
    [lang.EN]: `Our servers are located in several countries. By cooperating with us, you can choose the location that suits you specifically.`,
    [lang.DE]: `Unsere Server befinden sich in mehreren Ländern. Durch die Zusammenarbeit mit uns können Sie den Standort auswählen, der genau zu Ihnen passt.`,
}

const whyUsHeader = {
    [lang.RU]: `Почему Вам стоит выбрать нас`,
    [lang.EN]: `Why you should choose us`,
    [lang.DE]: `Warum Sie sich für uns entscheiden sollten`,
}

const whyUsText = {
    [lang.RU]: `В современном мире очень много людей, которые хотят ограничить Вашу информационную свободу. Сложно найти надёжного партнёра со свободными и открытыми решениями, не требующего от Вас лишней и, будем честны, совершенно ненужной информации. Мы хотим дать Вам нечто большее, чем просто интернет сервис. За нами стоит идея и мы действительно любим то, что делаем.`,
    [lang.EN]: `In the modern world there are a lot of people who want to limit your information freedom. It is difficult to find a reliable partner with free and open solutions that does not require unnecessary and, let’s be honest, completely unnecessary information from you. We want to give you something more than just an Internet service. We have an idea behind us and we truly love what we do.`,
    [lang.DE]: `In der modernen Welt gibt es viele Menschen, die Ihre Informationsfreiheit einschränken möchten. Es ist schwierig, einen zuverlässigen Partner mit kostenlosen und offenen Lösungen zu finden, der keine unnötigen und, seien wir ehrlich, völlig unnötigen Informationen von Ihnen verlangt. Wir möchten Ihnen mehr als nur einen Internetdienst bieten. Wir haben eine Idee hinter uns und wir lieben wirklich, was wir tun.`,
}
const whyUsMoreButton = {
    [lang.RU]: `Узнать больше`,
    [lang.EN]: `Find out more`,
    [lang.DE]: `Finde mehr heraus`,
}

const anyPlatformSlogan = {
    [lang.RU]: `Любая нужная Вам платформа`,
    [lang.EN]: `Any platform you need`,
    [lang.DE]: `Jede Plattform, die Sie brauchen`,
}

const anyPlatformText = {
    [lang.RU]: `Так как мы строим наш сервис поверх открытых и известных решений, как OpenVPN, WireGuard, Shadowsocks, VLESS, VMess и Trojan, PonVPN доступен на большом количестве платформ`,
    [lang.EN]: `Since we build our service on top of open and popular solutions like OpenVPN, WireGuard, Shadowsocks, VLESS, VMess and Trojan, PonVPN is available on a huge number of platforms`,
    [lang.DE]: `Da wir unseren Service auf offenen und bekannten Lösungen wie OpenVPN, WireGuard, Shadowsocks, VLESS, VMess und Trojan aufbauen, ist PonVPN auf einer Vielzahl von Plattformen verfügbar`,
}

const rightTariffSlogan = {
    [lang.RU]: `Сконструируйте подходящий для Вас тариф`,
    [lang.EN]: `Design the right tariff for you`,
    [lang.DE]: `Gestalten Sie den für Sie passenden Tarif`,
}

const rightTariffText = {
    [lang.RU]: `Вам не стоит переплачивать за вещи, которыми Вы не пользуетесь. Благодаря нашему конструктору, Вы можете подобрать себе именно то, что нужно. К примеру, чтобы просматривать соцсети с PonVPN, вам достаточно лишь одного доллара. И нет причин платить больше!`,
    [lang.EN]: `You shouldn't overpay for things you don't use. Using our constructor, you can choose exactly what you need. For example, to view social networks with PonVPN, you only need one dollar. And there is no reason to pay more!`,
    [lang.DE]: `You shouldn't overpay for things you don't use. Our constructor allows you to choose exactly what you need. For example, to watch social networks with PonVPN, you only need one dollar. And there is no reason to pay more!`,
}

const rightTariffPro_1 = {
    [lang.RU]: `Цены от 1$`,
    [lang.EN]: `Prices from 1$`,
    [lang.DE]: `Preise ab 1$`,
}

const rightTariffPro_2 = {
    [lang.RU]: `Широкий выбор протоколов и локаций`,
    [lang.EN]: `Wide choice of protocols and locations`,
    [lang.DE]: `Große Auswahl an Protokollen und Standorten`,
}

const rightTariffPro_3 = {
    [lang.RU]: `Простой конструктор тарифа`,
    [lang.EN]: `A simple tariff constructor`,
    [lang.DE]: `Ein einfacher Tarifkonstrukteur`,
}

const rightTariffPro_4 = {
    [lang.RU]: `Платите одинаково, вне зависимости от периода оплаты`,
    [lang.EN]: `Pay the same regardless of the payment period`,
    [lang.DE]: `Zahlen Sie unabhängig vom Zahlungszeitraum das Gleiche`,
}

const rightTariffButton = {
    [lang.RU]: `Подобрать тариф`,
    [lang.EN]: `Design a tariff`,
    [lang.DE]: `Einen Tarif erstellen`,
}

const stepsSlogan = {
    [lang.RU]: `Всего 3 шага до лучшего VPN в истории`,
    [lang.EN]: `Only 3 steps to get the best VPN ever`,
    [lang.DE]: `Nur 3 Schritte zum besten VPN aller Zeiten`,
}

const stepsStep_1Title = {
    [lang.RU]: `Купите`,
    [lang.EN]: `Buy`,
    [lang.DE]: `Kaufen`,
}
const stepsStep_1Text = {
    [lang.RU]: `Пополните баланс, выберите подходящие Вам страну и тариф, совершите покупку`,
    [lang.EN]: `Top up your balance, select the country and tariff that suits you, and make a purchase`,
    [lang.DE]: `Laden Sie Ihr Guthaben auf, wählen Sie das für Sie passende Land und den Tarif aus und tätigen Sie den Kauf`,
}

const stepsStep_2Title = {
    [lang.RU]: `Скачайте конфигурационный файл`,
    [lang.EN]: `Download config file`,
    [lang.DE]: `Konfigurationsdatei herunterladen`,
}
const stepsStep_2Text = {
    [lang.RU]: `Загрузите конфигурационный файл PonVPN на ваше устройство`,
    [lang.EN]: `Just press download button to get PonVPN config file onto your device`,
    [lang.DE]: `Laden Sie einfach die PonVPN-Konfigurationsdatei auf Ihr Gerät herunter.`,
}

const stepsStep_3Title = {
    [lang.RU]: `Импортируйте конфигурационный файл`,
    [lang.EN]: `Import config file`,
    [lang.DE]: `Konfigurationsdatei importieren`,
}
const stepsStep_3Text = {
    [lang.RU]: `Импортируйте файл конфигурации в подходящее приложение-клиент на Вашем устройстве. Это всё!`,
    [lang.EN]: `Import the configuration file into the appropriate client application on your device. That's it!`,
    [lang.DE]: `Importieren Sie die Konfigurationsdatei in die entsprechende Clientanwendung auf Ihrem Gerät. Das ist es!`,
}

export const MainLang = {
    top,
    topPrice,
    topGetButton,
    speechAboutProtocols,
    pros_noLogsTitle,
    pros_noLogs,
    pros_dataCollectionTitle,
    pros_dataCollection,
    pros_opensourceTitle,
    pros_opensource,
    pros_cryptoTitle,
    pros_crypto,
    pros_botTitle,
    pros_bot,
    pros_countriesTitle,
    pros_countries,
    whyUsHeader,
    whyUsText,
    whyUsMoreButton,
    anyPlatformSlogan,
    anyPlatformText,
    rightTariffSlogan,
    rightTariffText,
    rightTariffPro_1,
    rightTariffPro_2,
    rightTariffPro_3,
    rightTariffPro_4,
    rightTariffButton,
    stepsSlogan,
    stepsStep_1Title,
    stepsStep_1Text,
    stepsStep_2Title,
    stepsStep_2Text,
    stepsStep_3Title,
    stepsStep_3Text,
    broken
}