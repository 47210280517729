import { lang } from "../common/lang_enum";

const menu_back = {
    [lang.RU]: `Назад`,
    [lang.EN]: `Back`,
    [lang.DE]: `Zurück`,
}

const menu_my = {
    [lang.RU]: `Мой VPN`,
    [lang.EN]: `My VPN`,
    [lang.DE]: `Mein VPN`,
}

const menu_buy = {
    [lang.RU]: `Купить VPN`,
    [lang.EN]: `Buy VPN`,
    [lang.DE]: `VPN kaufen`,
}

const menu_profile = {
    [lang.RU]: `Профиль и баланс`,
    [lang.EN]: `Profile and balance`,
    [lang.DE]: `Nutzerkonto und Geldsaldo`,
}

const menu_support = {
    [lang.RU]: `Поддержка`,
    [lang.EN]: `Support`,
    [lang.DE]: `Unterstützung`,
}

const certs_your_vpns = {
    [lang.RU]: `Ваши VPN:`,
    [lang.EN]: `Your VPN:`,
    [lang.DE]: `Ihr VPN:`,
}

const certs_no_vpns_yet = {
    [lang.RU]: `Пока ни одного VPN`,
    [lang.EN]: `No VPN yet`,
    [lang.DE]: `Noch kein VPN`,
}

const certs_loading = {
    [lang.RU]: `Загружаем ваши VPN`,
    [lang.EN]: `Loading your VPN`,
    [lang.DE]: `Laden Ihres VPN`,
}

const cert_copy = {
    [lang.RU]: `Скопировать`,
    [lang.EN]: `Copy`,
    [lang.DE]: `Kopieren`,
}

const cert_download = {
    [lang.RU]: `Скачать`,
    [lang.EN]: `Download`,
    [lang.DE]: `Herunterladen`,
}

const cert_settings = {
    [lang.RU]: `Настройки`,
    [lang.EN]: `Settings`,
    [lang.DE]: `Einstellungen`,
}

const cert_auto_prolong = {
    [lang.RU]: `Продлевать автоматически`,
    [lang.EN]: `Auto renewal`,
    [lang.DE]: `Automatische Verlängerung`,
}

const cert_auto_prolong_info = {
    [lang.RU]: `По завершении срока действия VPN списать средства и продлить его ещё на месяц. Если не выбрано - услуга удалится.`,
    [lang.EN]: `When the VPN expires, funds is automatically debited from current account balance and VPN is prolonged for a month. If not selected, VPN will be deleted.`,
    [lang.DE]: `Wenn das VPN abläuft, wird der Betrag automatisch vom aktuellen Kontostand abgebucht und das VPN wird um einen Monat verlängert. Wenn nicht ausgewählt, wird VPN gelöscht.`,
}

const cert_rename = {
    [lang.RU]: `Переименовать`,
    [lang.EN]: `Rename`,
    [lang.DE]: `Umbenennen`,
}

const cert_rename_info = {
    [lang.RU]: `Имя ни на что не влияет и вы можете изменить его в любой момент.`,
    [lang.EN]: `The name does not affect anything and you can change it at any time.`,
    [lang.DE]: `Der Name hat keinerlei Auswirkungen und Sie können ihn jederzeit ändern.`,
}

const cert_change_tariff = {
    [lang.RU]: `Изменить тариф`,
    [lang.EN]: `Change tariff`,
    [lang.DE]: `Tarif ändern`,
}

const cert_change_tariff_less = {
    [lang.RU]: `Тариф будет изменен в сторону уменьшения. Дата истечения/продления срока действия услуги не будет изменена. Новая цена в месяц составит `,
    [lang.EN]: `Tariff will be changed downward. The service expiration/renewal dates will not be changed. New price per month is `,
    [lang.DE]: `Der Tarif wird nach unten geändert. Das Ablauf-/Erneuerungsdatum des Dienstes wird nicht geändert. Neuer Preis pro Monat ist `,
}

const cert_change_tariff_more = {
    [lang.RU]: `Тариф будет изменен в сторону увеличения. Средства будут списаны с текущего баланса вашего аккаунта. Даты истечения срока действия/продления услуги не будут изменены. Новая цена в месяц составит `,
    [lang.EN]: `Tariff will be changed upward. Funds will be credited from your current account balance. The service expiration/renewal dates will not be changed. New price per month is `,
    [lang.DE]: `Der Tarif wird nach oben geändert. Der Betrag wird von Ihrem aktuellen Kontostand abgebucht. Die Ablauf-/Erneuerungsdaten des Dienstes werden nicht geändert. Neuer Preis pro Monat ist `,
}

const cert_change_tariff_understand = {
    [lang.RU]: `Принимаю условия изменения тарифа`,
    [lang.EN]: `Accept tariff change terms`,
    [lang.DE]: `Akzeptieren die Bedingungen der Tarifänderung`,
}
const cert_change_tariff_pay_more = {
    [lang.RU]: `и согласен на доплату `,
    [lang.EN]: `and agree to additional payment `,
    [lang.DE]: `und stimmen einer zusätzlichen Zahlung zu `,
}

const cert_apply = {
    [lang.RU]: `Применить`,
    [lang.EN]: `Apply`,
    [lang.DE]: `Anwenden`,
}

const cert_cancel = {
    [lang.RU]: `Отменить`,
    [lang.EN]: `Cancel`,
    [lang.DE]: `Stornieren`,
}


const profile_title = {
    [lang.RU]: `Ваш профиль`,
    [lang.EN]: `Your profile`,
    [lang.DE]: `Dein Profil`,
}

const profile_username = {
    [lang.RU]: `Логин`,
    [lang.EN]: `Username`,
    [lang.DE]: `Nutzername`,
}

const profile_email = {
    [lang.EN]: `Email`,
    [lang.RU]: `Email`,
    [lang.DE]: `Email`,
}

const profile_telegram = {
    [lang.RU]: `Телеграм`,
    [lang.EN]: `Telegram`,
    [lang.DE]: `Telegram`,
}

const profile_telegram_connected = {
    [lang.RU]: `Подключён. Телеграм id = `,
    [lang.EN]: `Connected. Telegram id = `,
    [lang.DE]: `In Verbindung gebracht. Telegramm-ID =`,
}

const profile_telegram_not_connected = {
    [lang.RU]: `Не подключен.`,
    [lang.EN]: `Not connected.`,
    [lang.DE]: `Nicht verbunden.`,
}

const profile_telegram_about = {
    [lang.RU]: `Подключить`,
    [lang.EN]: `Connect`,
    [lang.DE]: `Verbinden`,
}

const profile_monero_info_1 = {
    [lang.RU]: `Вы можете пополнить баланс с помощью Вашего личного XMR адреса. Сумма будет начислена на баланс Вашего аккаунта в USD по текущему курсу.`,
    [lang.EN]: `You can top up your balance using your personal XMR address. The amount will be credited to your account balance in USD at the current exchange rate.`,
    [lang.DE]: `Sie können Ihr Guthaben mit Ihrer persönlichen XMR-Adresse aufladen. Der Betrag wird Ihrem Kontostand in USD zum aktuellen Wechselkurs gutgeschrieben.`,
}

const profile_monero_info_2 = {
    [lang.RU]: `Начисление средств происходит в течение 5 минут. Рекомендуем сохранить хеш транзакции на случай возможных неполадок.`,
    [lang.EN]: `The funds are credited within 5 minutes. We recommend saving the transaction hash in case of possible problems.`,
    [lang.DE]: `Die Gutschrift erfolgt innerhalb von 5 Minuten. Wir empfehlen, den Transaktions-Hash für den Fall möglicher Probleme zu speichern.`,
}

const profile_save_button = {
    [lang.RU]: `Сохранить`,
    [lang.EN]: `Save`,
    [lang.DE]: `Speichern`,
}

const profile_password_new = {
    [lang.RU]: `Новый пароль`,
    [lang.EN]: `New password`,
    [lang.DE]: `Neues Kennwort`,
}

const profile_password_repeat = {
    [lang.RU]: `Новый пароль снова`,
    [lang.EN]: `New password again`,
    [lang.DE]: `Neues Passwort nochmal`,
}

const profile_password_old = {
    [lang.RU]: `Старый пароль`,
    [lang.EN]: `Old password`,
    [lang.DE]: `Altes Kennwort`,
}

const profile_password_dont_match = {
    [lang.RU]: `Пароли не совпадают`,
    [lang.EN]: `Passwords don't match`,
    [lang.DE]: `Passwörter stimmen nicht überein`,
}

const profile_password_incorrect = {
    [lang.RU]: `Некорректный пароль`,
    [lang.EN]: `Incorrect password`,
    [lang.DE]: `Falsches Passwort`,
}

const profile_password_requirements = {
    [lang.RU]: `Требования к паролю: минимум 8 и максимум 30 символов, одна большая буква, одна маленькая бука, одно число и один специальный символ.`,
    [lang.EN]: `Password requirements: minimum 8 and maximum 30 characters, at least one uppercase letter, one lowercase letter, one number and one special character.`,
    [lang.DE]: `Passwortanforderungen: mindestens 8 und maximal 30 Zeichen, mindestens ein Großbuchstabe, ein Kleinbuchstabe, eine Zahl und ein Sonderzeichen.`,
}

const profile_logout = {
    [lang.RU]: `Выход`,
    [lang.EN]: `Logout`,
    [lang.DE]: `Ausloggen`,
}

const profile_balance = {
    [lang.RU]: `Баланс`,
    [lang.EN]: `Balance`,
    [lang.DE]: `Geldsaldo`,
}

const profile_balance_you_can_popup = {
    [lang.RU]: `Вы можете пополнить баланс одним из следующих способов оплаты:`,
    [lang.EN]: `You can top up your balance choosing one of the following payments methods:`,
    [lang.DE]: `Sie können Ihr Guthaben mit einer der folgenden Zahlungsmethoden aufladen:`,
}

const profile_balance_faq_info = {
    [lang.RU]: `Более подробную информацию вы можете найти в FAQ`,
    [lang.EN]: `You can find more info in FAQ`,
    [lang.DE]: `Weitere Informationen finden Sie in den FAQ`,
}

const profile_balance_cryptocloud_info = {
    [lang.RU]: `Оплата криптовалютой через нашего партнёра.`,
    [lang.EN]: `Payment in cryptocurrency via our partner.`,
    [lang.DE]: `Zahlung in Kryptowährung über unseren Partner.`,
}

const profile_balance_monero_info = {
    [lang.RU]: `Оплата криптовалютой Monero с минимальной комиссией`,
    [lang.EN]: `Payment with Monero cryptocurrency with minimal fee`,
    [lang.DE]: `Zahlung mit Monero-Kryptowährung mit minimaler Gebühr`,
}

const profile_have_an_issue = {
    [lang.RU]: `Остались вопросы? `,
    [lang.EN]: `Have an issue? `,
    [lang.DE]: `Haben Sie ein Problem? `,
}

const profile_contact_us = {
    [lang.RU]: `Свяжитесь с нами!`,
    [lang.EN]: `Contact us!`,
    [lang.DE]: `Kontaktiere uns!`,
}

const amount_regex = {
    [lang.RU]: `Сумма должна быть в формате 9999.99`,
    [lang.EN]: `Amount should be in format 9999.99`,
    [lang.DE]: `Der Betrag sollte im Format 9999.99 vorliegen`,
}

const preparing_payment_page = {
    [lang.RU]: `Готовим страницу для оплаты...`,
    [lang.EN]: `Payment is being prepared...`,
    [lang.DE]: `Die Zahlung wird vorbereitet...`,
}

const cryptocloud_speech_1 = {
    [lang.RU]: `Вы можете оплатить с помощью платежной системы Cryptocloud.`,
    [lang.EN]: `You can pay using Cryptocloud payment system.`,
    [lang.DE]: `Sie können mit dem Zahlungssystem Cryptocloud bezahlen.`,
}

const cryptocloud_speech_2 = {
    [lang.RU]: `Оплата возможна в нескольких криптовалютах. На их сайте также возможно приобрести криптовалюту.`,
    [lang.EN]: `Payment is possible in several cryptocurrencies. It is also possible to purchase cryptocurrency on their website.`,
    [lang.DE]: `Die Zahlung ist in mehreren Kryptowährungen möglich. Es ist auch möglich, Kryptowährung auf ihrer Website zu kaufen.`,
}

const cryptocloud_speech_3 = {
    [lang.RU]: `После ввода суммы и нажатия кнопки "Оплатить" вы будете перенаправлены на страницу оплаты.`,
    [lang.EN]: `After entering amount and clicking "Pay" you will be redirected to the payment page.`,
    [lang.DE]: `Nachdem Sie den Betrag eingegeben und auf "Zahlen" geklickt haben, werden Sie zur Zahlungsseite weitergeleitet.`,
}

const cryptocloud_amount_in_dollars = {
    [lang.RU]: `Пожалуйста, введите сумму в долларах США`,
    [lang.EN]: `Please, enter amount in USD`,
    [lang.DE]: `Bitte geben Sie den Betrag in USD ein`,
}

const cryptocloud_pay_button = {
    [lang.RU]: `Оплатить`,
    [lang.EN]: `Pay`,
    [lang.DE]: `Zahlen`,
}

const profile_cryptocloud_info_1 = {
    [lang.RU]: `Вы можете пополнить свой баланс с помощью криптовалюты через Cryptocloud. Если у Вас нет криптовалюты, Вы можете обменять её там же или в любом другом месте. Сумма будет зачислена на баланс вашего счета в долларах США по текущему обменному курсу.`,
    [lang.EN]: `You can top up your balance using cryptocurrency via Cryptocloud. If you do not have a cryptocurrency, you can exchange it there or anywhere else. The amount will be credited to your account balance in US dollars at the current exchange rate.`,
    [lang.DE]: `Sie können Ihr Guthaben mit Kryptowährung über Cryptocloud aufladen. Wenn Sie keine Kryptowährung haben, können Sie diese dort oder anderswo austauschen. Der Betrag wird Ihrem Kontostand in US-Dollar zum aktuellen Wechselkurs gutgeschrieben.`,
}

const profile_cryptocloud_info_2 = {
    [lang.RU]: `Средства зачисляются в течение 5 минут. Рекомендуется сохранить платежную информацию на случай возникновения проблем с оплатой.`,
    [lang.EN]: `Funds are credited within 5 minutes. It is recommended to save your payment information in case of trouble with payment.`,
    [lang.DE]: `Die Gutschrift erfolgt innerhalb von 5 Minuten. Es wird empfohlen, Ihre Zahlungsinformationen für den Fall von Zahlungsproblemen aufzubewahren.`,
}

const payment_header = {
    [lang.RU]: `Варианты оплаты`,
    [lang.EN]: `Payment options`,
    [lang.DE]: `Zahlungsmöglichkeiten`,
}

const faq_protocols_info = {
    [lang.RU]: `Мы предлагаем различные протоколы: OpenVPN, WireGuard, Shadowsocks, VLESS, VMess, Trojan. Каждый из них имеет свои преимущества, которые вы можете использовать в соответствии со своими потребностями.`,
    [lang.EN]: `We offer a variety of protocols: OpenVPN, WireGuard, Shadowsocks, VLESS, VMess, Trojan. Each of them has its own advantages, which you can use to suit your needs.`,
    [lang.DE]: `Wir bieten eine Vielzahl von Protokollen an: OpenVPN, WireGuard, Shadowsocks, VLESS, VMess, Trojan. Jeder von ihnen hat seine eigenen Vorteile, die Sie je nach Bedarf nutzen können.`,
}

const default_warning_text = {
    [lang.RU]: `Один или несколько ваших ВПН были остановлены. Пожалуйста, свяжитесь с поддержкой.`,
    [lang.EN]: `One or more of your VPNs have been stopped. Please, contact support.`,
    [lang.DE]: `Eines oder mehrere Ihrer VPNs wurden gestoppt. Bitte wenden Sie sich an den Support.`,
}

export const CabLang = {
    menu_back,
    menu_my,
    menu_buy,
    menu_profile,
    menu_support,
    certs_your_vpns,
    certs_no_vpns_yet,
    certs_loading,
    cert_copy,
    cert_download,
    cert_settings,
    cert_auto_prolong,
    cert_auto_prolong_info,
    cert_rename,
    cert_rename_info,
    cert_change_tariff,
    cert_change_tariff_less,
    cert_change_tariff_more,
    cert_change_tariff_understand,
    cert_change_tariff_pay_more,
    cert_apply,
    cert_cancel,
    profile_title,
    profile_username,
    profile_email,
    profile_telegram,
    profile_telegram_connected,
    profile_telegram_not_connected,
    profile_telegram_about,
    profile_save_button,
    profile_password_new,
    profile_password_repeat,
    profile_password_old,
    profile_password_dont_match,
    profile_password_incorrect,
    profile_password_requirements,
    profile_logout,
    profile_balance,
    profile_balance_you_can_popup,
    profile_balance_faq_info,
    profile_balance_cryptocloud_info,
    profile_balance_monero_info,
    profile_have_an_issue,
    profile_contact_us,
    amount_regex,
    preparing_payment_page,
    cryptocloud_speech_1,
    cryptocloud_speech_2,
    cryptocloud_speech_3,
    cryptocloud_amount_in_dollars,
    cryptocloud_pay_button,
    payment_header,
    profile_monero_info_1,
    profile_monero_info_2,
    profile_cryptocloud_info_1,
    profile_cryptocloud_info_2,
    faq_protocols_info,
    default_warning_text
}
