import { FaqPayments } from "js/languages/faq/money";
import BackToFaq from "js/pages/common/BackToFaq";


export default function CryptoCloud({ state, setState }) {

    const lang = state.language;
    return (
        <>
            <div className="faq_body_internal">
            <BackToFaq state={state} setState={setState} />
            <div className="common_protocol_title">CryptoCloud</div>

            <div>{FaqPayments.cryptocloud_1[lang]}</div>
            <div>{FaqPayments.cryptocloud_2[lang]}</div>
            <div>{FaqPayments.cryptocloud_3[lang]}</div>
            </div>
        </>
    )
}