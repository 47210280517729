import React from "react";

import { useNavigate, NavLink } from "react-router-dom";

import { ConstLang } from "js/languages/common/constructor";
import { CabLang } from "js/languages/cabinet/cabinet";
import { Tariffer } from "js/logic/Tariffer";
import { Requester } from "js/logic/Requester";
import { Cookier } from "js/logic/Cookier";


export default function Certificate({ id, country, ip, bandwidth, nameP, protocol, price, prolongP, until, text, setAlertVisible, lang,
    state }) {

    const navigate = useNavigate();

    const [settings, setSettings] = React.useState(false);

    const [prolong, setProlong] = React.useState(prolongP);

    const [initName] = React.useState(nameP);
    const [name, setName] = React.useState(initName);

    const [initBw] = React.useState(bandwidth);
    const [currBw, setCurrBw] = React.useState(initBw);

    const [confirmed, setConfirmed] = React.useState(false);

    const [mbsPosition, setMbsPosition] = React.useState(Tariffer.mbsValueToPosition(currBw));

    const [apply, setApply] = React.useState(false);

    const [newPrice, setNewPrice] = React.useState(price);
    const [toPay, setToPay] = React.useState("0");
    const updateTime = React.useRef(null);


    React.useEffect(() => {
        if (name === initName && prolong === prolongP && currBw === initBw) {
            setApply(false);
            return;
        }

        if (currBw !== initBw && !confirmed) {
            setApply(false);
            return;
        }

        setApply(true);

    }, [prolong, name, currBw, confirmed]);


    async function calcNewPrice(bw) {
        let ans = await Requester.calculateCertificatePrice(bw, 1, protocol);
        if (ans === Requester.unathe) {
            navigate("/enter");
            return;
        }
        if (ans.time > updateTime.current || updateTime.current === null) {
            updateTime.current = ans.time;
            setNewPrice(ans.month);
        }


        ans = await Requester.calculateCertificateDiffPrice(initBw, bw, protocol);
        if (ans === Requester.unathe) {
            navigate("/enter");
            return;
        }
        if (ans.time > updateTime.current || updateTime.current === null) {
            updateTime.current = ans.time;
            setToPay(ans.price);
        }
    }

    async function applyChanges() {
        if (name.length > 20) {
            alert(ConstLang.cert_name_req[lang]);
            return;
        }
        let ans = await Requester.updateCert(id, name, currBw, prolong);
        if (ans === Requester.unathe) {
            navigate("/enter");
            return;
        }
        setSettings(false);
        state.certs.setNeedUpdateAllCerts(true);
        state.certs.setNeedUpdatePreparingCerts(true);
        // reload();
        window.location.reload();
    }

    async function downloadCertificate() {
        window.location.href = `https://www.ponvpn.com/api/public/certificate/download_cert/${id}/${Cookier.getToken()}`;
    }

    function copyToClipboard() {
        const textArea = document.createElement("textarea");
        textArea.value = text;

        textArea.style.position = "absolute";
        textArea.style.left = "-999999px";

        document.body.prepend(textArea);
        textArea.select();

        try {
            document.execCommand('copy');
        } catch (error) {
            console.error(error);
        } finally {
            textArea.remove();
        }

        setAlertVisible(true);
        setTimeout(function () {
            setAlertVisible(false);
        }, 1000);

    }
    return (
        <>
            <div className="certificates_main">
                <div className={state.themeLight ? "certificates_content" : "certificates_content certificates_content_dark"}>
                    <div className={state.themeLight ? "certificates_side1" : " certificates_side1 certificates_side1_dark"}>
                        <div
                            className="certificates_param certificates_first_param">{ConstLang.country[lang]}: {ConstLang.countryNameByCode(country.code)[lang]}</div>
                        <div className="certificates_param">Ip: {ip}</div>
                        <div className="certificates_param">{ConstLang.price[lang]}: {price}$/{ConstLang.per_month[lang]}</div>
                        <div className="certificates_param certificates_title_bw">{ConstLang.speed[lang]}: {initBw}mb/s</div>
                    </div>
                    <div className={state.themeLight ? "certificates_side2" : "certificates_side2 certificates_side2_dark"}>
                        <div className="certificates_param certificates_first_param">{protocol} <NavLink to={`/faq/${protocol.toLowerCase()} `} className={state.themeLight ? "cabinet_total_card_link" : "cabinet_total_card_link cabinet_total_card_link_dark"}>{ConstLang.protoc_about[lang]}</NavLink>
                        </div>
                        <div className="certificates_name">{initName}</div>
                        <div
                            className="certificates_card_ip">Until {until.split("T")[0] + " " + until.split("T")[1].split(".")[0]} UTC
                        </div>

                        <div className={state.themeLight ? "certificates_buttons" : "certificates_buttons certificates_buttons_dark"}>
                            <div className="certificates_button" onClick={() => copyToClipboard()}>{CabLang.cert_copy[lang]}</div>
                            <div className="certificates_button" onClick={() => downloadCertificate()}>{CabLang.cert_download[lang]}</div>
                            <div className="certificates_button" onClick={() => setSettings(!settings)}>{CabLang.cert_settings[lang]}
                            </div>
                        </div>
                    </div>
                </div>







                <div className={settings ? state.themeLight ? "certificates_settings" : "certificates_settings certificates_settings_dark" : "certificates_invisible_settings"}>
                    <div className="certificates_card_setting_row">
                        <span className="certificates_option">{CabLang.cert_auto_prolong[lang]}</span>
                        <div className="agree_checkbox">
                            <label className="switchAgree" >
                                <input onChange={() => setProlong(!prolong)} type="checkbox" checked={prolong} />
                                <div className="sliderAgree round"></div>
                            </label>
                        </div>
                    </div>
                    <div className={state.themeLight ? "certificates_settings_info" : "certificates_settings_info certificates_settings_info_dark"}>
                        {CabLang.cert_auto_prolong_info[lang]}
                    </div>

                    <div className="certificates_card_setting_row">
                        <span className="certificates_option">{CabLang.cert_rename[lang]}</span>
                        <span className="setting_value">
                            <input className={state.themeLight ? "cabinet_input" : "cabinet_input cabinet_input_dark"} type="text"
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value);
                                }}>
                            </input></span>
                    </div>
                    <div className={state.themeLight ? "certificates_settings_info" : "certificates_settings_info certificates_settings_info_dark"}>
                        {CabLang.cert_rename_info[lang]}
                    </div>

                    <div className="certificates_card_setting_row">
                        <span className="certificates_option">{CabLang.cert_change_tariff[lang]}</span>
                        <input className="common_slider certificates_slider" type="range"
                            min="1"
                            max={Tariffer.maxPositionMbs}
                            step="1"
                            value={mbsPosition} onChange={(e) => {
                                setMbsPosition(e.target.value);
                                setCurrBw(Tariffer.sliderPositionToMbsValue(e.target.value));
                                calcNewPrice(Tariffer.sliderPositionToMbsValue(e.target.value));
                                setConfirmed(false);
                            }} />
                        <span className="certificates_option"> {Tariffer.sliderPositionToMbsValue(mbsPosition)} Mb/s </span>
                        {currBw !== initBw && <span className="certificates_option" onClick={() => { setCurrBw(initBw); setMbsPosition(Tariffer.mbsValueToPosition(initBw)); }}>{CabLang.cert_cancel[lang]}</span>}
                    </div>
                    {currBw < initBw && <div className={state.themeLight ? "certificates_settings_info" : "certificates_settings_info certificates_settings_info_dark"}>
                        {CabLang.cert_change_tariff_less[lang]}{newPrice}$
                    </div>}
                    {currBw > initBw && <div className={state.themeLight ? "certificates_settings_info" : "certificates_settings_info certificates_settings_info_dark"}>
                        {CabLang.cert_change_tariff_more[lang]}{newPrice}$
                    </div>}

                    {
                        (currBw !== initBw)
                            ? <div className="certificates_card_setting_row">
                                <span className="certificates_option"> {CabLang.cert_change_tariff_understand[lang]} {toPay !== "0" ?
                                    CabLang.cert_change_tariff_pay_more[lang] + toPay + "$" : ""}</span>
                                {/*<span className="setting_value"><input type="checkbox" checked={confirmed}*/}
                                {/*onChange={() => setConfirmed(!confirmed)}></input></span>*/}
                                <div className="agree_checkbox">
                                    <label className="switchAgree" htmlFor="checkbox_constructor2">
                                        <input onChange={() => setConfirmed(!confirmed)} type="checkbox" id="checkbox_constructor2" checked={confirmed} />
                                        <div className="sliderAgree round"></div>
                                    </label>
                                </div>
                            </div>
                            : ""
                    }

                    <div className={state.themeLight ? "certificates_buttons" : "certificates_buttons certificates_buttons_dark"}>
                        {
                            (apply)
                                ? <div className="certificates_button" onClick={() => applyChanges()}>{CabLang.cert_apply[lang]}</div>
                                : ""
                        }
                        <div className="certificates_button" onClick={() => setSettings(!settings)}>{CabLang.cert_cancel[lang]}</div>
                    </div>
                </div>
            </div>
        </>
    );
}