import {lang} from "../common/lang_enum";

export const about_ = {
    [lang.RU]: ``,
    [lang.EN]: ``,
    [lang.DE]: ``,
}

const whoAreWe_header = {
    [lang.RU]: `Кто мы?`,
    [lang.EN]: `Who are we?`,
    [lang.DE]: `Wer sind wir?`,
}

const whoAreWe_main = {
    [lang.RU]: `Мы - команда разработчиков, уважающая приватность и личную свободу других людей. Мы специализируемся на предоставлении безопасного и надёжного VPN сервиса. Повысьте свою защищённость в интернете и не позволяйте другим людям решать за вас, какие сайты посещать, а какие - нет! PonVPN опирается на три принципа:`,
    [lang.EN]: `We are a development team that respects the privacy and personal freedom of other people. We specialize in providing a secure and reliable VPN service. Increase your security on the Internet and don't let other people decide for you which sites to visit and which not! PonVPN is based on three principles:`,
    [lang.DE]: `Wir sind ein Entwicklungsteam, das die Privatsphäre und die persönliche Freiheit anderer Menschen respektiert. Wir sind darauf spezialisiert, einen sicheren und zuverlässigen VPN-Dienst bereitzustellen. Erhöhen Sie Ihre Sicherheit im Internet und lassen Sie nicht andere für Sie entscheiden, welche Websites Sie besuchen und welche nicht! Pon VPN basiert auf drei Prinzipien:`,
}

const whoAreWe_private = {
    [lang.RU]: `Приватность. `,
    [lang.EN]: `Privacy. `,
    [lang.DE]: `Datenschutz. `,
}

const whoAreWe_private_phrase = {
    [lang.RU]: `Мы не ведём логирование посещений и стремимся не знать, кто вы. Вы остаётесь в тени даже для нас.`,
    [lang.EN]: `We do not log visits and strive not to know who you are. You remain in the shadows even for us.`,
    [lang.DE]: `Wir führen keine Logs und wollen nicht wissen, wer Sie sind. Sie bleiben selbst für uns im Schatten.`,
}

const whoAreWe_open = {
    [lang.RU]: `Открытость. `,
    [lang.EN]: `Opennes. `,
    [lang.DE]: `Offenheit. `,
}

const whoAreWe_open_phrase = {
    [lang.RU]: `Для предоставления нашего сервиса мы используем только открытые и известные технологии.`,
    [lang.EN]: `To provide our service, we use only open and well-known technologies.`,
    [lang.DE]: `Um unseren Service zu erbringen, verwenden wir nur offene und bekannte Technologien.`,
}

const whoAreWe_novelness = {
    [lang.RU]: `Новизна. `,
    [lang.EN]: `Novelty. `,
    [lang.DE]: `Neuheit. `,
}
const whoAreWe_novelness_phrase = {
    [lang.RU]: `Мы держим руку на пульсе технологий, предоставляя Вам новейшие протоколы, так необходимые в наше время.`,
    [lang.EN]: `We keep our finger on the pulse of technology, providing you with the latest protocols that are so necessary in our time.`,
    [lang.DE]: `Wir bleiben am Puls der Technik und stellen Ihnen die neuesten Protokolle zur Verfügung, die in unserer Zeit so notwendig sind.`,
}

/////////////////////////////////////////////

const whatWeDo_header = {
    [lang.RU]: `Что мы делаем?`,
    [lang.EN]: `What we do?`,
    [lang.DE]: `Was machen wir?`,
}

const whatWeDo_1 = {
    [lang.RU]: `Мы делаем лучший, один из доступнейших по цене VPN сервис!`,
    [lang.EN]: `We do the best, one of the most affordable VPN service!`,
    [lang.DE]: `Wir machen das Beste, einen der günstigsten VPN-Dienste!`,
}
const whatWeDo_2 = {
    [lang.RU]: `Помогаем бороться с различного рода блокировками`,
    [lang.EN]: `We help to deal with various kinds of blocks`,
    [lang.DE]: `Wir helfen bei der Bewältigung verschiedener Arten von Blockaden`,
}
const whatWeDo_3 = {
    [lang.RU]: `Уважаем вашу приватность`,
    [lang.EN]: `We respect your privacy`,
    [lang.DE]: `Wir respektieren Ihre Privatsphäre`,
}
const whatWeDo_4 = {
    [lang.RU]: `Предоставляем честную долю пропускной способности сервера согласно тарифу`,
    [lang.EN]: `We provide a fair share of server bandwidth according to the tariff`,
    [lang.DE]: `Wir stellen einen fairen Anteil an Serverbandbreite gemäß Tarif zur Verfügung`,
}

////////////////////////////

const whatWeDontDo_header = {
    [lang.RU]: `Чего мы не делаем?`,
    [lang.EN]: `What we don't do?`,
    [lang.DE]: `Was wir nicht tun?`,
}

const whatWeDontDo_1 = {
    [lang.RU]: `Не собираем логи посещений`,
    [lang.EN]: `We do not collect visit logs`,
    [lang.DE]: `Wir sammeln keine Logs`,
}

const whatWeDontDo_2 = {
    [lang.RU]: `Не продаём никаких ваших данных третьми лицам`,
    [lang.EN]: `We do not sell any of your data to third parties`,
    [lang.DE]: `Wir verkaufen keine Ihrer Daten an Dritte`,
}

const whatWeDontDo_3 = {
    [lang.RU]: `Никак не анализируем содержание вашего трафика`,
    [lang.EN]: `We do not analyze the content of your traffic in any way`,
    [lang.DE]: `Wir analysieren den Inhalt Ihres Traffics in keiner Weise`,
}

const whatWeDontDo_4 = {
    [lang.RU]: `Не побуждаем вас ставить закрытое ПО на ваши устройства`,
    [lang.EN]: `We do not encourage you to install closed software on your devices`,
    [lang.DE]: `Wir empfehlen Ihnen nicht, geschlossene Software auf Ihren Geräten zu installieren`,
}

////////////////////////////////


const weHaveTelegramBot_header = {
    [lang.RU]: `У нас есть телеграм бот. Зачем?`,
    [lang.EN]: `We have Telegram Bot. Why?`,
    [lang.DE]: `Wir haben einen Telegramm-Bot. Warum?`,
}

const weHaveTelegramBot_1 = {
    [lang.RU]: `Управляйте услугами в личном кабинете бота`,
    [lang.EN]: `Manage services in the personal account of the bot`,
    [lang.DE]: `Verwalten Sie Dienste im persönlichen Konto des Bots`,
}

const weHaveTelegramBot_2 = {
    [lang.RU]: `Бот будет доступен в случае недоступности сайта из вашей локации`,
    [lang.EN]: `The bot will be available if the site is unavailable from your location`,
    [lang.DE]: `Der Bot ist verfügbar, wenn die Website von Ihrem Standort aus nicht verfügbar ist`,
}

const weHaveTelegramBot_3 = {
    [lang.RU]: `Это удобно. Если вы уже используете Telegram, он всегда под рукой`,
    [lang.EN]: `It's convenient. If you already use Telegram, it is always at hand`,
    [lang.DE]: `Es ist bequem. Wenn Sie bereits Telegramm verwenden, ist es immer zur Hand`,
}

const weHaveTelegramBot_of_course = {
    [lang.RU]: `Telegram является одним из самых популярных и удобных мессенджеров в настоящее время. Вы можете использовать наш сайт без Telegram, если вам необходима лучшая анонимность.`,
    [lang.EN]: `Telegram is one of the most popular and convenient messengers nowadays. You can use our site without Telegram if you want better anonymity.`,
    [lang.DE]: `Telegram ist heutzutage einer der beliebtesten und bequemsten Messenger. Sie können unsere Seite auch ohne Telegram nutzen, wenn Sie eine bessere Anonymität wünschen.`,
}

////////////////////////////////

const whatWeDontBelieve_header = {
    [lang.RU]: `Во что мы не верим`,
    [lang.EN]: `What we don't believe in`,
    [lang.DE]: `Woran wir nicht glauben`,
}

const whatWeDontBelieve_1 = {
    [lang.RU]: `В современном мире ваши данные имеют огромную ценность для определённых меркантильных агентов. Мы здесь чтобы помочь Вам защититься!`,
    [lang.EN]: `In the modern world, your data is of great value to certain mercantile agents. We are here to help you defend yourself!`,
    [lang.DE]: `In der modernen Welt sind Ihre Daten für bestimmte Handelsagenten von großem Wert. Wir sind hier, um Ihnen zu helfen, sich zu verteidigen!`,
}
const whatWeDontBelieve_1_strong = {
    [lang.RU]: `Ваши данные не важны. `,
    [lang.EN]: `Your data is not important. `,
    [lang.DE]: `Ihre Daten sind nicht wichtig. `,
}

const whatWeDontBelieve_2 = {
    [lang.RU]: `Задайте себе вопрос - как и за счёт чего существуют бесплатные VPN провайдеры? Они собирают информацию о вас и продают их третьим лицам.`,
    [lang.EN]: `Ask yourself the question - how and due to what free VPN providers exist? They collect information about you and sell it to third parties.`,
    [lang.DE]: `Stellen Sie sich die Frage - wie und aufgrund welcher kostenlosen VPN-Anbieter gibt es? Sie sammeln Informationen über Sie und verkaufen sie an Dritte.`,
}
const whatWeDontBelieve_2_strong = {
    [lang.RU]: `Бесплатному VPN стоит доверять. `,
    [lang.EN]: `A free VPN should be trusted. `,
    [lang.DE]: `Einem kostenlosen VPN sollte vertraut werden. `,
}

const whatWeDontBelieve_3 = {
    [lang.RU]: `Если VPN провайдер  имеет и активно применяет инструменты для анализа вашего трафика (например фильтрует его на предмет рекламы) или вынуждает Вас устанавливать закрытое проприетарное ПО, то он имеет множество возможностей для слежки за вами. Некоторые даже удаляют Ваши данные со своих серверов за отдельную плату`,
    [lang.EN]: `If a VPN provider has and actively uses tools to analyze your traffic (for example, filters it for advertising) or forces you to install non-free proprietary software, then it has many opportunities to spy on you. Some even delete your data from their servers for a fee`,
    [lang.DE]: `Wenn ein VPN-Anbieter Tools zur Analyse Ihres Datenverkehrs besitzt und aktiv einsetzt (z. B. nach Werbung filtert) oder Sie zwingt, geschlossene proprietäre Software zu installieren, dann hat er viele Möglichkeiten, Sie auszuspionieren. Einige löschen Ihre Daten sogar gegen eine Gebühr von ihren Servern`,
}
const whatWeDontBelieve_3_strong = {
    [lang.RU]: `Популярным и "продвинутым" VPN стоит доверять. `,
    [lang.EN]: `Popular and "advanced" VPNs should be trusted. `,
    [lang.DE]: `Beliebten und "fortgeschrittenen" VPNs sollte vertraut werden. `,
}

////////////////////

const whatCanVpn_header = {
    [lang.RU]: `Что позволяет сделать VPN`,
    [lang.EN]: `What does a VPN allow you to do`,
    [lang.DE]: `Was können Sie mit einem VPN tun`,
}

const whatCanVpn_1 = {
    [lang.RU]: `Спрятать Ваш реальный IP адрес от посещаемых вами сайтов и интернет сервисов`,
    [lang.EN]: `Hide your real IP address from the websites and Internet services you visit`,
    [lang.DE]: `Verbergen Sie Ihre echte IP-Adresse vor den von Ihnen besuchten Websites und Internetdiensten`,
}

const whatCanVpn_2 = {
    [lang.RU]: `Получить доступ к ресурсам, недоступным из вашей локации`,
    [lang.EN]: `Gain access to resources not available from your location`,
    [lang.DE]: `Erhalten Sie Zugriff auf Ressourcen, die an Ihrem Standort nicht verfügbar sind`,
}

const whatCanVpn_3 = {
    [lang.RU]: `Обойти блокировки интернет сервисов по географическому принципу (geo ip)`,
    [lang.EN]: `Bypass blocking of Internet services by geographical principle (geo ip)`,
    [lang.DE]: `Sperrung von Internetdiensten nach geografischem Prinzip umgehen (Geo-IP)`,
}

const whatCanVpn_4 = {
    [lang.RU]: `Добавить слой шифрования вашему трафику, обезопасить себя даже на случай его перехвата`,
    [lang.EN]: `Add an encryption layer to your traffic, protect yourself even in case it is intercepted`,
    [lang.DE]: `Fügen Sie Ihrem Datenverkehr eine Verschlüsselungsschicht hinzu, schützen Sie sich selbst für den Fall, dass er abgefangen wird`,
}

/////////////////

const whoSpiesOnYou_header = {
    [lang.RU]: `Кто и зачем за вами следит?`,
    [lang.EN]: `Who is spying on you and why?`,
    [lang.DE]: `Wer spioniert dich aus und warum?`,
}

const whoSpiesOnYou_1 = {
    [lang.RU]: `Ваш провайдер способен просматривать все, что вы передаете через интернет в незашифрованном виде, а также видит, куда и когда вы подключаетесь. Именно поэтому стоит пользоваться VPN!`,
    [lang.EN]: `Your provider is able to view everything you transmit over the Internet in unencrypted form, and also sees where and when you connect. That's why it's worth using a VPN!`,
    [lang.DE]: `Ihr Provider kann alles, was Sie über das Internet übertragen, unverschlüsselt einsehen und sieht auch, wo und wann Sie sich verbinden. Deshalb lohnt es sich, ein VPN zu verwenden!`,
}
const whoSpiesOnYou_1_strong = {
    [lang.RU]: `ISP.`,
    [lang.EN]: `ISP.`,
    [lang.DE]: `ISP.`,
}

const whoSpiesOnYou_2 = {
    [lang.RU]: `Все приложения, которые вы устанавливаете на ваше устройство, могут иметь доступ не только к вашему трафику, но и к данным других запускаемых приложений. Все это может быть использовано для показа раздражающей таргетированной рекламы.`,
    [lang.EN]: `Any apps you install on your device can have access not only to your traffic, but also to the data of other apps you run. All of this can be used to display annoying targeted ads.`,
    [lang.DE]: `Alle Apps, die Sie auf Ihrem Gerät installieren, können nicht nur auf Ihren Datenverkehr, sondern auch auf die Daten anderer von Ihnen ausgeführter Apps zugreifen. All dies kann genutzt werden, um lästige gezielte Werbung anzuzeigen.`,
}
const whoSpiesOnYou_2_strong = {
    [lang.RU]: `Сторонние приложения.`,
    [lang.EN]: `Third party applications.`,
    [lang.DE]: `Anwendungen von Drittherstellern.`,
}

const whoSpiesOnYou_3 = {
    [lang.RU]: `Большинство VPN сервисов (не мы!) в коммерческих целях передают ваши личные данные третьим лицам! Для них это способ неплохо заработать`,
    [lang.EN]: `Most VPN services (not us!) transfer your personal data to third parties for commercial purposes! For them, this is a way to make good money`,
    [lang.DE]: `Die meisten VPN-Dienste (nicht wir!) ihre persönlichen Daten zu kommerziellen Zwecken an Dritte weiterzugeben! Für sie ist dies eine Möglichkeit, gutes Geld zu verdienen`,
}
const whoSpiesOnYou_3_strong = {
    [lang.RU]: `VPN сервисы.`,
    [lang.EN]: `VPN Providers.`,
    [lang.DE]: `VPN-Anbieter. `,
}

const whoSpiesOnYou_4 = {
    [lang.RU]: `Различного рода сетевые атаки могут быть совершены на сегмент вашей сети. Не дайте возможности недоброжелателям из интернета определить ваш IP.`,
    [lang.EN]: `Various types of network attacks can be carried out on a segment of your network. Do not allow Internet attackers to determine your IP.`,
    [lang.DE]: `Auf ein Segment Ihres Netzwerks können verschiedene Arten von Netzwerkangriffen ausgeführt werden. Erlauben Sie Internet-Angreifern nicht, Ihre IP zu ermitteln.`,
}
const whoSpiesOnYou_4_strong = {
    [lang.RU]: `Злоумышленники.`,
    [lang.EN]: `Intruders.`,
    [lang.DE]: `Eindringling. `,
}

const whoSpiesOnYou_5 = {
    [lang.RU]: `Они всё о вас знают. И даже VPN не поможет, если вы интегрированны в их инфраструктуру, использующую закрытые системы.`,
    [lang.EN]: `They know all about you. VPN is not an option if you are integrated into their infrastructure using enclosed systems.`,
    [lang.DE]: `Sie wissen alles über dich. VPN ist keine Option, wenn Sie über geschlossene Systeme in deren Infrastruktur eingebunden sind.`,
}
const whoSpiesOnYou_5_strong = {
    [lang.RU]: `Корпорации.`,
    [lang.EN]: `Corporations.`,
    [lang.DE]: `Unternehmen. `,
}

////////////////////////////////

const whyWeAreBetter_header = {
    [lang.RU]: `Причины выбрать именно нас`,
    [lang.EN]: `Reasons to choose us`,
    [lang.DE]: `Gründe, sich für uns zu entscheiden`,
}

const whyWeAreBetter_1 = {
    [lang.RU]: `Наши файлы конфигурации можно легко проанализировать. Наш сервис построен на основе открытых и постоянно проверяемых технологий.`,
    [lang.EN]: `Our configuration files can be easily analyzed. Our service is built on the top of open and constantly assured technologies`,
    [lang.DE]: `Unsere Konfigurationsdateien können einfach analysiert werden. Unser Service basiert auf offenen und stets gesicherten Technologien`,
}
const whyWeAreBetter_2 = {
    [lang.RU]: `Начать работать с нами можно полностью анонимно`,
    [lang.EN]: `You can start working with us completely anonymously`,
    [lang.DE]: `Sie können völlig anonym mit uns zusammenarbeiten`,
}
const whyWeAreBetter_3 = {
    [lang.RU]: `Вы можете управлять услугами через личный кабинет в Telegram`,
    [lang.EN]: `You can manage the services through your personal account in Telegram`,
    [lang.DE]: `Sie können die Dienste über Ihr persönliches Konto in Telegram verwalten`,
}
const whyWeAreBetter_4 = {
    [lang.RU]: `Мы не ведём никаких логов посещений`,
    [lang.EN]: `We do not keep any visit logs`,
    [lang.DE]: `Wir sammeln keine Logs`,
}
const whyWeAreBetter_5 = {
    [lang.RU]: `Мы предоставляем честную долю пропускной способности сервера`,
    [lang.EN]: `We provide a fair share of server bandwidth`,
    [lang.DE]: `Wir bieten einen fairen Anteil an Serverbandbreite`,
}
const whyWeAreBetter_6 = {
    [lang.RU]: `Мы один из доступнейших по цене VPN сервис`,
    [lang.EN]: `We are one of the most affordable VPN service`,
    [lang.DE]: `Wir sind einer der günstigsten VPN-Dienste`,
}
const whyWeAreBetter_7 = {
    [lang.RU]: `Мы принимаем оплату в криптовалюте`,
    [lang.EN]: `We accept payment in cryptocurrency`,
    [lang.DE]: `Wir akzeptieren Zahlungen in Kryptowährung`,
}

//////////

const whyWeUseOpenvpn_header = {
    [lang.RU]: `Почему мы используем открытые протоколы?`,
    [lang.EN]: `Why do we use opensource protocols?`,
    [lang.DE]: `Warum verwenden wir OpenSource-Protokolle?`,
}

const whyWeUseOpenvpn_main = {
    [lang.RU]: `Разработка, тестирование и поддержка стойкого и безопасного VPN протокола - тяжкая и долгая работа. Портирование сервиса на разные платформы потребует много ресурсов и высокий уровень экспертизы, отлов багов займёт годы, а аудит будет стоить немалую сумму. Мы предпочитаем идти разумным путём и строим наш сервис поверх популярнейших и открытых протоколов. Нет смысла устанавливать на Ваше устройство очередное проприетарное приложение, вам потребуется лишь импортировать конфигурационный файл, с содержанием которого вы при желании можете ознакомиться. Это позволяет нам предоставлять один из самых недорогих сервисов на рынке`,
    [lang.EN]: `Developing, testing and maintaining a strong and secure VPN protocol is hard and time-consuming work. Porting a service to different platforms will require a lot of resources and a high level of expertise, catching bugs will take years, and an audit will cost a considerable amount. We prefer to take a reasonable route and build our service on top of the most popular and open protocols. There is no point in installing another proprietary application on your device. You only need to import a configuration file. This allows us to provide one of the most inexpensive services on the market`,
    [lang.DE]: `Das Entwickeln, Testen und Verwalten eines starken und sicheren VPN-Protokolls ist harte und zeitaufwändige Arbeit. Die Portierung eines Dienstes auf verschiedene Plattformen erfordert viele Ressourcen und ein hohes Maß an Fachwissen, das Auffinden von Fehlern wird Jahre dauern und eine Prüfung wird erhebliche Kosten verursachen. Wir bevorzugen einen vernünftigen Weg und bauen unseren Service auf den beliebtesten und offensten Protokollen auf. Es macht keinen Sinn, eine andere proprietäre Anwendung auf Ihrem Gerät zu installieren. Sie müssen lediglich eine Konfigurationsdatei importieren. Dies ermöglicht es uns, eine der kostengünstigsten Dienstleistungen auf dem Markt anzubieten`,
}

export const AboutLang = {
    whoAreWe_header,
    whoAreWe_main,
    whoAreWe_private,
    whoAreWe_private_phrase,
    whoAreWe_open,
    whoAreWe_open_phrase,
    whoAreWe_novelness,
    whoAreWe_novelness_phrase,
    whatWeDo_header,
    whatWeDo_1,
    whatWeDo_2,
    whatWeDo_3,
    whatWeDo_4,
    whatWeDontDo_header,
    whatWeDontDo_1,
    whatWeDontDo_2,
    whatWeDontDo_3,
    whatWeDontDo_4,
    weHaveTelegramBot_header,
    weHaveTelegramBot_1,
    weHaveTelegramBot_2,
    weHaveTelegramBot_3,
    weHaveTelegramBot_of_course,
    whatWeDontBelieve_header,
    whatWeDontBelieve_1,
    whatWeDontBelieve_1_strong,
    whatWeDontBelieve_2,
    whatWeDontBelieve_2_strong,
    whatWeDontBelieve_3,
    whatWeDontBelieve_3_strong,
    whatCanVpn_header,
    whatCanVpn_1,
    whatCanVpn_2,
    whatCanVpn_3,
    whatCanVpn_4,
    whoSpiesOnYou_header,
    whoSpiesOnYou_1,
    whoSpiesOnYou_1_strong,
    whoSpiesOnYou_2,
    whoSpiesOnYou_2_strong,
    whoSpiesOnYou_3,
    whoSpiesOnYou_3_strong,
    whoSpiesOnYou_4,
    whoSpiesOnYou_4_strong,
    whoSpiesOnYou_5,
    whoSpiesOnYou_5_strong,
    whyWeAreBetter_header,
    whyWeAreBetter_1,
    whyWeAreBetter_2,
    whyWeAreBetter_3,
    whyWeAreBetter_4,
    whyWeAreBetter_5,
    whyWeAreBetter_6,
    whyWeAreBetter_7,
    whyWeUseOpenvpn_header,
    whyWeUseOpenvpn_main
}