import React from "react";

import topPic from "resources/images/prices/top.webp";
import bottomPic from "resources/images/prices/bottom.webp";
import Constructor from "js/pages/constructor/Constructor";
import { ConstLang } from "js/languages/common/constructor";


export default function PricesPage({ state, setState }) {
    let lang = state.language;
    let theme = state.themeLight;
    return (
        <>

            <section className={theme ? "common_section" : "common_section common_section_dark"}>
                <div className="common_footer_header_gap"></div>
                <AboutConstructor lang={lang} theme={theme} />

                <div className="common_filler">
                    <div className="common_container">
                        <div className="prices_constructor_title" id="buy">{ConstLang.select_your_settings[lang]}</div>
                        <Constructor state={state} setState={setState} />
                    </div>
                </div>

                <Line lang={lang} theme={theme} />

                <Pluses lang={lang} theme={theme} />
            </section>
        </>
    );
}

function AboutConstructor({ lang, theme }) {
    return (
        <section className={theme ? "common_section" : "common_section common_section_dark"}>
            <div className="common_container">
                <div className="common_grid_2">
                    <img alt="#" className="common_image" src={topPic} />
                    <div className="common_area">
                        <div className="common_slogan">{ConstLang.you_can_shoose_title[lang]}</div>
                        <div className="common_text">
                            <div className="who_we_are_3">
                                <div className="common_paragraph">
                                    {ConstLang.you_can_shoose_text[lang]}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </ section>
    );
}

function Line({ lang, theme }) {
    return (
        <div className={theme ? "prices_page_background" : "prices_page_background prices_page_background_dark"}>
            <div className="common_container">
                <h3 className={theme ? "common_h3_slogan" : "common_h3_slogan common_h3_slogan_dark"}>
                    {ConstLang.conn_limit[lang]}
                </h3>
            </div>
        </div>
    );
}


function Pluses({ lang, theme }) {
    return (
        <section className={theme ? "common_section" : "common_section common_section_dark"}>
            <div className="common_container">
                <div className="common_grid_2">

                    <div className="common_area_mr">
                        <div className="common_slogan">{ConstLang.prices_part_title[lang]}</div>
                        <div className="common_text">
                            <div className="who_we_are_3">
                                <div className="common_phrase">
                                    &#10004; {ConstLang.prices_part_1[lang]}
                                </div>
                                <div className="common_phrase">
                                    &#10004; {ConstLang.prices_part_2[lang]}
                                </div>
                                <div className="common_phrase">
                                    &#10004; {ConstLang.prices_part_3[lang]}
                                </div>
                                <div className="common_phrase">
                                    &#10004; {ConstLang.prices_part_4[lang]}
                                </div>
                            </div>
                        </div>
                    </div>
                    <img alt="#" className="common_image" src={bottomPic} />
                </div>
            </div>
            <div className="common_footer_header_gap"></div>
        </ section>
    );
}
