import {lang} from "./lang_enum";

export const header_about = {
    [lang.RU]: `О нас`,
    [lang.EN]: `About`,
    [lang.DE]: `Über uns`,
}

export const header_prices = {
    [lang.RU]: `Цены`,
    [lang.EN]: `Prices`,
    [lang.DE]: `Preise`,
}
export const header_profile = {
    [lang.RU]: `Профиль`,
    [lang.EN]: `Profile`,
    [lang.DE]: `Profil`,
}

export const header_login = {
    [lang.RU]: `Войти`,
    [lang.EN]: `Login`,
    [lang.DE]: `Login`,
}