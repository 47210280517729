import React from "react";

import { FaqTelegram } from "js/languages/faq/telegram";

import errImage from "../../../resources/images/error/error.webp";

export default function ErrorPage({ state }) {

    const lang = state.language;
    return (
        <section className={state.themeLight ? "common_section" : "common_section common_section_dark"}>
            <div className="common_filler">
                <div className="common_container">
                    <div className="common_grid_2">
                        <div>
                            <div className="common_panorama_name">{FaqTelegram.err_something_wrong[lang]}</div>
                            {/*<div className="common_panorama_slogan">{FaqTelegram.tg_manage_your_services[lang]}</div>*/}
                            <p>{FaqTelegram.err_error_info[lang]}: support@ponvpn.com</p>
                            <a href="mailto:support@ponvpn.com"
                                className={state.themeLight ? "common_panorama_button common_fancy_button" : "common_panorama_button common_fancy_button common_fancy_button_dark"}>
                                {FaqTelegram.err_open_mail[lang]}
                            </a>
                        </div>
                        <img alt="#" className="common_panorama_image common_image_br_05" src={errImage} />
                    </div>
                </div>
            </div>
        </section>
    );
}