import React from "react";
import { Route, Routes, useLocation, useNavigate, NavLink } from "react-router-dom";

import Buy from "js/pages/cabinet/Buy";
import CabinetSupport from "js/pages/cabinet/CabinetSupport";
import Certificates from "js/pages/cabinet/certificates/Certificates";

import { Cookier } from "js/logic/Cookier";
import { CabLang } from "js/languages/cabinet/cabinet";
import Balance from "js/pages/cabinet/balance/Balance";
import { BalanceCryptoCloud } from "js/pages/cabinet/balance/BalanceCryptoCloud";
import { Monero } from "js/pages/cabinet/balance/Monero";
import { Requester } from "js/logic/Requester";

export default function Cabinet({ state, setState }) {

    const navigate = useNavigate();
    const location = useLocation();
    const lang = state.language;

    const [warning, setWarning] = React.useState(false);
    const [customWarningText, setCustomWarningText] = React.useState("");

    let screen = state.cabinetScreen;
    if (["/cabinet/support", "/cabinet/support/"].includes(location.pathname)) {
        screen = "support";
    }
    if (location.pathname.includes("/cabinet/profile")) {
        screen = "profile";
    }
    if (["/cabinet/buy", "/cabinet/buy/"].includes(location.pathname)) {
        screen = "buy";
    }
    if (["/cabinet/certificates", "/cabinet/certificates/"].includes(location.pathname)) {
        screen = "certificates";
    }

    async function checkWarning() {
        let ans = await Requester.me();
        if (ans === Requester.unathe) {
            navigate("/enter")
            return;
        }
        state.user.setWarning(ans.user.warning);
        state.user.setCustomWarningText(ans.user.customWarningText);
        setWarning(ans.user.warning);
        setCustomWarningText(ans.user.customWarningText);
    }

    function setScreen(name) {
        let newState = { ...state };
        newState.cabinetScreen = name;
        setState(newState);
    }

    React.useEffect(() => {
        if (!Cookier.checkToken()) navigate("/enter");

        if (window.matchMedia('screen and (min-width: 48rem)').matches && ["/cabinet", "/cabinet/"].includes(location.pathname)) {
            navigate("/cabinet/certificates");
            setScreen("certificates");
        }

        let newState = { ...state };
        newState.footerVisible = false;
        setState(newState);

        checkWarning();

    }, []);

    function top() {
        window.scrollTo({ top: 0 })
    }

    function Menu({ state, mobile }) {
        useLocation();
        return (
            <>
                <div className={mobile ? state.themeLight ? "cabinet_menu_mobile" : "cabinet_menu_mobile cabinet_menu_mobile_dark" : state.themeLight ? "cabinet_menu_hidable" : "cabinet_menu_hidable cabinet_menu_hidable_dark"}>
                    <NavLink to="/cabinet/certificates"
                        className={screen === "certificates" ? "cabinet_menu_element cabinet_menu_element_active cabinet_menu_element_first" :
                            "cabinet_menu_element cabinet_menu_element_first"}
                        onClick={() => { setScreen("certificates"); top() }}
                    >
                        {CabLang.menu_my[lang]}
                    </NavLink>
                    <NavLink to="/cabinet/buy" className={screen === "buy" ? "cabinet_menu_element cabinet_menu_element_active" :
                        "cabinet_menu_element"}
                        onClick={() => { setScreen("buy"); top() }}
                    >
                        {CabLang.menu_buy[lang]}
                    </NavLink>
                    <NavLink to="/cabinet/profile" className={screen === "profile" ? "cabinet_menu_element cabinet_menu_element_active" :
                        "cabinet_menu_element"}
                        onClick={() => { setScreen("profile"); top() }}
                    >
                        {CabLang.menu_profile[lang]}
                    </NavLink>
                    <NavLink to="/cabinet/support" className={screen === "support" ? "cabinet_menu_element cabinet_menu_element_last  cabinet_menu_element_active" :
                        "cabinet_menu_element cabinet_menu_element_last"}
                        onClick={() => { setScreen("support"); top() }}
                    >
                        {CabLang.menu_support[lang]}
                    </NavLink>
                </div>
                <div className={!mobile ? "common_invisible" : "cabinet_menu_hidable"}>
                    Select
                </div>
            </>
        );
    }

    if (Cookier.checkToken())
        return (
            <div className="common_filler">
                <div className="cabinet_header_gap"></div>
                <div className="common_container">
                    {warning ?
                        <div className="cabinet_warning_area">
                            <div className="cabinet_warning_text">
                                {
                                    (customWarningText != null && customWarningText != undefined && customWarningText != "") ?
                                        customWarningText : CabLang.default_warning_text[lang]
                                }
                            </div>
                        </div>
                        : ""
                    }
                </div>
                <div className="common_container common_mobile_full_width_container">
                    <div className="cabinet_area">
                        <Menu mobile={false} state={state} />
                        <div className={state.themeLight ? "cabinet_body" : "cabinet_body cabinet_body_dark"}>
                            <Routes>
                                <Route path="/" element={<Menu mobile={true} state={state} />} />
                                <Route path="certificates" element={<Certificates state={state} setState={setState} />} />
                                <Route path="buy" element={<Buy state={state} setState={setState} />} />
                                <Route path="profile" element={<Balance state={state} setState={setState} />} />
                                <Route path="support" element={<CabinetSupport state={state} setState={setState} />} />

                                <Route path="profile/cryptocloud" element={<BalanceCryptoCloud state={state} />} />
                                <Route path="profile/monero" element={<Monero state={state} />} />
                            </Routes>
                        </div>
                    </div>
                </div>
            </div>
        );
}