// REACT
import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
// JS
import BackToMenu from "js/pages/common/BackToMenu"
import { Requester } from "js/logic/Requester";
import { CabLang } from "js/languages/cabinet/cabinet";
import { Cookier } from "js/logic/Cookier";
// IMAGES
import btcIco from "resources/icons/common/crypto/btc.webp";
import ltcIco from "resources/icons/common/crypto/ltc.webp";
import ethIco from "resources/icons/common/crypto/eth.webp";
import xmrIco from "resources/icons/common/crypto/xmr.webp";
import usdtIco from "resources/icons/common/crypto/usdt.webp";

export default function Balance({ state, setState }) {

    let re = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,30}$");

    const navigate = useNavigate();

    const lang = state.language;

    const [email, setEmail] = React.useState(state.user.data.email);

    const [newPassword, setNewPassword] = React.useState("");
    const [confirmPassword, setConfirmPassword] = React.useState("");
    const [oldPassword, setOldPassword] = React.useState("");
    const [failedPass, setFailedPass] = React.useState(false);

    const [promo, setPromo] = React.useState("");

    async function tryPromo() {
        let ans = await Requester.apply_promo(promo);
        if (ans === Requester.unathe) {
            navigate("/enter")
            return;
        }
        if (ans === Requester.promo_err) alert("Failed");
        if (ans === Requester.promo_ok) {
            alert("Success");
            window.location.reload();
        }
    }

    React.useEffect(() => {
        balance();
    }, []);

    async function balance() {
        let ans = await Requester.me();
        if (ans === Requester.unathe) {
            navigate("/enter")
            return;
        }
        state.user.setEmailAndBalance(ans.user.email, ans.user.balance);
        setEmail(ans.user.email);
        state.user.setTelegramId(ans.user.telegramId);
        state.user.setMoneroAddress(ans.user.moneroAddress);
    }

    async function emailSet() {
        let ans = await Requester.setEmail(email);
        if (ans === Requester.unathe) {
            navigate("/enter")
            return;
        }
        window.location.reload();
    }

    async function passwordSet() {
        if (!re.test(confirmPassword)) {
            alert(CabLang.profile_password_requirements[lang]);
            return;
        }
        let ans = await Requester.setPassword(oldPassword, confirmPassword);
        if (ans === Requester.unathe) {
            navigate("/enter")
            return;
        }
        setNewPassword("");
        setConfirmPassword("");
        setOldPassword("");
        if (ans === Requester.incor_pass) {
            setFailedPass(true);
            return;
        }
        window.location.reload();
    }

    function top() {
        window.scrollTo({ top: 0 })
    }

    return (
        <>
            <BackToMenu state={state} setState={setState} />

            <div className="cabinet_body_internal">
                <div>
                    <div className="cabinet_title">{CabLang.profile_title[lang]}</div>


                    <div className="cabinet_paragraph">
                        <span className="profile_option">{CabLang.profile_username[lang]}:</span>
                        <span className="constructor_paragraph_text">{Cookier.getUsername()}</span>
                    </div>
                    <div className="cabinet_paragraph">
                        <span className="profile_option">{CabLang.profile_email[lang]}:</span>

                        <input className={state.themeLight ? "cabinet_input" : "cabinet_input cabinet_input_dark"}
                            type="text"
                            maxLength="255"
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value);
                            }}>
                        </input>
                        {email !== state.user.data.email &&
                            <button className="common_fancy_button profile_save_button" onClick={() => emailSet()}>{CabLang.profile_save_button[lang]}</button>}
                    </div>

                    <div className="cabinet_paragraph">
                        <span className="profile_option">{CabLang.profile_telegram[lang]}</span>
                        <span className="constructor_paragraph_text">
                            {state.user.data.telegramId == null &&
                                <span className="constructor_paragraph_text">{CabLang.profile_telegram_not_connected[lang]}
                                    <NavLink className={state.themeLight ? "cabinet_total_card_link" : "cabinet_total_card_link cabinet_total_card_link_dark"} to="/telegram">{CabLang.profile_telegram_about[lang]}</NavLink></span>}
                            {state.user.data.telegramId != null &&
                                <span
                                    className="constructor_paragraph_text">{CabLang.profile_telegram_connected[lang]}{state.user.data.telegramId}</span>}
                        </span>
                    </div>

                    <div className={newPassword.length === 0 ? "profile_password_area" : "profile_password_area_active"}>
                        <div className="cabinet_paragraph">
                            <span className="profile_option">{CabLang.profile_password_new[lang]}:</span>
                            <input className={state.themeLight ? "cabinet_input" : "cabinet_input cabinet_input_dark"}
                                type="password" autoComplete="new-password"
                                value={newPassword}
                                onChange={(e) => {
                                    setNewPassword(e.target.value);
                                }}>
                            </input>
                        </div>
                        {
                            newPassword.length > 0 &&
                            <div className="cabinet_paragraph">
                                <span className="profile_option">{CabLang.profile_password_repeat[lang]}:</span>
                                <input className={state.themeLight ? "cabinet_input" : "cabinet_input cabinet_input_dark"}
                                    type="password" autoComplete="new-password"
                                    value={confirmPassword}
                                    onChange={(e) => {
                                        setConfirmPassword(e.target.value);
                                    }}>
                                </input>
                            </div>
                        }
                        {
                            newPassword === confirmPassword && newPassword !== "" &&
                            <div className="cabinet_paragraph">
                                <span className="profile_option">{CabLang.profile_password_old[lang]}:</span>
                                <input className={state.themeLight ? "cabinet_input" : "cabinet_input cabinet_input_dark"}
                                    type="password" autoComplete="new-password"
                                    value={oldPassword}
                                    onChange={(e) => {
                                        setOldPassword(e.target.value);
                                    }}>
                                </input>
                                <button className="common_fancy_button profile_save_button" onClick={() => passwordSet()}>
                                    {CabLang.profile_save_button[lang]}
                                </button>
                            </div>
                        }
                        {
                            newPassword !== confirmPassword && confirmPassword !== "" &&
                            <div className="cabinet_paragraph">
                                <span className="profile_option">{CabLang.profile_password_dont_match[lang]}</span>
                            </div>
                        }
                        {
                            failedPass &&
                            <div className="cabinet_paragraph">
                                <span className="profile_option">{CabLang.profile_password_incorrect[lang]}</span>
                            </div>
                        }
                    </div>

                    <div className="cabinet_paragraph">
                        <span className="constructor_paragraph_text">
                            {CabLang.profile_have_an_issue[lang]}
                            <NavLink to="/faq/contacts" className={state.themeLight ? "cabinet_total_card_link" : "cabinet_total_card_link cabinet_total_card_link_dark"}>{CabLang.profile_contact_us[lang]}</NavLink>
                        </span>
                    </div>

                    <div className="cabinet_paragraph">
                        <span className="profile_option">Promo code:</span>

                        <input className={state.themeLight ? "cabinet_input" : "cabinet_input cabinet_input_dark"}
                            type="text"
                            value={promo}
                            onChange={(e) => {
                                setPromo(e.target.value);
                            }}>
                        </input>
                        {promo !== "" &&
                            <button className="common_fancy_button profile_save_button" onClick={() => tryPromo()}>Send</button>}
                    </div>

                    <div className="cabinet_paragraph">
                        <span className="profile_option">
                            <button className={state.themeLight ? "common_fancy_button" : "common_fancy_button common_fancy_button_dark"}
                                onClick={() => {
                                    Cookier.deleteToken();
                                    state.logined = false;
                                    setState({ ...state });
                                    window.location.reload();
                                }}>{CabLang.profile_logout[lang]}</button>
                        </span>
                    </div>

                    <div className="cabinet_title">{CabLang.profile_balance[lang]}: {state.user.data.balance}$</div>

                    <div className="cabinet_paragraph">
                        <span className="profile_option_wide">{CabLang.profile_balance_you_can_popup[lang]}</span>
                    </div>
                    <div className="cabinet_paragraph">
                        <span className="profile_option_wide">
                            <NavLink to="/faq/payments" className={state.themeLight ? "cabinet_total_card_link" : "cabinet_total_card_link cabinet_total_card_link_dark"}>{CabLang.profile_balance_faq_info[lang]}</NavLink>
                        </span>
                    </div>
                    <NavLink className={state.themeLight ? "profile_popup_partner" : "profile_popup_partner profile_popup_partner_dark"}
                        to="/cabinet/profile/cryptocloud"
                        onClick={() => top()}>
                        <div className="profile_popup_partner_title">CryptoCloud</div>
                        <div className="profile_popup_partner_currencies">
                            <img src={btcIco} alt={""} />
                            <img src={ltcIco} alt={""} />
                            <img src={ethIco} alt={""} />
                            <img src={usdtIco} alt={""} />
                        </div>
                        <div className="profile_popup_partner_description">{CabLang.profile_balance_cryptocloud_info[lang]}</div>
                    </NavLink>
                    <NavLink className={state.themeLight ? "profile_popup_partner" : "profile_popup_partner profile_popup_partner_dark"}
                        to="/cabinet/profile/monero"
                        onClick={() => top()}>
                        <div className="profile_popup_partner_title">Monero</div>
                        <div className="profile_popup_partner_currencies">
                            <img src={xmrIco} alt={""} />
                        </div>
                        <div className="profile_popup_partner_description">
                            {CabLang.profile_balance_monero_info[lang]}
                        </div>
                    </NavLink>
                </div>
            </div>

        </>
    );
};