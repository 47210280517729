import { lang } from "../common/lang_enum";

const menu_protocols = {
    [lang.RU]: `Протоколы`,
    [lang.EN]: `Protocols`,
    [lang.DE]: `Protokolle`,
}

const menu_protocols_openvpn = {
    [lang.RU]: `OpenVPN`,
    [lang.EN]: `OpenVPN`,
    [lang.DE]: `OpenVPN`,
}

const menu_protocols_wireguard = {
    [lang.RU]: `WireGuard`,
    [lang.EN]: `WireGuard`,
    [lang.DE]: `WireGuard`,
}

const menu_protocols_shadowsocks = {
    [lang.RU]: `Shadowsocks`,
    [lang.EN]: `Shadowsocks`,
    [lang.DE]: `Shadowsocks`,
}

const menu_protocols_vless = {
    [lang.RU]: `VLESS`,
    [lang.EN]: `VLESS`,
    [lang.DE]: `VLESS`,
}

const menu_protocols_vmess = {
    [lang.RU]: `VMess`,
    [lang.EN]: `VMess`,
    [lang.DE]: `VMess`,
}

const menu_protocols_trojan = {
    [lang.RU]: `Trojan`,
    [lang.EN]: `Trojan`,
    [lang.DE]: `Trojan`,
}

const menu_payment = {
    [lang.RU]: `Оплата`,
    [lang.EN]: `Payment`,
    [lang.DE]: `Zahlung`,
}

const menu_payment_info = {
    [lang.RU]: `Информация об оплате`,
    [lang.EN]: `Payment Info`,
    [lang.DE]: `Zahlungsinformationen`,
}

const menu_payment_cryptocloud = {
    [lang.RU]: `CryptoCloud`,
    [lang.EN]: `CryptoCloud`,
    [lang.DE]: `CryptoCloud`,
}

const menu_payment_monero = {
    [lang.RU]: `Monero`,
    [lang.EN]: `Monero`,
    [lang.DE]: `Monero`,
}

const menu_payment_moneyback = {
    [lang.RU]: `Возврат средств`,
    [lang.EN]: `Moneyback`,
    [lang.DE]: `Geld-zurück`,
}

const menu_rules = {
    [lang.RU]: `Правила`,
    [lang.EN]: `Rules`,
    [lang.DE]: `Regeln`,
}

const menu_terms_of_service = {
    [lang.RU]: `Правила сервиса`,
    [lang.EN]: `Terms of Service`,
    [lang.DE]: `Nutzungsbedingungen`,
}

const menu_privacy_policy = {
    [lang.RU]: `Политика приватности`,
    [lang.EN]: `Privacy Policy`,
    [lang.DE]: `Datenschutzrichtlinie`,
}

const menu_contacts = {
    [lang.RU]: `Контакты`,
    [lang.EN]: `Contacts`,
    [lang.DE]: `Kontakte`,
}

export const FaqMenuLang = {
    menu_protocols,
    menu_protocols_openvpn,
    menu_protocols_wireguard,
    menu_protocols_shadowsocks,
    menu_protocols_vless,
    menu_protocols_vmess,
    menu_protocols_trojan,
    menu_payment,
    menu_payment_info,
    menu_payment_cryptocloud,
    menu_payment_monero,
    menu_payment_moneyback,
    menu_rules,
    menu_terms_of_service,
    menu_privacy_policy,
    menu_contacts,
}