const sliderPositionToMbsValue = {
    1: 1,
    2: 5,
    3: 10,
    4: 25,
    5: 50,
};

const sliderPositionToPeriodValue = {
    1: 1,
    2: 3,
    3: 6,
    4: 12
}


export const Tariffer = {
    availablePositionsMbs: Object.keys(sliderPositionToMbsValue),
    minPositionMbs: 1,
    maxPositionMbs: 5,
    minPositionPeriod: 1,
    maxPositionPeriod: 4,
    sliderPositionToMbsValue: (pos) => sliderPositionToMbsValue[pos],
    sliderPositionToPeriodValue: (pos) => sliderPositionToPeriodValue[pos],
    mbsValueToPosition: (mbs) => Object.keys(sliderPositionToMbsValue).find(key => sliderPositionToMbsValue[key] === mbs)
};