import Contacts from "js/pages/faq/Contacts";
import BackToMenu from "js/pages/common/BackToMenu"

export default function CabinetSupport({ state, setState }) {
    return (
        <>
            <div className="">
                <BackToMenu state={state} setState={setState} />
                <div className="support_contacts_wrapper">
                    <Contacts state={state} setState={setState} display_back={false} />
                </div>
            </div>
        </>
    );
}