import btcIco from "resources/icons/common/crypto/btc.webp";
import ltcIco from "resources/icons/common/crypto/ltc.webp";
import ethIco from "resources/icons/common/crypto/eth.webp";
import xmrIco from "resources/icons/common/crypto/xmr.webp";
import usdtIco from "resources/icons/common/crypto/usdt.webp";

import React from "react";
import { Route, Routes, NavLink, useLocation, useNavigate } from "react-router-dom";

import { FooterLang } from "js/languages/common/footer";


export default function Footer({ state }) {

    const navigate = useNavigate();

    function top() {
        window.scrollTo({ top: 0 })
    }

    const lang = state.language;
    return (
        <div className={state.themeLight ? "footer_area" : "footer_area footer_area_dark"}>



            <div className="common_container">
                <div className="footer_row">

                    <div className="footer_col">
                        <div className="footer_logo" onClick={() => { navigate("/"); top() }}>PonVPN</div>
                        <div
                            className={state.themeLight ? "footer_col_elements" : "footer_col_elements footer_col_elements_dark"}>
                            <div className="footer_slogan">
                                {FooterLang.best_vpn[lang]}
                            </div>

                            <div className="footer_pon">
                                {FooterLang.pon[lang]}
                            </div>

                            <div className="footer_currencies">
                                <div className="footer_currency"> <img src={xmrIco} alt={""} /> </div>
                                <div className="footer_currency"> <img src={btcIco} alt={""} /> </div>
                                <div className="footer_currency"> <img src={ltcIco} alt={""} /> </div>
                                <div className="footer_currency"> <img src={ethIco} alt={""} /> </div>
                                <div className="footer_currency"> <img src={usdtIco} alt={""} /> </div>
                            </div>
                        </div>
                    </div>

                    <div className="footer_col">
                        <div className="footer_col_header">{FooterLang.company[lang]}</div>
                        <div
                            className={state.themeLight ? "footer_col_elements" : "footer_col_elements footer_col_elements_dark"}>
                            <NavLink to={{pathname: "/about"}} onClick={() => top()}>{FooterLang.about_us[lang]}</NavLink>
                            <NavLink to={{pathname: "/telegram"}} onClick={() => top()}>{FooterLang.tg[lang]}</NavLink>
                            <NavLink to={{pathname: "/faq/contacts"}} onClick={() => top()}>{FooterLang.contacts[lang]}</NavLink>
                            <NavLink to={{pathname: "/faq/privacy_policy"}} onClick={() => top()}>{FooterLang.privacy_policy[lang]}</NavLink>
                            <NavLink to={{pathname: "/faq/terms"}} onClick={() => top()}>{FooterLang.terms[lang]}</NavLink>
                            <NavLink to={{pathname: "/faq/payments"}} onClick={() => top()}>{FooterLang.payments[lang]}</NavLink>
                        </div>
                    </div>
                    <div className="footer_col">
                        <div className="footer_col_header">{FooterLang.protocols[lang]}</div>
                        <div
                            className={state.themeLight ? "footer_col_elements" : "footer_col_elements footer_col_elements_dark"}>
                            <NavLink to={{pathname: "/faq/openvpn"}} onClick={() => top()}>OpenVPN</NavLink>
                            <NavLink to={{pathname: "/faq/wireguard"}} onClick={() => top()}>WireGuard</NavLink>
                            <NavLink to={{pathname: "/faq/shadowsocks"}} onClick={() => top()}>Shadowsocks</NavLink>
                            <NavLink to={{pathname: "/faq/vless"}} onClick={() => top()}>VLESS</NavLink>
                            <NavLink to={{pathname: "/faq/vmess"}} onClick={() => top()}>VMess</NavLink>
                            <NavLink to={{pathname: "/faq/trojan"}} onClick={() => top()}>Trojan</NavLink>
                        </div>
                    </div>
                </div>
            </div>



        </div>
    );
}