import React from "react";
import { useNavigate, NavLink } from "react-router-dom";

import Certificate from "js/pages/cabinet/certificates/Certificate";
import BackToMenu from "js/pages/common/BackToMenu";
import Loading from "js/pages/common/Loading";

import { getPreparingCerts, getReadyCerts, Requester } from "js/logic/Requester";
import { ConstLang } from "js/languages/common/constructor";
import { CabLang } from "js/languages/cabinet/cabinet";

export default function Certificates({ state, setState }) {

    let lang = state.language;

    const navigate = useNavigate();

    const [alertVisible, setAlertVisible] = React.useState(false);

    const [loading, setLoading] = React.useState(true);

    const attemtsFetchPreparing = React.useRef(0);

    React.useEffect(() => {
        reload();
    }, []);

    async function reload() {
        if (state.certs.data.preparingCerts.length === 0) setLoading(true);
        all();
        preparing();
    }

    const all = async () => {
        if (state.certs.data.needUpdateAllCerts) {
            let all = await Requester.getAllCerts();
            if (all === Requester.unathe) navigate("/enter");
            let ready = all.certs.filter(e => (e.action === "RUNNING" || e.action === "MOVING"));
            let preparing = all.certs.filter(e => (e.action !== "RUNNING" && e.action !== "MOVING" && e.action !== "STOPPED") && e.status !== "ERROR");
            let error = all.certs.filter(e => (e.status === "ERROR" && e.action !== "MOVING"));
            let stopped = all.certs.filter(e => (e.status !== "ERROR" && e.action === "STOPPED"));

            state.certs.setReadyCerts(ready);
            state.certs.setPreparingCerts(preparing);
            state.certs.setErrorCerts(error);
            state.certs.setStoppedCerts(stopped);

            if (all.certs.filter(
                (e) => (e.action === "CREATING" || e.action === "SPEEDING") && e.status === "OK"
            ).length > 0) {
                setTimeout(() => reload(), 10000);
            } else {
                state.certs.setNeedUpdateAllCerts(false);
            }
        }
        setLoading(false);
    };


    const preparing = async () => {
        if (state.certs.data.needUpdatePreparingCerts) {
            let prep = await Requester.getPreparingCerts();
            if (prep === Requester.unathe) navigate("/enter");

            state.certs.setPreparingCerts(prep.preparing);

            if (prep.preparing.length === 0 && attemtsFetchPreparing.current < 5) {
                setTimeout(() => preparing(), 1000);
                attemtsFetchPreparing.current += 1;
            } else {
                state.certs.setNeedUpdatePreparingCerts(false);
                state.certs.setNeedUpdateAllCerts(true);
                all();
            }
        }

    };


    return (
        <>
            <div className="">
                <BackToMenu state={state} setState={setState} />
                <Alert visible={alertVisible} />
                {(state.certs.data.readyCerts.length > 0 || state.certs.data.preparingCerts.length > 0)
                    && <div className="certificates_title">{CabLang.certs_your_vpns[lang]}</div>}
                {(loading || state.certs.data.needUpdatePreparingCerts) && <><br /><br /><br /><Loading state={state} text={CabLang.certs_loading[lang]} /></>}

                {(state.certs.data.readyCerts.length === 0 && state.certs.data.preparingCerts.length === 0)
                    && !loading && <div className="certificates_title">{CabLang.certs_no_vpns_yet[lang]}</div>}
                {
                    state.certs.data.readyCerts.map((e, i) => <Certificate key={i}
                        id={e.id}
                        country={e.country}
                        ip={e.ip}
                        bandwidth={e.bandwidth}
                        nameP={e.name}
                        protocol={e.protocol}
                        price={e.price}
                        prolongP={e.prolong}
                        until={e.paidTill}
                        text={e.text}
                        setAlertVisible={setAlertVisible}
                        lang={lang}
                        reload={reload}
                        state={state}
                    />)
                }
                {
                    state.certs.data.preparingCerts.map((e, i) => <PreparingCertificate
                        key={i}
                        id={e.id}
                        ip={e.ip}
                        country={e.country}
                        bandwidth={e.bandwidth}
                        nameP={e.name}
                        protocol={e.protocol}
                        price={e.price}

                        setAlertVisible={setAlertVisible}
                        lang={lang}
                        state={state}
                    />)
                }
                {
                    state.certs.data.errorCerts.map((e, i) => <ErrorCertificate
                        key={i}
                        id={e.id}
                        ip={e.ip}
                        country={e.country}
                        bandwidth={e.bandwidth}
                        nameP={e.name}
                        protocol={e.protocol}
                        price={e.price}

                        setAlertVisible={setAlertVisible}
                        lang={lang}
                        state={state}
                    />)
                }
                {
                    state.certs.data.stoppedCerts.map((e, i) => <StopeedCertificate
                        key={i}
                        id={e.id}
                        ip={e.ip}
                        country={e.country}
                        bandwidth={e.bandwidth}
                        nameP={e.name}
                        protocol={e.protocol}
                        price={e.price}

                        setAlertVisible={setAlertVisible}
                        lang={lang}
                        state={state}
                    />)
                }
            </div>

        </>
    );
}

function Alert({ visible }) {
    return (
        <div className={visible ? "certificates_alert" : "common_invisible"}>Certificate text copied to clipboard</div>
    )
}

function PreparingCertificate({ state, ip, country, bandwidth, nameP, protocol, price, lang }) {
    return (
        <>
            <div className="certificates_main">
                <div className={state.themeLight ? "certificates_content" : "certificates_content certificates_content_dark"}>
                    <div className={state.themeLight ? "certificates_side1" : " certificates_side1 certificates_side1_dark"}>
                        <div
                            className="certificates_param certificates_first_param">{ConstLang.country[lang]}: {ConstLang.countryNameByCode(country.code)[lang]}</div>
                        <div className="certificates_param">Ip: {ip}</div>
                        <div className="certificates_param">{ConstLang.price[lang]}: {price}$/{ConstLang.per_month[lang]}</div>
                        <div className="certificates_param certificates_title_bw">{ConstLang.speed[lang]}: {bandwidth}mb/s</div>
                    </div>
                    <div
                        className={state.themeLight ? "certificates_side2" : "certificates_side2 certificates_side2_dark"}>
                        <div className="certificates_param certificates_first_param">{protocol} <NavLink to={`/faq/${protocol.toLowerCase()}`} className={state.themeLight ? "cabinet_total_card_link" : "cabinet_total_card_link cabinet_total_card_link_dark"}>{ConstLang.protoc_about[lang]}</NavLink>
                        </div>
                        <div className="certificates_name">{nameP}</div>
                        <div className="certificates_card_ip">{ConstLang.preparing_cert[lang]}</div>
                        <div className="certificates_card_buttons_area">
                            <div className="certificates_dual_ring"></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

function ErrorCertificate({ state, id, ip, country, bandwidth, nameP, protocol, price, lang }) {
    return (
        <>

            <div className="certificates_main certificates_error_cert">
                <div className={state.themeLight ? "certificates_content" : "certificates_content certificates_content_dark"}>
                    <div className={state.themeLight ? "certificates_side1" : " certificates_side1 certificates_side1_dark"}>
                        <div className="certificates_param certificates_first_param">{ConstLang.country[lang]}: {ConstLang.countryNameByCode(country.code)[lang]}</div>
                        <div className="certificates_param">Ip: {ip}</div>
                        <div className="certificates_param">{ConstLang.price[lang]}: {price}$/{ConstLang.per_month[lang]}</div>
                        <div className="certificates_param certificates_title_bw">{ConstLang.speed[lang]}: {bandwidth}mb/s</div>
                    </div>
                    <div
                        className={state.themeLight ? "certificates_side2" : "certificates_side2 certificates_side2_dark"}>
                        <div className="certificates_param certificates_first_param">{protocol} <NavLink to={`/faq/${protocol.toLowerCase()}`} className={state.themeLight ? "cabinet_total_card_link" : "cabinet_total_card_link cabinet_total_card_link_dark"}>{ConstLang.protoc_about[lang]}</NavLink>
                        </div>
                        <div className="certificates_name">{nameP}</div>
                        <div className="certificates_card_ip">{ConstLang.something_wrong[lang]}{id}</div>
                        <div className="certificates_card_buttons_area">
                            <div className="certificates_dual_ring"></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

function StopeedCertificate({ state, id, ip, country, bandwidth, nameP, protocol, price, lang }) {
    return (
        <>

            <div className="certificates_main certificates_error_cert">
                <div className={state.themeLight ? "certificates_content" : "certificates_content certificates_content_dark"}>
                    <div className={state.themeLight ? "certificates_side1" : " certificates_side1 certificates_side1_dark"}>
                        <div className="certificates_param certificates_first_param">{ConstLang.country[lang]}: {ConstLang.countryNameByCode(country.code)[lang]}</div>
                        <div className="certificates_param">Ip: {ip}</div>
                        <div className="certificates_param">{ConstLang.price[lang]}: {price}$/{ConstLang.per_month[lang]}</div>
                        <div className="certificates_param certificates_title_bw">{ConstLang.speed[lang]}: {bandwidth}mb/s</div>
                    </div>
                    <div
                        className={state.themeLight ? "certificates_side2" : "certificates_side2 certificates_side2_dark"}>
                        <div className="certificates_param certificates_first_param">{protocol} <NavLink to={`/faq/${protocol.toLowerCase()}`} className={state.themeLight ? "cabinet_total_card_link" : "cabinet_total_card_link cabinet_total_card_link_dark"}>{ConstLang.protoc_about[lang]}</NavLink>
                        </div>
                        <div className="certificates_name">{nameP}</div>
                        <div className="certificates_card_ip">{ConstLang.stopped_cert[lang]}</div>
                        <div className="certificates_card_buttons_area">
                            {/* <div className="certificates_dual_ring"></div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}