import { MainLang } from "js/languages/index/main";
import { Cookier } from "js/logic/Cookier";
import { lang } from "js/languages/common/lang_enum";

// const domain = "http://192.168.1.114:9090";
// const domain = window.location.origin;
const domain = "https://www.ponvpn.com";

const unathe = "UNATHE";
const noserver = "NOSERVER";
const incor_pass = "INCORRECT_PASSWORD";
const captch = "CAPTCHA_ERROR";

const promo_ok = "PROMO_OK";
const promo_err = "PROMO_ERR";


let language = Cookier.getLanguage();
if (language == null || language === "")
    language = lang.EN;

const errorText = MainLang.broken[language];


const defaultHeaders = () => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    return headers;
}

const defaultHeadersWithToken = () => {
    let headers = defaultHeaders();
    headers.append("Authorization", `Bearer ${Cookier.getToken()}`);
    return headers;
}


const register = async (email, username, password, captchaId, captchaEpoch, captchaAnswer) => {
    const requestOptions = {
        method: 'POST',
        headers: defaultHeaders(),
        body: JSON.stringify({ email, username, password, captchaId, captchaEpoch, captchaAnswer })
    };

    try {
        const response = await fetch(`${domain}/api/public/enter/register`, requestOptions);
        if (response.status === 200) return response.json();
        if (response.status === 203) return response.json();
        if (response.status === 204) {
            return captch;
        }
        if (response.status === 403) return unathe;
    } catch {
        alert(errorText);
        return unathe;
    }
};

const getCaptcha = async () => {
    const requestOptions = {
        method: 'GET',
        headers: defaultHeaders(),
    };

    try {
        const response = await fetch(`${domain}/api/public/enter/captcha`, requestOptions);
        if (response.ok) return response.json();
        if (response.status === 403) return unathe;
    } catch {
        alert(errorText);
        return unathe;
    }
};

const login = async (username, password) => {
    const requestOptions = {
        method: 'POST',
        headers: defaultHeaders(),
        body: JSON.stringify({ username, password })
    };

    try {
        const response = await fetch(`${domain}/api/public/enter/login`, requestOptions);
        if (response.ok) return response.json();
        if (response.status === 403) return unathe;
    } catch {
        alert(errorText);
        return unathe;
    }
};


const buy = async (country, protocol, bandwidth, months) => {
    let requestOptions = {
        method: 'POST',
        headers: defaultHeadersWithToken(),
        body: JSON.stringify({ country, protocol, bandwidth, months })
    };

    try {
        let response = await fetch(`${domain}/api/private/certificate/buy`, requestOptions);

        if (response.ok) {
            return response.json();
        }
        if (response.status === 403) return unathe;
        if (response.status === 404) return noserver;
    } catch {
        alert(errorText);
        return unathe;
    }
};

const getReadyCerts = async () => {
    let requestOptions = {
        method: 'GET',
        headers: defaultHeadersWithToken(),
    };

    try {
        let response = await fetch(`${domain}/api/private/certificate/ready`, requestOptions);

        if (response.ok) return response.json();

        if (response.status === 403) return unathe;
    } catch {
        alert(errorText);
        return unathe;
    }
};

const getPreparingCerts = async () => {
    let requestOptions = {
        method: 'GET',
        headers: defaultHeadersWithToken(),
    };

    try {
        let response = await fetch(`${domain}/api/private/certificate/preparing`, requestOptions);

        if (response.ok) return response.json();
        if (response.status === 403) return unathe;
    } catch {
        alert(errorText);
        return unathe;
    }
};

const getAllCerts = async () => {
    let requestOptions = {
        method: 'GET',
        headers: defaultHeadersWithToken(),
    };

    try {
        let response = await fetch(`${domain}/api/private/certificate/all`, requestOptions);

        if (response.ok) return response.json();
        if (response.status === 403) return unathe;
    } catch {
        alert(errorText);
        return unathe;
    }
};

const calculateCertificatePrice = async (mbs, months, protocol) => {
    let requestOptions = {
        method: 'POST',
        headers: defaultHeaders(),
        body: JSON.stringify({ mbs, months, protocol: protocol.toUpperCase(), time: new Date().getTime() })
    };

    try {
        let response = await fetch(`${domain}/api/public/price`, requestOptions);

        if (response.ok) return response.json();
        if (response.status === 404) return unathe;
    } catch {
        alert(errorText);
        return unathe;
    }
};
const calculateCertificateDiffPrice = async (oldMbs, newMbs, protocol) => {
    let requestOptions = {
        method: 'POST',
        headers: defaultHeaders(),
        body: JSON.stringify({ oldMbs, newMbs, protocol: protocol.toUpperCase(), time: new Date().getTime() })
    };

    try {
        let response = await fetch(`${domain}/api/public/price/diff`, requestOptions);

        if (response.ok) return response.json();
        if (response.status === 404) return unathe;
    } catch {
        alert(errorText);
        return unathe;
    }
};

const popupBalance = async (amount) => {
    let requestOptions = {
        method: 'POST',
        headers: defaultHeadersWithToken(),
        body: JSON.stringify({ amount }),
    };

    try {
        let response = await fetch(`${domain}/api/private/pay/balance`, requestOptions);

        if (response.ok) return response.json();
        if (response.status === 404) return unathe;
    } catch {
        alert(errorText);
        return unathe;
    }
}

const updateCert = async (id, name, bandwidth, prolong) => {
    let requestOptions = {
        method: 'PATCH',
        headers: defaultHeadersWithToken(),
        body: JSON.stringify({ id, name, bandwidth, prolong }),
    };

    try {
        let response = await fetch(`${domain}/api/private/certificate/update`, requestOptions);

        if (response.ok) return response.json();
        if (response.status === 404) return unathe;
    } catch {
        alert(errorText);
        return unathe;
    }
}

const me = async () => {
    let requestOptions = {
        method: 'GET',
        headers: defaultHeadersWithToken(),
    };

    try {
        let response = await fetch(`${domain}/api/private/user/me`, requestOptions);

        if (response.ok) return response.json();
        if (response.status === 404) return unathe;
    } catch {
        alert(errorText);
        return unathe;
    }
}

const apply_promo = async (code) => {
    let requestOptions = {
        method: 'POST',
        headers: defaultHeadersWithToken(),
    };
    let response;
    try {
        response = await fetch(`${domain}/api/private/user/promo_code/${code}`, requestOptions);

        if (response.ok) {
            return promo_ok;
        } else if (response.status === 404) {
            return promo_err;
        } else {
            return unathe;
        }
    } catch {
        return promo_err;
    }
}

const setEmail = async (email) => {
    let requestOptions = {
        method: 'PATCH',
        headers: defaultHeadersWithToken(),
        body: JSON.stringify({ email }),
    };

    try {
        let response = await fetch(`${domain}/api/private/user/set_email`, requestOptions);

        if (response.ok) return response.json();
        if (response.status === 404) return unathe;
    } catch {
        alert(errorText);
        return unathe;
    }
}

const setPassword = async (oldPassword, password) => {
    let requestOptions = {
        method: 'PATCH',
        headers: defaultHeadersWithToken(),
        body: JSON.stringify({ oldPassword, password }),
    };

    try {
        let response = await fetch(`${domain}/api/private/user/set_password`, requestOptions);

        if (response.ok) return response.json();
        if (response.status === 404) return unathe;
        if (response.status === 401) {
            return incor_pass;
        }
    } catch {
        alert(errorText);
        return unathe;
    }
}

const downloadCert = async (id) => {
    let requestOptions = {
        method: 'GET',
        headers: defaultHeadersWithToken(),
    };

    try {
        let response = await fetch(`${domain}/api/public/certificate/download_cert/${id}/${Cookier.getToken()}`, requestOptions);

        if (response.ok) return response.json();
        if (response.status === 404) return unathe;
    } catch {
        alert(errorText);
        return unathe;
    }
}

export const Requester = {
    register,
    login,
    getReadyCerts,
    calculateCertificatePrice,
    calculateCertificateDiffPrice,
    getPreparingCerts,
    getAllCerts,
    buy,
    unathe,
    noserver,
    incor_pass,
    captch,
    popupBalance,
    updateCert,
    me,
    setEmail,
    setPassword,
    getCaptcha,
    apply_promo,
    promo_err,
    promo_ok,
    downloadCert
}
