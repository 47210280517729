import { FaqPayments } from "js/languages/faq/money";
import BackToFaq from "js/pages/common/BackToFaq";


export default function Moneyback({ state, setState }) {

    const lang = state.language;
    return (
        <>
            <div className="faq_body_internal">
            <BackToFaq state={state} setState={setState} />
            <div className="common_protocol_title">{FaqPayments.mb_title[lang]}</div>
            <div>{FaqPayments.mb_ve_provide_service[lang]}</div>
            <div>{FaqPayments.mb_criteria[lang]}</div>
            <div className="faq_terms_conditions">
                <div><strong>{FaqPayments.mb_timeframe_title[lang]}</strong> {FaqPayments.mb_timeframe[lang]}</div>
                <div><strong>{FaqPayments.mb_operability_title[lang]}</strong> {FaqPayments.mb_operability[lang]}</div>
            </div>
            <div>{FaqPayments.mb_we_reserve_right[lang]}</div>
            <div className="faq_terms_conditions">
                <div>{FaqPayments.mb_your_responsibility[lang]}</div>
                <div><strong>{FaqPayments.mb_only_xmr[lang]}</strong>
                </div>
            </div>{FaqPayments.mb_contact_us[lang]}<div>
            </div>
            </div>
        </>
    )
}