import { CabLang } from "js/languages/cabinet/cabinet";
import BackToFaq from "js/pages/common/BackToFaq";



export default function PaymentInfo({ state, setState }) {

    const lang = state.language;
    return (
        <>
            <div className="faq_body_internal">
            <BackToFaq state={state} setState={setState} />
            <div className="common_protocol_title">{CabLang.payment_header[lang]}</div>

            <div className="cabinet_title">Monero</div>


                <span className="constructor_paragraph_text">
                    {CabLang.profile_monero_info_1[lang]}
                </span>
                <span className="constructor_paragraph_text">
                    {CabLang.profile_monero_info_2[lang]}
                </span>

            <div className="cabinet_title">CryptoCloud</div>


                <span className="constructor_paragraph_text">
                    {CabLang.profile_cryptocloud_info_1[lang]}
                </span>
                <span className="constructor_paragraph_text">
                    {CabLang.profile_cryptocloud_info_2[lang]}
                </span>



            </div>
        </>
    )
}