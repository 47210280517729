import { lang } from "./lang_enum";

const best_vpn = {
    [lang.RU]: `Лучший VPN для обучения, бизнеса и ежедневного использования.`,
    [lang.EN]: `Best VPN for study, business and daily use.`,
    [lang.DE]: `Bestes VPN für Studium, Geschäft und Alltag.`,
}

const pon = {
    [lang.RU]: `Приватный. Открытый. Новый.`,
    [lang.EN]: `Private. Open. New.`,
    [lang.DE]: `Privat. Offen. Neu.`,
}

const company = {
    [lang.RU]: `Компания`,
    [lang.EN]: `Company`,
    [lang.DE]: `Gesellschaft`,
}

const protocols = {
    [lang.RU]: `Протоколы`,
    [lang.EN]: `Protocols`,
    [lang.DE]: `Protokolle`,
}

const about_us = {
    [lang.RU]: `О нас`,
    [lang.EN]: `About us`,
    [lang.DE]: `Über uns`,
}

const tg = {
    [lang.RU]: `Телеграм бот`,
    [lang.EN]: `Telegram bot`,
    [lang.DE]: `Telegram-Bot`,
}

const contacts = {
    [lang.RU]: `Контакты`,
    [lang.EN]: `Contacts`,
    [lang.DE]: `Kontakte`,
}

const privacy_policy = {
    [lang.RU]: `Политика приватности`,
    [lang.EN]: `Privacy Policy`,
    [lang.DE]: `Datenschutzrichtlinie`,
}

const terms = {
    [lang.RU]: `Условия пользования`,
    [lang.EN]: `Terms of service`,
    [lang.DE]: `Nutzungsbedingungen`,
}

const payments = {
    [lang.RU]: `Оплата`,
    [lang.EN]: `Payments`,
    [lang.DE]: `Zahlung`,
}

export const FooterLang = {
    best_vpn,
    pon,
    company,
    protocols,
    about_us,
    tg,
    contacts,
    privacy_policy,
    terms,
    payments
}