import React from "react";


import { header_about, header_prices, header_profile, header_login } from "js/languages/common/header";

import { Route, Routes, NavLink, useLocation, useNavigate } from "react-router-dom";
import { Cookier } from "js/logic/Cookier";
import { lang } from "js/languages/common/lang_enum";
import logoImg from "resources/icons/common/logo.webp";


export default function Header({ state, setState }) {

    const location = useLocation();

    const [ch, setCh] = React.useState(false);

    function changeLanguage(language) {
        let newState = { ...state };
        Cookier.setLanguage(language);
        newState.language = language;
        setState(newState);
    }

    function changeTheme() {
        Cookier.saveThemeAndSetState({ i: state, set: setState }, !state.themeLight);
        if (state.themeLight) document.documentElement.style.background = "var(--color_background_dark)";
        else document.documentElement.style.background = "var(--color_background_light)";
    }

    function top() {
        window.scrollTo({ top: 0 })
    }

    const language = state.language;

    return (
        <div className={state.themeLight ? "header" : "header header_dark"}>
            <div className="common_container">
                <div className="header_grid">
                    <div className="header_logo_and_selector">
                        <NavLink to="/">
                            <div className="header_logo_icon">
                                <img src={logoImg} alt={""} />
                            </div>
                            <div
                                className={state.themeLight ? "header_logo_letters" : "header_logo_letters header_logo_letters_dark"}>PonVPN
                            </div>
                        </NavLink>

                        <select defaultValue={Cookier.getLanguage() != null && Cookier.getLanguage() !== undefined && Cookier.getLanguage() !== "" ? Cookier.getLanguage() : lang.EN}
                            className={state.themeLight ? "selector" : "selector selector_dark"}
                            onChange={(e) => changeLanguage(e.target.value)}>
                            <option value={lang.EN}>English</option>
                            <option value={lang.RU}>Русский</option>
                            <option value={lang.DE}>Deutsch</option>
                        </select>

                        <label className="header_switch">
                            <input type="checkbox"
                                // checked={!state.themeLight}
                                checked={!Cookier.getTheme()}
                                onChange={() => changeTheme()} />
                            <span className="header_slider"></span>
                        </label>

                    </div>


                    <input type="checkbox" id="header_menu_checkbox" checked={ch} onChange={() => {
                        setCh(!ch)
                    }} />
                    <label htmlFor="header_menu_checkbox" className="header_menu_checkbox_label"><span></span></label>

                    <nav className={state.themeLight ? "header_menu" : "header_menu header_menu_dark"}>
                        <NavLink to="/about" onClick={() => {setCh(false); top()}}>{header_about[language]}</NavLink>
                        <NavLink to="/prices" onClick={() => {setCh(false); top()}}>{header_prices[language]}</NavLink>
                        <NavLink to="/faq" onClick={() => {setCh(false); top()}}>FAQ</NavLink>
                        <NavLink to="/telegram" onClick={() => {setCh(false); top()}}>Telegram</NavLink>
                        {
                            !state.logined
                                ? <NavLink to="/enter" onClick={() => {setCh(false); top()}}>{header_login[language]}</NavLink>
                                : <NavLink to={location.pathname.includes("cabinet") ? "/cabinet/certificates" : "/cabinet"}
                                    onClick={() => {setCh(false); top()}}>{header_profile[language]}
                                </NavLink>
                        }
                    </nav>

                </div>
            </div>
        </div>
    )
}