import React from "react";

import { NavLink, useNavigate } from "react-router-dom";

import Loading from "js/pages/common/Loading";

import { Requester } from "js/logic/Requester";
import { CabLang } from "js/languages/cabinet/cabinet";


export function BalanceCryptoCloud({ state }) {

    const lang = state.language;
    useNavigate();
    const [amount, setAmount] = React.useState(1);

    const [loading, setLoading] = React.useState(false);

    async function popup() {
        if (loading) return;
        setLoading(true);
        let response = await Requester.popupBalance(amount);
        window.location = response.link;
    }

    function checkAmount() {
        const amountRegex = /^\d{1,4}(\.\d{1,2})?$/;
        if(amountRegex.test(amount)){
            popup();
        } else {
            alert(CabLang.amount_regex[lang]);
        }
    }

    return (
        <>
            <div className="cabinet_back_to_menu_wrapper">
                <NavLink className="common_fancy_button" to="/cabinet/profile">{CabLang.menu_back[lang]}</NavLink>
            </div>
            <div className="cabinet_body_internal">

                <div className="cabinet_title">CryptoCloud</div>
                <div className="cabinet_paragraph">
                    <span className="constructor_paragraph_text">
                        {CabLang.cryptocloud_speech_1[lang]}
                    </span>
                </div>

                <div className="cabinet_paragraph">
                    <span className="constructor_paragraph_text">
                        {CabLang.cryptocloud_speech_2[lang]}
                    </span>
                </div>

                <div className="cabinet_paragraph">
                    <span className="constructor_paragraph_text">
                        {CabLang.cryptocloud_speech_3[lang]}
                    </span>
                </div>

                <div className="cabinet_paragraph">
                    <span className="cabinet_paragraph_title">{CabLang.cryptocloud_amount_in_dollars[lang]}</span>
                    <span className="cryptocloud_paragraph_text">
                        <input className={state.themeLight ? "cabinet_input" : "cabinet_input cabinet_input_dark"}
                            type="text"
                            value={amount}
                            onChange={(e) => {
                                setAmount(e.target.value);
                            }}
                        >
                        </input>
                        <button className="common_fancy_button" onClick={checkAmount}>
                            {CabLang.cryptocloud_pay_button[lang]}
                        </button>
                    </span>
                </div>

                {loading && <Loading text={CabLang.preparing_payment_page[lang]} state={state} />}
            </div>
        </>
    );
}