export default function Loading({state, text}) {

    return (
        <>
            <div className={state.themeLight ? "loading_spinner": "loading_spinner loading_spinner_dark"}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div className={state.themeLight ? "loading_text" : "loading_text loading_text_dark"}>{text}</div>
        </>
    );
}