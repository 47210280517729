import { lang } from "./lang_enum";

const protocols = [
    {
        name: "OpenVPN",
        description: {
            [lang.RU]: `OpenVPN — это протокол виртуальной частной сети (VPN) с открытым исходным кодом, который обеспечивает безопасную связь через Интернет. Он использует собственный протокол безопасности, основанный в первую очередь на библиотеке OpenSSL, для установления безопасного соединения точка-точка или сервер-клиент в маршрутизируемых конфигурациях.`,
            [lang.EN]: `OpenVPN is an open-source software application that implements virtual private network (VPN) techniques for creating secure point-to-point or site-to-site connections in routed or bridged configurations and remote access facilities.`,
            [lang.DE]: `OpenVPN ist ein Open-Source-Protokoll für virtuelle private Netzwerke (VPN), das sichere Kommunikation über das Internet ermöglicht. Es verwendet ein benutzerdefiniertes Sicherheitsprotokoll, das hauptsächlich auf der OpenSSL-Bibliothek basiert, um eine sichere Punkt-zu-Punkt- oder Standort-zu-Standort-Verbindung in gerouteten oder gebrückten Konfigurationen herzustellen.`,
        }
    },
    {
        name: "WireGuard",
        description: {
            [lang.RU]: `WireGuard это инновационный и легкий протокол для виртуальных частных сетей (VPN), целью которого является обеспечение простой настройки и высокой производительности.`,
            [lang.EN]: `WireGuard is an innovative and lightweight protocol for Virtual Private Networks (VPN) that aims to provide easy configuration and high performance.`,
            [lang.DE]: `WireGuard ist ein innovatives und schlankes Protokoll für Virtual Private Networks (VPN), das darauf abzielt, einfache Konfiguration und hohe Leistung zu bieten. `,
        }
    },
    {
        name: "Shadowsocks",
        description: {
            [lang.RU]: `Shadowsocks это легкий прокси-протокол с открытым исходным кодом. Первоначально разработанный в Китае, он завоевал популярность во всем мире, поскольку эффективно обеспечивает безопасный и конфиденциальный доступ в Интернет.`,
            [lang.EN]: `Shadowsocks is a lightweight and open source proxy protocol. Originally developed in China, it has gained popularity worldwide as it effectively provides secure and private internet access.`,
            [lang.DE]: `Shadowsocks ist ein leichtgewichtiges und Open-Source-Proxy-Protokoll. Ursprünglich in China entwickelt, hat es weltweit an Beliebtheit gewonnen, da es effektiv sicheren und privaten Internetzugang ermöglicht.`,
        }
    },
    {
        name: "VLESS",
        description: {
            [lang.RU]: `VLESS означает "VMess-less", — это протокол, используемый для связи между клиентом и сервером в рамках служб VPN. VLESS связан с проектом V2Ray, платформой с открытым исходным кодом, предназначенной для улучшения возможностей других протоколов VPN и предоставления дополнительных функций.`,
            [lang.EN]: `VLESS, which stands for "VMess-less" is a protocol used for communication between a client and a server in the context of VPN (Virtual Private Network) services. VLESS is associated with the V2Ray project, an open-source platform designed to improve upon and provide additional features beyond the capabilities of other VPN protocols.`,
            [lang.DE]: `VLESS, was für „VMess-less“ steht, ist ein Protokoll, das für die Kommunikation zwischen einem Client und einem Server im Rahmen von VPN-Diensten verwendet wird. VLESS ist mit dem V2Ray-Projekt verbunden, einer Open-Source-Plattform, die darauf ausgelegt ist, die Fähigkeiten anderer VPN-Protokolle zu verbessern und zusätzliche Funktionen bereitzustellen.`,
        }
    },
    {
        name: "VMess",
        description: {
            [lang.RU]: `VMess ("Versatile Messaging Stream") представляет собой протокол связи, используемый в контексте служб VPN. VMess связан с проектом V2Ray, платформой с открытым исходным кодом, целью которой является предоставление расширенных функций для безопасной связи. VMess в экосистеме V2Ray выбирают пользователи и провайдеры VPN, которым нужны расширенные функции, возможности настройки и надежная безопасность своих VPN-подключений.`,
            [lang.EN]: `VMess, which stands for "Versatile Messaging Stream" is a communication protocol used in the context of VPN services. VMess is associated with the V2Ray project, an open-source platform that aims to provide advanced features for secure communication. VMess, within the V2Ray ecosystem, is chosen by users and VPN providers who seek advanced features, customization options, and strong security for their VPN connections.`,
            [lang.DE]: `VMess, was für „Versatile Messaging Stream“ steht, ist ein Kommunikationsprotokoll, das im Zusammenhang mit VPN-Diensten verwendet wird. VMess ist mit dem V2Ray-Projekt verbunden, einer Open-Source-Plattform, die darauf abzielt, erweiterte Funktionen für sichere Kommunikation bereitzustellen. VMess wird innerhalb des V2Ray-Ökosystems von Benutzern und VPN-Anbietern ausgewählt, die erweiterte Funktionen, Anpassungsoptionen und starke Sicherheit für ihre VPN-Verbindungen suchen.`,
        }
    },
    {
        name: "Trojan",
        description: {
            [lang.RU]: `Trojan это современный протокол VPN, маскирующий свой трафик под обычный HTTPS-трафик. Это обеспечивает его эффективность в обходе DPI (глубокая проверка пакетов).`,
            [lang.EN]: `Trojan is a modern VPN protocol that disguises its traffic as regular HTTPS traffic. This ensures its effectiveness in circumventing DPI (Deep Packet Inspection).`,
            [lang.DE]: `Trojan ist ein modernes VPN-Protokoll, das seinen Datenverkehr als regulären HTTPS-Datenverkehr tarnt. Dies stellt die Wirksamkeit bei der Umgehung von DPI (Deep Packet Inspection) sicher.`,
        }
    }
];


const countries = [
    {
        code: "USA",
        name: {
            [lang.RU]: `США`,
            [lang.EN]: `USA`,
            [lang.DE]: `USA`,
        }
    },
    {
        code: "NLD",
        name: {
            [lang.RU]: `Нидерланды`,
            [lang.EN]: `Netherlands`,
            [lang.DE]: `Niederlande`,
        }
    },
    {
        code: "GBR",
        name: {
            [lang.RU]: `Великобритания`,
            [lang.EN]: `United Kingdom`,
            [lang.DE]: `Großbritannien`,
        }
    },
    {
        code: "DEU",
        name: {
            [lang.RU]: `Германия`,
            [lang.EN]: `Germany`,
            [lang.DE]: `Deutschland`,
        }
    },
    // {
    //     code: "AUT",
    //     name: {
    //         [lang.RU]: `Австрия`,
    //         [lang.EN]: `Austria`,
    //         [lang.DE]: `Österreich`,
    //     }
    // },
    // {
    //     code: "CAN",
    //     name: {
    //         [lang.RU]: `Канада`,
    //         [lang.EN]: `Canada`,
    //         [lang.DE]: `Kanada`,
    //     }
    // },

];

const moneybackSpeach = {
    [lang.RU]: `Мы отвечаем за качество наших услуг, и ваше удовлетворение является нашим главным приоритетом. Мы уверены, что вам понравится то, что мы предлагаем, поэтому подкрепляем это нашей беспроблемной гарантией возврата средств.`,
    [lang.EN]: `We stand behind the quality of our services, and your satisfaction is our top priority. We're so confident that you'll love what we offer that we back it up with our hassle-free money-back guarantee.`,
    [lang.DE]: `Wir stehen hinter der Qualität unserer Dienstleistungen und Ihre Zufriedenheit hat für uns oberste Priorität. Wir sind so überzeugt, dass Ihnen unser Angebot gefallen wird, dass wir dies mit unserer problemlosen Geld-zurück-Garantie untermauern.`,
}

const moreInfoMoneyback = {
    [lang.RU]: `Возврат средств`,
    [lang.EN]: `Moneyback`,
    [lang.DE]: `Erstattung`,
}

const paymentSpeachUnlogined = {
    [lang.RU]: `Сначала вам необходимо создать аккаунт и пополнить баланс.`,
    [lang.EN]: `You should create account and top up your balance first.`,
    [lang.DE]: `Sie sollten zunächst ein Konto erstellen und Ihr Guthaben aufladen.`,
}

const paymentSpeachLogined = {
    [lang.RU]: `Средства будут списаны с текущего баланса аккаунта.`,
    [lang.EN]: `Funds will be credited from your current account balance.`,
    [lang.DE]: `Der Betrag wird von Ihrem aktuellen Kontostand abgezogen.`,
}

const moreInfoPayment = {
    [lang.RU]: `Способы оплаты.`,
    [lang.EN]: `Payment options.`,
    [lang.DE]: `Zahlungsmöglichkeiten.`,
}

const agreeWithTerms = {
    [lang.RU]: `Согласен с правилами сервиса`,
    [lang.EN]: `Accept terms of service`,
    [lang.DE]: `Stimmen Sie den Nutzungsbedingungen zu`,
}

const mustAgree = {
    [lang.RU]: `Для продолжерния Вам необходимо согласиться с правилами сервиса.`,
    [lang.EN]: `To continue, you should agree with the terms of service.`,
    [lang.DE]: `Um fortzufahren, müssen Sie den Nutzungsbedingungen zustimmen.`,
}

const buyButton = {
    [lang.RU]: `Купить`,
    [lang.EN]: `Buy`,
    [lang.DE]: `Kaufen`,
}

const money = {
    [lang.RU]: `Недостаточно средств. Пожалуйста, пополните баланс.`,
    [lang.EN]: `Not enough funds. Please, top up your balance.`,
    [lang.DE]: `Nicht genug Geld. Bitte laden Sie Ihr Guthaben auf.`,
}

const error = {
    [lang.RU]: `Неизвестная ошибка. Попробуйте позже.`,
    [lang.EN]: `Unknown error. Try again later.`,
    [lang.DE]: `Unbekannter Fehler. Versuchen Sie es später erneut.`,
}

const country = {
    [lang.RU]: `Страна`,
    [lang.EN]: `Country`,
    [lang.DE]: `Land`,
}

const protoc = {
    [lang.RU]: `Протокол`,
    [lang.EN]: `Protocol`,
    [lang.DE]: `Protokoll`,
}

const protoc_about = {
    [lang.RU]: `О протоколе`,
    [lang.EN]: `About protocol`,
    [lang.DE]: `Über das Protokoll`,
}

const band = {
    [lang.RU]: `Скорость`,
    [lang.EN]: `Speed`,
    [lang.DE]: `Geschwindigkeit`,
}

const price = {
    [lang.RU]: `Цена`,
    [lang.EN]: `Price`,
    [lang.DE]: `Preis`,
}

const price_month = {
    [lang.RU]: `Месяц`,
    [lang.EN]: `Month`,
    [lang.DE]: `Monat`,
}

const price_total = {
    [lang.RU]: `Всего`,
    [lang.EN]: `Total`,
    [lang.DE]: `In Summe`,
}

const speed = {
    [lang.RU]: `Скорость`,
    [lang.EN]: `Speed`,
    [lang.DE]: `Geschwindigkeit`,
}

const period = {
    [lang.RU]: `Период`,
    [lang.EN]: `Period`,
    [lang.DE]: `Zeitraum`,
}

const select_your_settings = {
    [lang.RU]: `Выберите опции, подходящие именно вам`,
    [lang.EN]: `Select options right for you`,
    [lang.DE]: `Wählen Sie die Optionen, die zu Ihnen passen`,
}

const you_can_shoose_title = {
    [lang.RU]: `Настройте свой тариф`,
    [lang.EN]: `Choose your tariff options`,
    [lang.DE]: `Wählen Sie Ihre Tarifoptionen`,
}
const you_can_shoose_text = {
    [lang.RU]: `Повысьте свою онлайн-безопасность без ущерба для своего бюджета. Наши доступные тарифные планы удовлетворят любые потребности. Никаких скрытых затрат, никаких компромиссов в отношении качества — просто непревзойденная VPN-защита по цене, которая вам понравится.`,
    [lang.EN]: `Elevate your online security without compromising your budget. Our affordable pricing plans suit any needs. No hidden costs, no compromise on quality - just unmatched VPN protection at a price you'll love.`,
    [lang.DE]: `Erhöhen Sie Ihre Online-Sicherheit, ohne Ihr Budget zu gefährden. Unsere erschwinglichen Preispläne passen zu jedem Bedarf. Keine versteckten Kosten, keine Kompromisse bei der Qualität – einfach unübertroffener VPN-Schutz zu einem Preis, den Sie lieben werden.`,
}

const conn_limit = {
    [lang.RU]: `Мы рады предложить вам премиум VPN сервис, не прожигая при этом дыру в вашем кармане.`,
    [lang.EN]: `We are thrilled to bring you premium VPN service without burning a hole in your pocket.`,
    [lang.DE]: `Wir freuen uns, Ihnen einen Premium-VPN-Dienst anbieten zu können, ohne ein Loch in Ihre Tasche zu reißen.`,
}

const prices_part_title = {
    [lang.RU]: `Честные цены`,
    [lang.EN]: `Honest Prices`,
    [lang.DE]: `Ehrliche Preise`,
}

const prices_part_1 = {
    [lang.RU]: `Никаких скрытых платежей`,
    [lang.EN]: `No hidden costs`,
    [lang.DE]: `Keine versteckten Kosten`,
}

const prices_part_2 = {
    [lang.RU]: `Различные способы оплаты`,
    [lang.EN]: `Different payment options`,
    [lang.DE]: `Verschiedene Zahlungsmöglichkeiten`,
}

const prices_part_3 = {
    [lang.RU]: `Автопродление`,
    [lang.EN]: `Auto renewal`,
    [lang.DE]: `automatische Verlängerung`,
}

const prices_part_4 = {
    [lang.RU]: `Гаранития возврата средств`,
    [lang.EN]: `Moneyback guarantee`,
    [lang.DE]: `Geld-Zurück-Garantie`,
}

const info_about_proto = {
    [lang.RU]: `О протоколе`,
    [lang.EN]: `About protocol`,
    [lang.DE]: `Über das Protokoll`,
}
const info_payment = {
    [lang.RU]: `Оплата`,
    [lang.EN]: `Payment`,
    [lang.DE]: `Zahlung`,
}
const info_moneyback = {
    [lang.RU]: `Возврат средств`,
    [lang.EN]: `Moneyback`,
    [lang.DE]: `Erstattung`,
}

const per_month = {
    [lang.RU]: `Месяц`,
    [lang.EN]: `Month`,
    [lang.DE]: `Monat`,
}

const preparing_cert = {
    [lang.RU]: `Конфигурационный файл будет готов через пару минут`,
    [lang.EN]: `Config file will be ready in a couple of minutes`,
    [lang.DE]: `Die Konfigurationsdatei ist in wenigen Minuten fertig`,
}

const something_wrong = {
    [lang.RU]: `Что-то пошло не так. Код ошибки = `,
    [lang.EN]: `Something went wrong. Error code = `,
    [lang.DE]: `Etwas ist schief gelaufen. Fehlercode =`,
}

const stopped_cert = {
    [lang.RU]: `ВПН остановлен. Пожалуйста, свяжитесь с поддержкаой.`,
    [lang.EN]: `VPN was stopped. Please, contact support.`,
    [lang.DE]: `VPN wurde gestoppt. Bitte wenden Sie sich an den Support.`,
}

const no_server = {
    [lang.RU]: `К сожалению, сейчас нет доступных серверов для выбранной локации, попробуйте снова позже`,
    [lang.EN]: `Unfortunately, there are no servers available for the selected location right now, try again later`,
    [lang.DE]: `Leider sind derzeit keine Server für den ausgewählten Standort verfügbar. Versuchen Sie es später noch einmal`,
}

const cert_name_req = {
    [lang.RU]: `Название не может быть больше 20 символов.`,
    [lang.EN]: `The name cannot be more than 20 characters long.`,
    [lang.DE]: `Der Name darf nicht länger als 20 Zeichen sein.`,
}

export const ConstLang = {
    protocols,
    countries,
    countryNameByCode: (code) => countries.find(c => c.code === code).name,
    protocolByName: (name) => protocols.find(p => p.name === name),
    contryByCode: (code) => {
        let country = "";
        countries.forEach(e => {
            if (e.code === code) country = e;
        });
        return country;
    },
    moneybackSpeach,
    moreInfoMoneyback,
    paymentSpeachUnlogined,
    paymentSpeachLogined,
    moreInfoPayment,
    agreeWithTerms,
    mustAgree,
    buyButton,
    money,
    error,
    country,
    protoc,
    protoc_about,
    band,
    price,
    price_month,
    price_total,
    speed,
    period,
    select_your_settings,
    you_can_shoose_title,
    you_can_shoose_text,
    conn_limit,
    prices_part_title,
    prices_part_1,
    prices_part_2,
    prices_part_3,
    prices_part_4,
    info_about_proto,
    info_payment,
    info_moneyback,
    preparing_cert,
    per_month,
    something_wrong,
    stopped_cert,
    no_server,
    cert_name_req
}
