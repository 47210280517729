import React from "react";
import { useNavigate, NavLink } from "react-router-dom";

import { Requester } from "js/logic/Requester";
import { Tariffer } from "js/logic/Tariffer";
import { Cookier } from "js/logic/Cookier";
import { ConstLang } from "js/languages/common/constructor";

export default function Constructor({ state }) {

    const navigate = useNavigate();

    const lang = state.language;
    const theme = state.themeLight;

    const country = state.constructor.data.country;
    const setCountry = (c) => state.constructor.setCountry(c);

    const protocol = state.constructor.data.protocol;
    const setProtocol = (p) => state.constructor.setProtocol(p);

    const bwSliderPosition = state.constructor.data.bwSliderPosition;
    const setBwSliderPosition = (p) => state.constructor.setBwSliderPosition(p);

    const periodSliderPosition = state.constructor.data.periodSliderPosition;
    const setPeriodSliderPosition = (p) => state.constructor.setPeriodSliderPosition(p);

    const priceMonth = state.constructor.data.priceToBuyMonth;
    const priceTotal = state.constructor.data.priceToBuyTotal;
    const setPrice = (m, t) => state.constructor.setPriceToBuy(m, t);
    const [agreeToPay, setAgreeToPay] = React.useState(false);

    const updateTime = React.useRef(null);

    React.useEffect(() => {
        calc();
    }, [protocol, lang, bwSliderPosition, periodSliderPosition]);


    async function calc() {
        let ans = await Requester.calculateCertificatePrice(Tariffer.sliderPositionToMbsValue(bwSliderPosition),
            Tariffer.sliderPositionToPeriodValue(periodSliderPosition), protocol.name);
        if (ans.time > updateTime.current || updateTime.current === null) {
            updateTime.current = ans.time;
            setPrice(ans.month, ans.total);
        }
    }

    function top() {
        window.scrollTo({ top: 0 })
    }


    return (
        <>
            <div className="common_title">Set up your VPN:</div>

            <div className="constructor_area">
                <div className="constructor_manipulators_area">
                    <div className="dropdowns">
                        <div className="constructor_dropdown_area">
                            <div className="constructor_dropdown_title">{ConstLang.country[lang]}:</div>
                            <select
                                className={theme ? "selector constructor_selector_p50_z0" : "selector selector_dark constructor_selector_p50_z0"}
                                defaultValue={country.code}
                                onChange={(e) => setCountry(ConstLang.contryByCode(e.target.value))}>
                                {
                                    ConstLang.countries.map(e =>
                                        <option key={e.code}
                                            value={e.code}
                                        >{e.name[lang]}</option>
                                    )
                                }
                            </select>
                        </div>
                        <div className="constructor_dropdown_area">
                            <div className="constructor_dropdown_title">{ConstLang.protoc[lang]}:</div>
                            <select
                                className={theme ? "selector constructor_selector_p50_z0" : "selector selector_dark constructor_selector_p50_z0"}
                                defaultValue={protocol.name}
                                onChange={(e) =>
                                    setProtocol(ConstLang.protocolByName(e.target.value))
                                }>
                                {ConstLang.protocols.map(e => <option key={e.name} value={e.name}>{e.name}</option>)}
                            </select>
                        </div>
                    </div>
                    <div className="constructor_slider_area">
                        <div
                            className="constructor_slider_title">{ConstLang.speed[lang]}: {Tariffer.sliderPositionToMbsValue(bwSliderPosition)} Mb/s
                        </div>
                        <input className="common_slider"
                            type="range"
                            min={Tariffer.minPositionMbs}
                            max={Tariffer.maxPositionMbs}
                            step="1"
                            value={bwSliderPosition}
                            onChange={(e) => setBwSliderPosition(e.target.value)} />
                    </div>
                    <div className="constructor_slider_area">
                        <div
                            className="constructor_slider_title">{ConstLang.period[lang]}: {Tariffer.sliderPositionToPeriodValue(periodSliderPosition)} months
                        </div>
                        <input className="common_slider"
                            type="range"
                            min={Tariffer.minPositionPeriod}
                            max={Tariffer.maxPositionPeriod}
                            step="1"
                            value={periodSliderPosition}
                            onChange={(e) => setPeriodSliderPosition(e.target.value)} />
                    </div>
                </div>


                <div className="constructor_total_card_area">
                    <div className="constructor_total_card">
                        <div className="constructor_colored_side cabinet_menu_element_first">
                            <div className="constructor_total_card_param_title">{ConstLang.country[lang]}</div>
                            <div className="constructor_total_card_param_value">{country.name[lang]}</div>
                        </div>
                        <div className={theme ? "" : "constructor_colored_side_dark"}>
                            <div className="constructor_total_card_param_title">{ConstLang.protoc[lang]}</div>
                            <div className="constructor_total_card_param_value">{protocol.name}
                                <NavLink to={`/faq/${protocol.name.toLowerCase()}`} onClick={() => top()}
                                    className={state.themeLight ?
                                        "constructor_proto_link_to_faq" : "constructor_proto_link_to_faq constructor_proto_link_to_faq_dark"}
                                >{ConstLang.protoc_about[lang]}</NavLink></div>
                        </div>
                        <div className="constructor_colored_side">
                            <div className="constructor_total_card_param_title">{ConstLang.band[lang]}</div>
                            <div
                                className="constructor_total_card_param_value">{Tariffer.sliderPositionToMbsValue(bwSliderPosition)} mb/s
                            </div>
                        </div>
                        <div
                            className={theme ? "cabinet_menu_element_last" : "constructor_colored_side_dark cabinet_menu_element_last"}>
                            <div className="constructor_total_card_param_title">{ConstLang.price[lang]}</div>
                            <div
                                className="constructor_total_card_param_value">{ConstLang.price_month[lang]}: {priceMonth}$ <br />
                                {ConstLang.price_total[lang]}: {priceTotal}$
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="constructor_total_card_agree">
                <div className="agree_checkbox">
                    <label className="switchAgree" htmlFor="checkbox_constructor">
                        <input onChange={() => setAgreeToPay(!agreeToPay)} type="checkbox" id="checkbox_constructor"
                            checked={agreeToPay} />
                        <div className="sliderAgree round"></div>
                    </label>
                </div>
                <NavLink to="/faq/terms"
                    className={state.themeLight ? "text" : "text text_dark"}>{ConstLang.agreeWithTerms[lang]}</NavLink>
            </div>

            <div className="constructor_total_card_buy_button">
                <div className="common_panorama_button common_fancy_button constructor_button_buy"
                    onClick={async () => {
                        if (!Cookier.checkToken()) {
                            top();
                            navigate("/enter#enter");
                            return;
                        }
                        if (!agreeToPay) {
                            alert(ConstLang.mustAgree[lang]);
                            return;
                        }
                        let ans = await Requester.buy(
                            country.code,
                            protocol.name.toUpperCase(),
                            Tariffer.sliderPositionToMbsValue(bwSliderPosition),
                            Tariffer.sliderPositionToPeriodValue(periodSliderPosition)
                        );
                        if (ans === Requester.noserver) {
                            alert(ConstLang.no_server[lang]);
                            return;
                        }
                        if (ans.ok) {
                            state.certs.setNeedUpdatePreparingCerts(true);
                            navigate("/cabinet/certificates");
                        } else {
                            if (ans.text === "NotEnough money") {
                                alert(ConstLang.money[lang]);
                            } else {
                                alert(ConstLang.error[lang]);
                            }
                        }
                    }}>{ConstLang.buyButton[lang]}</div>
            </div>

            <div className="common_paragraph">
                <span className="common_paragraph_title">{ConstLang.info_about_proto[lang]}:</span>
                <span className="constructor_paragraph_text">{protocol.description[lang]}</span>
            </div>
            <div className="common_paragraph">
                <span className="common_paragraph_title">{ConstLang.info_payment[lang]}:</span>
                {Cookier.checkToken()
                    ? <span className="constructor_paragraph_text">{ConstLang.paymentSpeachLogined[lang]}
                        <NavLink
                            className={state.themeLight ? "constructor_about" : "constructor_about constructor_about_dark"}
                            to="/faq/monero">{ConstLang.moreInfoPayment[lang]}</NavLink></span>
                    : <span className="constructor_paragraph_text">{ConstLang.paymentSpeachUnlogined[lang]}
                        <NavLink
                            className={state.themeLight ? "constructor_about" : "constructor_about constructor_about_dark"}
                            to="/faq/monero">{ConstLang.moreInfoPayment[lang]}</NavLink></span>
                }
            </div>
            <div className="common_paragraph">
                <span className="common_paragraph_title">{ConstLang.info_moneyback[lang]}:</span>
                <span className="constructor_paragraph_text">{ConstLang.moneybackSpeach[lang]}
                    <NavLink
                        className={state.themeLight ? "constructor_about" : "constructor_about constructor_about_dark"}
                        to="/faq/moneyback">{ConstLang.moreInfoMoneyback[lang]}</NavLink> </span>
            </div>
        </>
    );
}