import { Route, Routes, NavLink, useLocation } from "react-router-dom";

import PrivacyPolicy from "js/pages/faq/PrivacyPolicy";
import Moneyback from "js/pages/faq/Moneyback";
import PaymentInfo from "js/pages/faq/PaymentInfo";
import Monero from "js/pages/faq/Monero";
import CryptoCloud from "js/pages/faq/CryptoCloud";
import Contacts from "js/pages/faq/Contacts";
import Terms from "js/pages/faq/Terms";
import Rules from "js/pages/faq/Rules";
import MainProtocols from "js/pages/faq/MainProtocols";

import BackToFaq from "js/pages/common/BackToFaq";

import { FaqProtocols } from "js/languages/faq/protocols";

import { FaqMenuLang } from "js/languages/faq/faq_menu";


import config_download from "resources/images/faq/config_download.webp";
import openvpn_logo from "resources/images/faq/openvpn/openvpn_logo.webp";
import openvpn_import from "resources/images/faq/openvpn/openvpn_import.webp";
import wireguard_logo from "resources/images/faq/wireguard/wireguard_logo.webp";
import wireguard_import from "resources/images/faq/wireguard/wireguard_import.webp";
import shadowsocks_logo from "resources/images/faq/shadowsocks/shadowsocks_logo.webp";
import shadowsocks_import from "resources/images/faq/shadowsocks/shadowsocks_import.webp";
import xray_logo from "resources/images/faq/xray/xray_logo.webp";
import xray_import from "resources/images/faq/xray/xray_import.webp";
import React from "react";

export default function FaqPage({ state, setState }) {

    const location = useLocation();

    function top() {
        window.scrollTo({ top: 0 })
    }

    let screen = state.faqScreen;
    if (["/faq/protocols", "/faq/protocols/"].includes(location.pathname)) {
        screen = "protocols";
    }
    if (["/faq/openvpn", "/faq/openvpn/"].includes(location.pathname)) {
        screen = "openvpn";
    }
    if (["/faq/wireguard", "/faq/wireguard/"].includes(location.pathname)) {
        screen = "wireguard";
    }
    if (["/faq/shadowsocks", "/faq/shadowsocks/"].includes(location.pathname)) {
        screen = "shadowsocks";
    }
    if (["/faq/vless", "/faq/vless/"].includes(location.pathname)) {
        screen = "vless";
    }
    if (["/faq/vmess", "/faq/vmess/"].includes(location.pathname)) {
        screen = "vmess";
    }
    if (["/faq/trojan", "/faq/trojan/"].includes(location.pathname)) {
        screen = "trojan";
    }

    if (["/faq/payments", "/faq/payments/"].includes(location.pathname)) {
        screen = "payments";
    }
    if (["/faq/cryptocloud", "/faq/cryptocloud/"].includes(location.pathname)) {
        screen = "cryptocloud";
    }
    if (["/faq/monero", "/faq/monero/"].includes(location.pathname)) {
        screen = "monero";
    }
    if (["/faq/moneyback", "/faq/moneyback/"].includes(location.pathname)) {
        screen = "moneyback";
    }

    if (["/faq/rules", "/faq/rules/"].includes(location.pathname)) {
        screen = "rules";
    }
    if (["/faq/terms", "/faq/terms/"].includes(location.pathname)) {
        screen = "terms";
    }
    if (["/faq/privacy_policy", "/faq/privacy_policy/"].includes(location.pathname)) {
        screen = "privacy_policy";
    }
    if (["/faq/contacts", "/faq/contacts/"].includes(location.pathname)) {
        screen = "contacts";
    }

    return (
        <>
            <div className="common_footer_header_gap"></div>
            <div className="common_filler">
                <div className="common_mobile_full_width_container">
                    <div className="faq_cont">
                        <FaqMenu state={state} mobile={false} />
                        <div
                            className={state.themeLight ? "faq_content faq_font_size" : "faq_content faq_content_dark faq_font_size"}>
                            <Routes>
                                <Route path="/" element={<FaqMenu mobile={true} state={state} setState={setState} />} />
                                {/* <Route path="" element={<MainProtocols state={state} />} /> */}
                                <Route path="protocols" element={<MainProtocols state={state} setState={setState} />} />
                                <Route path="openvpn" element={<OpenVPNProtocol state={state} setState={setState} />} />
                                <Route path="wireguard"
                                    element={<WireGuardProtocol state={state} setState={setState} />} />
                                <Route path="shadowsocks"
                                    element={<ShadowsocksProtocol state={state} setState={setState} />} />
                                <Route path="vless" element={<VLESSProtocol state={state} setState={setState} />} />
                                <Route path="vmess" element={<VMessProtocol state={state} setState={setState} />} />
                                <Route path="trojan" element={<TrojanProtocol state={state} setState={setState} />} />
                                <Route path="payments" element={<PaymentInfo state={state} setState={setState} />} />
                                <Route path="cryptocloud" element={<CryptoCloud state={state} setState={setState} />} />
                                <Route path="monero" element={<Monero state={state} setState={setState} />} />
                                <Route path="moneyback" element={<Moneyback state={state} setState={setState} />} />
                                <Route path="rules" element={<Rules state={state} setState={setState} />} />
                                <Route path="terms" element={<Terms state={state} setState={setState} />} />
                                <Route path="privacy_policy"
                                    element={<PrivacyPolicy state={state} setState={setState} />} />
                                <Route path="contacts"
                                    element={<Contacts state={state} setState={setState} display_back={true} />} />
                            </Routes>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

    function FaqMenu({ state, mobile }) {

        const lang = state.language;
        return (
            <>
                <div
                    className={mobile ? state.themeLight ? "faq_menu_canvas" : "faq_menu_canvas faq_menu_canvas_dark" : state.themeLight ? "faq_menu_hidable" : "faq_menu_hidable faq_menu_canvas_dark"}>
                    <NavLink to="/faq/protocols"
                        onClick={() => top()}
                        className={screen === "protocols" ? "faq_menu_header cabinet_menu_element_first faq_active" : "faq_menu_header cabinet_menu_element_first"}>{FaqMenuLang.menu_protocols[lang]}
                    </NavLink>
                    <NavLink to="/faq/openvpn"
                        onClick={() => top()}
                        className={screen === "openvpn" ? "faq_menu_item faq_active" : "faq_menu_item"}>{FaqMenuLang.menu_protocols_openvpn[lang]}</NavLink>
                    <NavLink to="/faq/wireguard"
                        onClick={() => top()}
                        className={screen === "wireguard" ? "faq_menu_item faq_active" : "faq_menu_item"}>{FaqMenuLang.menu_protocols_wireguard[lang]}</NavLink>
                    <NavLink to="/faq/shadowsocks"
                        onClick={() => top()}
                        className={screen === "shadowsocks" ? "faq_menu_item faq_active" : "faq_menu_item"}>{FaqMenuLang.menu_protocols_shadowsocks[lang]}</NavLink>
                    <NavLink to="/faq/vless"
                        onClick={() => top()}
                        className={screen === "vless" ? "faq_menu_item faq_active" : "faq_menu_item"}>{FaqMenuLang.menu_protocols_vless[lang]}</NavLink>
                    <NavLink to="/faq/vmess"
                        onClick={() => top()}
                        className={screen === "vmess" ? "faq_menu_item faq_active" : "faq_menu_item"}>{FaqMenuLang.menu_protocols_vmess[lang]}</NavLink>
                    <NavLink to="/faq/trojan"
                        onClick={() => top()}
                        className={screen === "trojan" ? "faq_menu_item faq_active" : "faq_menu_item"}>{FaqMenuLang.menu_protocols_trojan[lang]}</NavLink>
                    <NavLink to="/faq/payments"
                        onClick={() => top()}
                        className={screen === "payments" ? "faq_menu_header faq_active" : "faq_menu_header"}>{FaqMenuLang.menu_payment[lang]}</NavLink>
                    <NavLink to="/faq/cryptocloud"
                        onClick={() => top()}
                        className={screen === "cryptocloud" ? "faq_menu_item faq_active" : "faq_menu_item"}>{FaqMenuLang.menu_payment_cryptocloud[lang]}</NavLink>
                    <NavLink to="/faq/monero"
                        onClick={() => top()}
                        className={screen === "monero" ? "faq_menu_item faq_active" : "faq_menu_item"}>{FaqMenuLang.menu_payment_monero[lang]}</NavLink>
                    <NavLink to="/faq/moneyback"
                        onClick={() => top()}
                        className={screen === "moneyback" ? "faq_menu_item faq_active" : "faq_menu_item"}>{FaqMenuLang.menu_payment_moneyback[lang]}</NavLink>
                    <NavLink to="/faq/rules"
                        onClick={() => top()}
                        className={screen === "rules" ? "faq_menu_header faq_active" : "faq_menu_header"}>{FaqMenuLang.menu_rules[lang]}</NavLink>
                    <NavLink to="/faq/terms"
                        onClick={() => top()}
                        className={screen === "terms" ? "faq_menu_item faq_active" : "faq_menu_item"}>{FaqMenuLang.menu_terms_of_service[lang]}</NavLink>
                    <NavLink to="/faq/privacy_policy"
                        onClick={() => top()}
                        className={screen === "privacy_policy" ? "faq_menu_item faq_active" : "faq_menu_item"}>{FaqMenuLang.menu_privacy_policy[lang]}</NavLink>
                    <NavLink to="/faq/contacts"
                        onClick={() => top()}
                        className={screen === "contacts" ? "faq_menu_header cabinet_menu_element_last faq_active" : "faq_menu_header cabinet_menu_element_last"}>{FaqMenuLang.menu_contacts[lang]}</NavLink>
                </div>
                {/* <div className={!mobile && screen != "" ? "invisible" : "cab"}>
                    <MainProtocols state={state} setState={setState} />
                </div> */}
            </>
        );
    }
}


function OpenVPNProtocol({ state, setState }) {

    const lang = state.language;
    return (
        <>
            <div className="faq_body_internal">
                <BackToFaq state={state} setState={setState} />
                <div className="common_protocol_title">OpenVPN</div>
                <div className={state.themeLight ? "faq_step" : "faq_step faq_step_dark"}>
                    <div className="common_grid_2">
                        <img alt="#" className="faq_image" src={openvpn_logo} />
                        <div className="common_area">
                            <div className="faq_step_title">1. {FaqProtocols.faq_install_app[lang]}</div>
                            <div className="common_text">
                                <div className="who_we_are_3">
                                    <div className="common_phrase">
                                        <ul>
                                            <li><h3>Android:</h3></li>
                                            <li className="faq_list_download">⬇ <a href="https://play.google.com/store/apps/details?id=net.openvpn.openvpn">OpenVPN Connect</a></li>
                                            <li className="faq_list_download">⬇ <a href="https://play.google.com/store/apps/details?id=de.blinkt.openvpn">OpenVPN for Android</a></li>
                                            <li className="faq_list_download">⬇ <a href="https://ics-openvpn.blinkt.de/">OpenVPN for Android (APK)</a></li>
                                            <li><h3>iOS:</h3></li>
                                            <li className="faq_list_download">⬇ <a href="https://apps.apple.com/app/openvpn-connect-openvpn-app/id590379981">OpenVPN Connect</a></li>
                                            <li><h3>Windows:</h3></li>
                                            <li className="faq_list_download">⬇ <a href="https://openvpn.net/downloads/openvpn-connect-v3-windows.msi">OpenVPN Connect</a></li>
                                            <li className="faq_list_download">⬇ <a href="https://swupdate.openvpn.org/community/releases/OpenVPN-2.6.8-I001-amd64.msi">OpenVPN GUI</a></li>
                                            <li><h3>MacOS:</h3></li>
                                            <li className="faq_list_download">⬇ <a href="https://openvpn.net/downloads/openvpn-connect-v3-macos.dmg">OpenVPN Connect</a></li>
                                            <li className="faq_list_download">⬇ <a href="https://tunnelblick.net/iprelease/Tunnelblick_3.8.8g_build_5779.3.dmg">Tunnelblick</a></li>
                                            <li><h3>Linux:</h3></li>
                                            <li className="faq_list_download">⬇ <a href="https://community.openvpn.net/openvpn/wiki/OpenVPN3Linux">OpenVPN 3</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={state.themeLight ? "faq_step" : "faq_step faq_step_dark"}>
                    <div className="common_grid_2">
                        <div className="faq_step_body">
                            <div className="faq_step_title">2. {FaqProtocols.faq_download_config[lang]}</div>
                            <div className="common_text">
                                <div className="who_we_are_3">
                                    <div className="common_phrase">
                                        <ul>
                                            <li>🔗 <a href="https://www.ponvpn.com/cabinet/certificates">{FaqProtocols.faq_website[lang]}</a></li>
                                            <li>🔗 <a href="https://t.me/ponvpnbot">Telegram bot</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <img alt="#" className="faq_image" src={config_download} />
                    </div>
                </div>
                <div className={state.themeLight ? "faq_step" : "faq_step faq_step_dark"}>
                    <div className="common_grid_2">
                        <img alt="#" className="faq_image" src={openvpn_import} />
                        <div className="faq_step_body">
                            <div className="faq_step_title">{FaqProtocols.faq_import_and_connect[lang]}
                            </div>
                            <div className="faq_step_title">
                                <div className="who_we_are_3">
                                    <div className="common_phrase">
                                        <ul>
                                            <li>✔ {FaqProtocols.faq_choose_file[lang]}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={state.themeLight ? "faq_step" : "faq_step faq_step_dark"}>
                    <div className="faq_note">{FaqProtocols.faq_contact_support[lang]} - <a
                        href="mailto:support@ponvpn.com">support@ponvpn.com</a></div>
                </div>

                <div className="faq_protocol_info">{FaqProtocols.faq_openvpn_long[lang]}</div>

                <div className="faq_title">{FaqProtocols.faq_openvpn_1_title[lang]}</div>
                <div className="faq_speech">{FaqProtocols.faq_openvpn_1_text[lang]}</div>

                <div className="faq_title">{FaqProtocols.faq_openvpn_2_title[lang]}</div>
                <div className="faq_speech">{FaqProtocols.faq_openvpn_2_text[lang]}</div>

                <div className="faq_title">{FaqProtocols.faq_openvpn_3_title[lang]}</div>
                <div className="faq_speech">{FaqProtocols.faq_openvpn_3_text[lang]}</div>

                <div className="faq_title">{FaqProtocols.faq_openvpn_4_title[lang]}</div>
                <div className="faq_speech">{FaqProtocols.faq_openvpn_4_text[lang]}</div>

                <div className="faq_title">{FaqProtocols.faq_openvpn_5_title[lang]}</div>
                <div className="faq_speech">{FaqProtocols.faq_openvpn_5_text[lang]}</div>

                <div className="faq_title">{FaqProtocols.faq_openvpn_6_title[lang]}</div>
                <div className="faq_speech">{FaqProtocols.faq_openvpn_6_text[lang]}</div>

                {/*<div className="os_code"># df -h | grep -v docker</div>*/}
                {/*<div className="faq_image_area"><img src={osImage}/></div>*/}
            </div>
        </>
    );
}

function WireGuardProtocol({ state, setState }) {

    const lang = state.language;
    return (
        <>
            <div className="faq_body_internal">
                <BackToFaq state={state} setState={setState}/>
                <div className="common_protocol_title">WireGuard</div>
                <div className={state.themeLight ? "faq_step" : "faq_step faq_step_dark"}>
                    <div className="common_grid_2">
                        <img alt="#" className="faq_image" src={wireguard_logo}/>
                        <div className="common_area">
                            <div className="faq_step_title">1. {FaqProtocols.faq_install_app[lang]}</div>
                            <div className="common_text">
                                <div className="who_we_are_3">
                                    <div className="common_phrase">
                                        <ul>
                                            <li><h3>Android:</h3></li>
                                            <li className="faq_list_download">⬇ <a href="https://play.google.com/store/apps/details?id=com.wireguard.android">WireGuard</a></li>
                                            <li><h3>iOS:</h3></li>
                                            <li className="faq_list_download">⬇ <a href="https://itunes.apple.com/us/app/wireguard/id1441195209?ls=1&mt=8">WireGuard</a></li>
                                            <li><h3>Windows:</h3></li>
                                            <li className="faq_list_download">⬇ <a href="https://download.wireguard.com/windows-client/wireguard-installer.exe">WireGuard</a></li>
                                            <li><h3>MacOS:</h3></li>
                                            <li className="faq_list_download">⬇ <a href="https://itunes.apple.com/us/app/wireguard/id1451685025?ls=1&mt=12">WireGuard</a></li>
                                            <li><h3>Linux:</h3></li>
                                            <li className="faq_list_download">⬇ <a href="https://www.wireguard.com/install/">WireGuard</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={state.themeLight ? "faq_step" : "faq_step faq_step_dark"}>
                    <div className="common_grid_2">
                        <div className="faq_step_body">
                            <div className="faq_step_title">2. {FaqProtocols.faq_download_config[lang]}</div>
                            <div className="common_text">
                                <div className="who_we_are_3">
                                    <div className="common_phrase">
                                        <ul>
                                            <li>🔗 <a
                                                href="https://www.ponvpn.com/cabinet/certificates">{FaqProtocols.faq_website[lang]}</a>
                                            </li>
                                            <li>🔗 <a href="https://t.me/ponvpnbot">Telegram bot</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <img alt="#" className="faq_image" src={config_download}/>
                    </div>
                </div>
                <div className={state.themeLight ? "faq_step" : "faq_step faq_step_dark"}>
                    <div className="common_grid_2">
                        <img alt="#" className="faq_image" src={wireguard_import}/>
                        <div className="faq_step_body">
                            <div className="faq_step_title">{FaqProtocols.faq_import_and_connect[lang]}
                            </div>
                            <div className="faq_step_title">
                                <div className="who_we_are_3">
                                    <div className="common_phrase">
                                        <ul>
                                            <li>✔ {FaqProtocols.faq_choose_file[lang]}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={state.themeLight ? "faq_step" : "faq_step faq_step_dark"}>
                    <div className="faq_note">{FaqProtocols.faq_contact_support[lang]} - <a
                        href="mailto:support@ponvpn.com">support@ponvpn.com</a></div>
                </div>


                <div className="faq_protocol_info">{FaqProtocols.faq_wireguard_long[lang]}</div>

                <div className="faq_title">{FaqProtocols.faq_wireguard_1_title[lang]}</div>
                <div className="faq_speech">{FaqProtocols.faq_wireguard_1_text[lang]}</div>

                <div className="faq_title">{FaqProtocols.faq_wireguard_2_title[lang]}</div>
                <div className="faq_speech">{FaqProtocols.faq_wireguard_2_text[lang]}</div>

                <div className="faq_title">{FaqProtocols.faq_wireguard_3_title[lang]}</div>
                <div className="faq_speech">{FaqProtocols.faq_wireguard_3_text[lang]}</div>

                <div className="faq_title">{FaqProtocols.faq_wireguard_4_title[lang]}</div>
                <div className="faq_speech">{FaqProtocols.faq_wireguard_4_text[lang]}</div>

                <div className="faq_title">{FaqProtocols.faq_wireguard_5_title[lang]}</div>
                <div className="faq_speech">{FaqProtocols.faq_wireguard_5_text[lang]}</div>

                <div className="faq_title">{FaqProtocols.faq_wireguard_6_title[lang]}</div>
                <div className="faq_speech">{FaqProtocols.faq_wireguard_6_text[lang]}</div>

                <div className="faq_title">{FaqProtocols.faq_wireguard_7_title[lang]}</div>
                <div className="faq_speech">{FaqProtocols.faq_wireguard_7_text[lang]}</div>

                <div className="faq_title">{FaqProtocols.faq_wireguard_8_title[lang]}</div>
                <div className="faq_speech">{FaqProtocols.faq_wireguard_8_text[lang]}</div>


                {/*<div className="os_code"># df -h | grep -v docker</div>*/}
                {/*<div className="faq_image_area"><img src={osImage}/></div>*/}
            </div>
        </>
    );
}

function ShadowsocksProtocol({state, setState}) {

    const lang = state.language;
    return (
        <>
            <div className="faq_body_internal">
                <BackToFaq state={state} setState={setState}/>
                <div className="common_protocol_title">Shadowsocks</div>
                <div className={state.themeLight ? "faq_step" : "faq_step faq_step_dark"}>
                    <div className="common_grid_2">
                        <img alt="#" className="faq_image" src={shadowsocks_logo}/>
                        <div className="common_area">
                            <div className="faq_step_title">1. {FaqProtocols.faq_install_app[lang]}</div>
                            <div className="common_text">
                                <div className="who_we_are_3">
                                    <div className="common_phrase">
                                        <ul>
                                            <li><h3>Android:</h3></li>
                                            <li className="faq_list_download">⬇ <a href="https://play.google.com/store/apps/details?id=com.github.shadowsocks">Shadowsocks</a></li>
                                            <li><h3>iOS:</h3></li>
                                            <li className="faq_list_download">⬇ <a href="https://apps.apple.com/us/app/v2box-v2ray-client/id6446814690">V2Box - V2ray Client</a></li>
                                            <li><h3>Windows:</h3></li>
                                            <li className="faq_list_download">⬇ <a href="https://github.com/shadowsocks/shadowsocks-windows/releases/download/4.4.1.0/Shadowsocks-4.4.1.0.zip">Shadowsocks</a></li>
                                            <li><h3>MacOS:</h3></li>
                                            <li className="faq_list_download">⬇ <a href="https://apps.apple.com/us/app/v2box-v2ray-client/id6446814690">V2Box - V2ray Client</a></li>
                                            <li><h3>Linux:</h3></li>
                                            <li className="faq_list_download">⬇ <a href="https://github.com/shadowsocks/shadowsocks-qt5/wiki/Installation">Shadowsocks-Qt5</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={state.themeLight ? "faq_step" : "faq_step faq_step_dark"}>
                    <div className="common_grid_2">
                        <div className="faq_step_body">
                            <div className="faq_step_title">2. {FaqProtocols.faq_download_config[lang]}</div>
                            <div className="common_text">
                                <div className="who_we_are_3">
                                    <div className="common_phrase">
                                        <ul>
                                            <li>🔗 <a
                                                href="https://www.ponvpn.com/cabinet/certificates">{FaqProtocols.faq_website[lang]}</a>
                                            </li>
                                            <li>🔗 <a href="https://t.me/ponvpnbot">Telegram bot</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <img alt="#" className="faq_image" src={config_download}/>
                    </div>
                </div>
                <div className={state.themeLight ? "faq_step" : "faq_step faq_step_dark"}>
                    <div className="common_grid_2">
                        <img alt="#" className="faq_image" src={shadowsocks_import}/>
                        <div className="faq_step_body">
                            <div className="faq_step_title">{FaqProtocols.faq_import_and_connect[lang]}
                            </div>
                            <div className="faq_step_title">
                                <div className="who_we_are_3">
                                    <div className="common_phrase">
                                        <ul>
                                            <li>✔ {FaqProtocols.faq_choose_file[lang]}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={state.themeLight ? "faq_step" : "faq_step faq_step_dark"}>
                    <div className="faq_note">{FaqProtocols.faq_contact_support[lang]} - <a
                        href="mailto:support@ponvpn.com">support@ponvpn.com</a></div>
                </div>
                <div className={state.themeLight ? "faq_step" : "faq_step faq_step_dark"}>
                    <div className="faq_note">{FaqProtocols.faq_note_shadowsocks_windows[lang]}
                    </div>
                </div>


                    <div className="faq_protocol_info">{FaqProtocols.faq_shadowsocks_long[lang]}</div>

                    <div className="faq_title">{FaqProtocols.faq_shadowsocks_1_title[lang]}</div>
                    <div className="faq_speech">{FaqProtocols.faq_shadowsocks_1_text[lang]}</div>

                    <div className="faq_title">{FaqProtocols.faq_shadowsocks_2_title[lang]}</div>
                    <div className="faq_speech">{FaqProtocols.faq_shadowsocks_2_text[lang]}</div>

                    <div className="faq_title">{FaqProtocols.faq_shadowsocks_3_title[lang]}</div>
                    <div className="faq_speech">{FaqProtocols.faq_shadowsocks_3_text[lang]}</div>

                    <div className="faq_title">{FaqProtocols.faq_shadowsocks_4_title[lang]}</div>
                    <div className="faq_speech">{FaqProtocols.faq_shadowsocks_4_text[lang]}</div>

                    <div className="faq_title">{FaqProtocols.faq_shadowsocks_5_title[lang]}</div>
                    <div className="faq_speech">{FaqProtocols.faq_shadowsocks_5_text[lang]}</div>

                    <div className="faq_title">{FaqProtocols.faq_shadowsocks_6_title[lang]}</div>
                    <div className="faq_speech">{FaqProtocols.faq_shadowsocks_6_text[lang]}</div>

                    {/*<div className="os_code"># df -h | grep -v docker</div>*/}
                    {/*<div className="faq_image_area"><img src={osImage}/></div>*/}
                </div>
            </>
            );
            }

            function VLESSProtocol({state, setState}) {

            const lang = state.language;
            return (
            <>
                <div className="faq_body_internal">
                    <BackToFaq state={state} setState={setState}/>
                    <div className="common_protocol_title">VLESS</div>
                    <div className={state.themeLight ? "faq_step" : "faq_step faq_step_dark"}>
                        <div className="common_grid_2">
                            <img alt="#" className="faq_image" src={xray_logo}/>
                            <div className="common_area">
                                <div className="faq_step_title">1. {FaqProtocols.faq_install_app[lang]}</div>
                                <div className="common_text">
                                    <div className="who_we_are_3">
                                        <div className="common_phrase">
                                            <ul>
                                                <li><h3>Android:</h3></li>
                                                <li className="faq_list_download">⬇ <a
                                                    href="https://play.google.com/store/apps/details?id=dev.hexasoftware.v2box">V2Box - V2ray Client</a>
                                                </li>
                                                <li><h3>iOS:</h3></li>
                                                <li className="faq_list_download">⬇ <a
                                                    href="https://itunes.apple.com/us/app/v2box-v2ray-client/id6446814690">V2Box
                                                    - V2ray Client</a></li>
                                                <li><h3>Windows:</h3></li>
                                                <li className="faq_list_download">⬇ <a
                                                    href="https://github.com/MatsuriDayo/nekoray/releases/download/3.26/nekoray-3.26-2023-12-09-windows64.zip">Nekoray</a>
                                                </li>
                                                <li><h3>MacOS:</h3></li>
                                                <li className="faq_list_download">⬇ <a
                                                    href="https://itunes.apple.com/us/app/v2box-v2ray-client/id6446814690">V2Box
                                                    - V2ray Client</a></li>
                                                <li><h3>Linux:</h3></li>
                                                <li className="faq_list_download">⬇ <a
                                                    href="https://github.com/MatsuriDayo/nekoray/releases/download/3.26/nekoray-3.26-2023-12-09-linux64.zip">Nekoray</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={state.themeLight ? "faq_step" : "faq_step faq_step_dark"}>
                        <div className="common_grid_2">
                            <div className="faq_step_body">
                                <div className="faq_step_title">2. {FaqProtocols.faq_download_config[lang]}</div>
                                <div className="common_text">
                                    <div className="who_we_are_3">
                                        <div className="common_phrase">
                                            <ul>
                                                <li>🔗 <a
                                                    href="https://www.ponvpn.com/cabinet/certificates">{FaqProtocols.faq_website[lang]}</a>
                                                </li>
                                                <li>🔗 <a href="https://t.me/ponvpnbot">Telegram bot</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <img alt="#" className="faq_image" src={config_download}/>
                        </div>
                    </div>
                    <div className={state.themeLight ? "faq_step" : "faq_step faq_step_dark"}>
                        <div className="common_grid_2">
                            <img alt="#" className="faq_image" src={xray_import}/>
                            <div className="faq_step_body">
                                <div className="faq_step_title">{FaqProtocols.faq_import_and_connect[lang]}
                                </div>
                                <div className="faq_step_title">
                                    <div className="who_we_are_3">
                                        <div className="common_phrase">
                                            <ul>
                                                <li>✔ {FaqProtocols.faq_choose_file[lang]}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={state.themeLight ? "faq_step" : "faq_step faq_step_dark"}>
                        <div className="faq_note">{FaqProtocols.faq_contact_support[lang]} - <a
                            href="mailto:support@ponvpn.com">support@ponvpn.com</a></div>
                    </div>
                    <div className={state.themeLight ? "faq_step" : "faq_step faq_step_dark"}>
                        <div className="faq_note">{FaqProtocols.faq_note_nekoray[lang]}
                        </div>
                    </div>


                    <div className="faq_protocol_info">{FaqProtocols.faq_vless_long[lang]}</div>

                    <div className="faq_title">{FaqProtocols.faq_vless_1_title[lang]}</div>
                    <div className="faq_speech">{FaqProtocols.faq_vless_1_text[lang]}</div>

                    <div className="faq_title">{FaqProtocols.faq_vless_2_title[lang]}</div>
                    <div className="faq_speech">{FaqProtocols.faq_vless_2_text[lang]}</div>

                    <div className="faq_title">{FaqProtocols.faq_vless_3_title[lang]}</div>
                    <div className="faq_speech">{FaqProtocols.faq_vless_3_text[lang]}</div>

                    <div className="faq_title">{FaqProtocols.faq_vless_4_title[lang]}</div>
                    <div className="faq_speech">{FaqProtocols.faq_vless_4_text[lang]}</div>

                    <div className="faq_title">{FaqProtocols.faq_vless_5_title[lang]}</div>
                    <div className="faq_speech">{FaqProtocols.faq_vless_5_text[lang]}</div>

                    <div className="faq_title">{FaqProtocols.faq_vless_6_title[lang]}</div>
                    <div className="faq_speech">{FaqProtocols.faq_vless_6_text[lang]}</div>

                    <div className="faq_title">{FaqProtocols.faq_vless_7_title[lang]}</div>
                    <div className="faq_speech">{FaqProtocols.faq_vless_7_text[lang]}</div>

                    {/*<div className="os_code"># df -h | grep -v docker</div>*/}
                    {/*<div className="faq_image_area"><img src={osImage}/></div>*/}
                </div>
            </>
            );
            }

function VMessProtocol({state, setState}) {

    const lang = state.language;
    return (
        <>
            <div className="faq_body_internal">
                <BackToFaq state={state} setState={setState}/>
                <div className="common_protocol_title">VMess</div>
                <div className={state.themeLight ? "faq_step" : "faq_step faq_step_dark"}>
                    <div className="common_grid_2">
                        <img alt="#" className="faq_image" src={xray_logo}/>
                        <div className="common_area">
                            <div className="faq_step_title">1. {FaqProtocols.faq_install_app[lang]}</div>
                            <div className="common_text">
                                <div className="who_we_are_3">
                                    <div className="common_phrase">
                                        <ul>
                                            <li><h3>Android:</h3></li>
                                            <li className="faq_list_download">⬇ <a
                                                href="https://play.google.com/store/apps/details?id=dev.hexasoftware.v2box">V2Box
                                                - V2ray Client</a>
                                            </li>
                                            <li><h3>iOS:</h3></li>
                                            <li className="faq_list_download">⬇ <a
                                                href="https://itunes.apple.com/us/app/v2box-v2ray-client/id6446814690">V2Box
                                                - V2ray Client</a></li>
                                            <li><h3>Windows:</h3></li>
                                            <li className="faq_list_download">⬇ <a
                                                href="https://github.com/MatsuriDayo/nekoray/releases/download/3.26/nekoray-3.26-2023-12-09-windows64.zip">Nekoray</a>
                                            </li>
                                            <li><h3>MacOS:</h3></li>
                                            <li className="faq_list_download">⬇ <a
                                                href="https://itunes.apple.com/us/app/v2box-v2ray-client/id6446814690">V2Box
                                                - V2ray Client</a></li>
                                            <li><h3>Linux:</h3></li>
                                            <li className="faq_list_download">⬇ <a
                                                href="https://github.com/MatsuriDayo/nekoray/releases/download/3.26/nekoray-3.26-2023-12-09-linux64.zip">Nekoray</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={state.themeLight ? "faq_step" : "faq_step faq_step_dark"}>
                    <div className="common_grid_2">
                        <div className="faq_step_body">
                            <div className="faq_step_title">2. {FaqProtocols.faq_download_config[lang]}</div>
                            <div className="common_text">
                                <div className="who_we_are_3">
                                    <div className="common_phrase">
                                        <ul>
                                            <li>🔗 <a
                                                href="https://www.ponvpn.com/cabinet/certificates">{FaqProtocols.faq_website[lang]}</a>
                                            </li>
                                            <li>🔗 <a href="https://t.me/ponvpnbot">Telegram bot</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <img alt="#" className="faq_image" src={config_download}/>
                    </div>
                </div>
                <div className={state.themeLight ? "faq_step" : "faq_step faq_step_dark"}>
                    <div className="common_grid_2">
                        <img alt="#" className="faq_image" src={xray_import}/>
                        <div className="faq_step_body">
                            <div className="faq_step_title">{FaqProtocols.faq_import_and_connect[lang]}
                            </div>
                            <div className="faq_step_title">
                                <div className="who_we_are_3">
                                    <div className="common_phrase">
                                        <ul>
                                            <li>✔ {FaqProtocols.faq_choose_file[lang]}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={state.themeLight ? "faq_step" : "faq_step faq_step_dark"}>
                    <div className="faq_note">{FaqProtocols.faq_contact_support[lang]} - <a
                        href="mailto:support@ponvpn.com">support@ponvpn.com</a></div>
                </div>
                <div className={state.themeLight ? "faq_step" : "faq_step faq_step_dark"}>
                    <div className="faq_note">{FaqProtocols.faq_note_nekoray[lang]}
                    </div>
                </div>


                <div className="faq_protocol_info">{FaqProtocols.faq_vmess_long[lang]}</div>

                <div className="faq_title">{FaqProtocols.faq_vmess_1_title[lang]}</div>
                <div className="faq_speech">{FaqProtocols.faq_vmess_1_text[lang]}</div>

                <div className="faq_title">{FaqProtocols.faq_vmess_2_title[lang]}</div>
                <div className="faq_speech">{FaqProtocols.faq_vmess_2_text[lang]}</div>


                <div className="faq_title">{FaqProtocols.faq_vmess_3_title[lang]}</div>
                <div className="faq_speech">{FaqProtocols.faq_vmess_3_text[lang]}</div>

                <div className="faq_title">{FaqProtocols.faq_vmess_4_title[lang]}</div>
                <div className="faq_speech">{FaqProtocols.faq_vmess_4_text[lang]}</div>

                <div className="faq_title">{FaqProtocols.faq_vmess_5_title[lang]}</div>
                <div className="faq_speech">{FaqProtocols.faq_vmess_5_text[lang]}</div>

                {/*<div className="os_code"># df -h | grep -v docker</div>*/}
                {/*<div className="faq_image_area"><img src={osImage}/></div>*/}
            </div>
        </>
    );
}

function TrojanProtocol({state, setState}) {

    const lang = state.language;
    return (
        <>
            <div className="faq_body_internal">
                <BackToFaq state={state} setState={setState}/>
                <div className="common_protocol_title">Trojan</div>
                <div className={state.themeLight ? "faq_step" : "faq_step faq_step_dark"}>
                    <div className="common_grid_2">
                        <img alt="#" className="faq_image" src={xray_logo}/>
                        <div className="common_area">
                            <div className="faq_step_title">1. {FaqProtocols.faq_install_app[lang]}</div>
                            <div className="common_text">
                                <div className="who_we_are_3">
                                    <div className="common_phrase">
                                        <ul>
                                            <li><h3>Android:</h3></li>
                                            <li className="faq_list_download">⬇ <a
                                                href="https://play.google.com/store/apps/details?id=dev.hexasoftware.v2box">V2Box
                                                - V2ray Client</a>
                                            </li>
                                            <li><h3>iOS:</h3></li>
                                            <li className="faq_list_download">⬇ <a
                                                href="https://itunes.apple.com/us/app/v2box-v2ray-client/id6446814690">V2Box
                                                - V2ray Client</a></li>
                                            <li><h3>Windows:</h3></li>
                                            <li className="faq_list_download">⬇ <a
                                                href="https://github.com/MatsuriDayo/nekoray/releases/download/3.26/nekoray-3.26-2023-12-09-windows64.zip">Nekoray</a>
                                            </li>
                                            <li><h3>MacOS:</h3></li>
                                            <li className="faq_list_download">⬇ <a
                                                href="https://itunes.apple.com/us/app/v2box-v2ray-client/id6446814690">V2Box
                                                - V2ray Client</a></li>
                                            <li><h3>Linux:</h3></li>
                                            <li className="faq_list_download">⬇ <a
                                                href="https://github.com/MatsuriDayo/nekoray/releases/download/3.26/nekoray-3.26-2023-12-09-linux64.zip">Nekoray</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={state.themeLight ? "faq_step" : "faq_step faq_step_dark"}>
                    <div className="common_grid_2">
                        <div className="faq_step_body">
                            <div className="faq_step_title">2. {FaqProtocols.faq_download_config[lang]}</div>
                            <div className="common_text">
                                <div className="who_we_are_3">
                                    <div className="common_phrase">
                                        <ul>
                                            <li>🔗 <a
                                                href="https://www.ponvpn.com/cabinet/certificates">{FaqProtocols.faq_website[lang]}</a>
                                            </li>
                                            <li>🔗 <a href="https://t.me/ponvpnbot">Telegram bot</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <img alt="#" className="faq_image" src={config_download}/>
                    </div>
                </div>
                <div className={state.themeLight ? "faq_step" : "faq_step faq_step_dark"}>
                    <div className="common_grid_2">
                        <img alt="#" className="faq_image" src={xray_import}/>
                        <div className="faq_step_body">
                            <div className="faq_step_title">{FaqProtocols.faq_import_and_connect[lang]}
                            </div>
                            <div className="faq_step_title">
                                <div className="who_we_are_3">
                                    <div className="common_phrase">
                                        <ul>
                                            <li>✔ {FaqProtocols.faq_choose_file[lang]}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={state.themeLight ? "faq_step" : "faq_step faq_step_dark"}>
                    <div className="faq_note">{FaqProtocols.faq_contact_support[lang]} - <a
                        href="mailto:support@ponvpn.com">support@ponvpn.com</a></div>
                </div>
                <div className={state.themeLight ? "faq_step" : "faq_step faq_step_dark"}>
                    <div className="faq_note">{FaqProtocols.faq_note_nekoray[lang]}
                    </div>
                </div>


                <div className="faq_protocol_info">{FaqProtocols.faq_trojan_long[lang]}</div>

                {/*<div className="os_code"># df -h | grep -v docker</div>*/}
                {/*<div className="faq_image_area"><img src={osImage}/></div>*/}
            </div>
        </>
    );
}