import { lang } from "../common/lang_enum";

const terms_title = {
    [lang.RU]: `Правила сервиса`,
    [lang.EN]: `Terms of Service`,
    [lang.DE]: `Nutzungsbedingungen`,
}

const terms_you_have_agree = {
    [lang.RU]: `Используя Сервис, вы подтверждаете, что прочитали, поняли и согласны соблюдать настоящие Условия.`,
    [lang.EN]: `By using the Service, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions.`,
    [lang.DE]: `Durch die Nutzung des Dienstes bestätigen Sie, dass Sie diese Allgemeinen Geschäftsbedingungen gelesen und verstanden haben und damit einverstanden sind, an sie gebunden zu sein.`,
}

const terms_you_will_be_banned = {
    [lang.RU]: `В случае неоднократного, систематического нарушения Вами настоящих Правил, мы оставляем за собой право полностью приостановить предоставление услуг.`,
    [lang.EN]: `In case of repeated, systematic violation of these Rules, we reserve the right to completely suspend the provision of services.`,
    [lang.DE]: `Im Falle eines wiederholten, systematischen Verstoßes Ihrerseits gegen diese Regeln behalten wir uns das Recht vor, die Leistungserbringung vollständig einzustellen.`,
}

const terms_1 = {
    [lang.RU]: `1. Вы подтверждаете, что достигли возраста совершеннолетия для пользования Cервисом.`,
    [lang.EN]: `1. You confirm that you have reached the age of majority to use the Service.`,
    [lang.DE]: `1. Sie bestätigen, dass Sie die Volljährigkeit zur Nutzung des Dienstes erreicht haben.`,
}
const terms_2 = {
    [lang.RU]: `2. Вы соглашаетесь не использовать Сервис для каких-либо незаконных или запрещенных действий, включая, помимо прочего, взлом, рассылку спама или любую деятельность, нарушающую действующее законодательство.`,
    [lang.EN]: `2. You agree not to use the Service for any unlawful or prohibited activities, including but not limited to hacking, spamming, or any activity that violates applicable laws.`,
    [lang.DE]: `2. Sie erklären sich damit einverstanden, den Dienst nicht für rechtswidrige oder verbotene Aktivitäten zu nutzen, einschließlich, aber nicht beschränkt auf Hacking, Spamming oder andere Aktivitäten, die gegen geltende Gesetze verstoßen.`,
}
const terms_3 = {
    [lang.RU]: `3. Для использования Сервиса необходимо создать учетную запись. Вы подтверждаете, что несете ответственность за сохранение конфиденциальности учетных данных вашей учетной записи.`,
    [lang.EN]: `3. To use the Service, it is required to create an account. You confirm to be responsible for maintaining the confidentiality of your account credentials.`,
    [lang.DE]: `3. Um den Dienst nutzen zu können, ist die Erstellung eines Kontos erforderlich. Sie bestätigen, dass Sie für die Wahrung der Vertraulichkeit Ihrer Kontodaten verantwortlich sind.`,
}
const terms_4 = {
    [lang.RU]: `4. Использование вами Сервиса регулируется Политикой конфиденциальности. Пожалуйста, ознакомьтесь с Политикой конфиденциальности для получения подробной информации о том, как мы собираем, используем и защищаем вашу информацию.`,
    [lang.EN]: `4. Your use of the Service is subject to Privacy Policy. Please refer to Privacy Policy for details on how we collect, use, and protect your information.`,
    [lang.DE]: `4. Ihre Nutzung des Dienstes unterliegt der Datenschutzrichtlinie. Einzelheiten darüber, wie wir Ihre Daten erfassen, verwenden und schützen, finden Sie in der Datenschutzerklärung.`,
}
const terms_5 = {
    [lang.RU]: `5. Сервис не несет ответственности за любые прямые либо косвенные убытки, возникшие в результате использования или невозможности использования Сервиса.`,
    [lang.EN]: `5. The Service is not liable for any direct, indirect, incidental, consequential, or special damages arising from the use or inability to use the Service.`,
    [lang.DE]: `5. Der Dienst haftet nicht für direkte, indirekte, zufällige, Folge- oder Sonderschäden, die sich aus der Nutzung oder Unmöglichkeit der Nutzung des Dienstes ergeben.`,
}
const terms_6 = {
    [lang.RU]: `6. Запрещается совершать любые действия, которые могут нарушить нормальное функционирование Сервиса.`,
    [lang.EN]: `6. It is prohibited to perform any actions that may disrupt the normal functioning of the Service.`,
    [lang.DE]: `6. Es ist verboten, Handlungen vorzunehmen, die den normalen Betrieb des Dienstes stören könnten.`,
}
const terms_7 = {
    [lang.RU]: `7. VPN удаляется сразу после истечения срока его действия, если на счету не осталось средств для продления.`,
    [lang.EN]: `7. VPN is deleted immediately after its expiration date if no funds left on Account to renew.`,
    [lang.DE]: `7. VPN wird unmittelbar nach seinem Ablaufdatum gelöscht, wenn auf dem Konto kein Guthaben mehr für die Verlängerung vorhanden ist.`,
}
const terms_8 = {
    [lang.RU]: `8. Вы соглашаетесь избегать использования оскорблений и ненормативной лексики при общении с любой частью Сервиса. Неподобающее поведение может привести к блокировке Аккаунта.`,
    [lang.EN]: `8. You agree to avoid using insults or profanity in communication with any part of the Service. Inappropriate behavior can result in Account to be banned.`,
    [lang.DE]: `8. Sie erklären sich damit einverstanden, Beleidigungen oder Obszönitäten in der Kommunikation mit irgendeinem Teil des Dienstes zu vermeiden. Unangemessenes Verhalten kann zur Sperrung des Kontos führen.`,
}
const terms_9 = {
    [lang.RU]: `9. Сервис оставляет за собой право изменять или обновлять настоящие Условия в любое время. Изменения вступают в силу после публикации на нашем сайте.`,
    [lang.EN]: `9. The Service reserves the right to modify or update these Terms and Conditions at any time. Changes will be effective upon posting on our website.`,
    [lang.DE]: `9. Der Dienst behält sich das Recht vor, diese Allgemeinen Geschäftsbedingungen jederzeit zu ändern oder zu aktualisieren. Änderungen werden mit der Veröffentlichung auf unserer Website wirksam.`,
}

export const FaqTerms = {
    terms_title,
    terms_you_have_agree,
    terms_you_will_be_banned,
    terms_1,
    terms_2,
    terms_3,
    terms_4,
    terms_5,
    terms_6,
    terms_7,
    terms_8,
    terms_9,
}