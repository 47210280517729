import React from "react";
import { useNavigate } from "react-router-dom";

import { login, register, Requester } from "js/logic/Requester";
import { Cookier } from "js/logic/Cookier";

import { EnterLang } from "js/languages/cabinet/enter";

import emailImage from "resources/icons/cabinet/email.webp";
import usernameImage from "resources/icons/cabinet/username.webp";
import passwordImage from "resources/icons/cabinet/password.webp";


export default function EnterPage({ state, setState }) {

    const lang = state.language;

    const [wantLogin, setWantLogin] = React.useState(true);

    const navigate = useNavigate();

    const [captchaText, setCaptchaText] = React.useState("");
    const [captchaAnswer, setCaptchaAnswer] = React.useState("");
    const [captchaEpoch, setCaptchaEpoch] = React.useState("");
    const [captchaId, setCaptchaId] = React.useState("");

    const [alertMessage, setAlertMessage] = React.useState("");
    const [alertMessageOk, setAlertMessageOk] = React.useState("");

    function clearAlerts() {
        setAlertMessage("");
        setAlertMessageOk("");
    }

    const [email, setEmail] = React.useState("");
    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [confirmedPassword, setConfirmedPassword] = React.useState("");

    React.useEffect(() => {
        setCaptcha();
    }, []);

    const setCaptcha = async () => {
        let ans = await Requester.getCaptcha();
        setCaptchaText(ans.eval);
        setCaptchaEpoch(ans.epoch);
        setCaptchaId(ans.id);
    }


    function EnterButton() {
        return wantLogin
            ? <a className={state.themeLight ? "enter_button" : "enter_button enter_button_dark"} href="#" onClick={() => doLogin()}>{EnterLang.login[lang]}</a>
            : <a className={state.themeLight ? "enter_button" : "enter_button enter_button_dark"} href="#" onClick={() => doRegister()}>{EnterLang.register[lang]}</a>;
    }

    function SwitchEnterButton() {

        const switchLogin = () => setWantLogin(!wantLogin);

        return wantLogin
            ? <a className={state.themeLight ? "enter_switch_button" : "enter_switch_button enter_switch_button_dark"} href="#" onClick={() => switchLogin()}>{EnterLang.or_register[lang]}</a>
            : <a className={state.themeLight ? "enter_switch_button" : "enter_switch_button enter_switch_button_dark"} href="#" onClick={() => switchLogin()}>{EnterLang.or_login[lang]}</a>;
    }

    return (
        <div className="common_filler">
            <div className="common_container">
                <div className={state.themeLight ? "enter_canvas" : "enter_canvas enter_canvas_dark"} id="enter">
                    <div className="enter_title">{wantLogin ? EnterLang.welcome_back[lang] : EnterLang.new_account[lang]}</div>

                    <div className="enter_fields">
                        {!wantLogin ?
                            <div className="enter_field_area">
                                <img src={emailImage} alt={""} />
                                <input type="text"
                                    placeholder={EnterLang.email[lang]} className={state.themeLight ? "enter_field" : "enter_field enter_field_dark"}
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                        clearAlerts();
                                    }}
                                    value={email}
                                    maxLength="255"
                                ></input>
                            </div>
                            : ""
                        }

                        <div className="enter_field_area">
                            <img src={usernameImage} alt={""} />
                            <input type="text" placeholder={EnterLang.username[lang]} className={state.themeLight ? "enter_field" : "enter_field enter_field_dark"}
                                onChange={(e) => {
                                    setUsername(e.target.value);
                                    clearAlerts();
                                }} value={username}></input>
                        </div>
                        <div className="enter_field_area">
                            <img src={passwordImage} alt={""} />
                            <input type="password" placeholder={EnterLang.password[lang]} className={state.themeLight ? "enter_field" : "enter_field enter_field_dark"}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                    clearAlerts();
                                }} value={password}></input>
                        </div>
                        {
                            !wantLogin ?
                                <div className="enter_field_area">
                                    <img src={passwordImage} alt={""} />
                                    <input type="password" placeholder={EnterLang.repeat[lang]} className={state.themeLight ? "enter_field" : "enter_field enter_field_dark"}
                                        onChange={(e) => {
                                            setConfirmedPassword(e.target.value);
                                            clearAlerts();
                                        }} value={confirmedPassword}></input>
                                </div>
                                : ""
                        }

                        {
                            !wantLogin ?
                                <div className="enter_captcha_box">
                                    <div className="enter_captcha_title">{EnterLang.captcha[lang]}</div>
                                    <div className="enter_captcha_eval">
                                        {captchaText}
                                    </div>
                                    <div className="enter_captcha_ans_area">
                                        <span className="your_ans_text">{EnterLang.answer[lang]}</span>
                                        <input className={state.themeLight ? "cabinet_input enter_short" : "cabinet_input cabinet_input_dark enter_short"}
                                            type="number" value={captchaAnswer} onChange={(e) => setCaptchaAnswer(e.target.value)} />
                                    </div>
                                </div>
                                : ""
                        }
                    </div>

                    <EnterButton />
                    <SwitchEnterButton />
                </div>
                <div className="enter_alert">{alertMessage}</div>
                <div className="enter_alert_ok">{alertMessageOk}</div>
            </div>
        </div>
    );

    async function doRegister() {
        let re = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,30}$");
        let usernameExp = new RegExp("^(?=.*[a-zA-Z])([A-Za-z0-9]){4,20}$");

        if ((password !== confirmedPassword) || (password === "")) {
            setAlertMessage(EnterLang.passwords_dont_match[lang]);
            return;
        }
        if (!re.test(password)) {
            setAlertMessage(EnterLang.password_requirements[lang]);
            return;
        }
        if (username.match(/^\d/)) {
            setAlertMessage(EnterLang.cant_start_with_digit[lang]);
            return;
        }
        if (!usernameExp.test(username)) {
            setAlertMessage(EnterLang.username_requirenments[lang]);
            return;
        }
        const ans = await Requester.register(email, username, password, captchaId, captchaEpoch, captchaAnswer);
        if (ans === Requester.captch) {
            setCaptcha();
            alert("Неверно ввели капчу");
            setCaptchaAnswer(0);
            return;
        }
        if (!ans.ok) {
            setAlertMessage(ans.text);
            setCaptcha();
            return;
        }
        setAlertMessageOk(EnterLang.account_created[lang] + username);
        setEmail("");
        setUsername("");
        setPassword("");
        setConfirmedPassword("");
        setWantLogin(!wantLogin);
    }

    async function doLogin() {
        if ((username === "") || (password === "")) {
            setAlertMessage(EnterLang.enter_creds[lang]);
            return;
        }
        const ans = await Requester.login(username, password);
        if (!ans.ok) {
            setAlertMessage(ans.text);
            return;
        }
        let token = ans.text;
        Cookier.saveTokenAndSetStateLogined({ i: state, set: setState }, token);
        navigate("/cabinet");
    }

}

