import {NavLink, useNavigate} from "react-router-dom";

import {MainLang} from "js/languages/index/main";

import mainPic from "resources/images/index/main.webp";

import stepBuyPic from "resources/images/index/step_buy.webp";
import stepDownloadPic from "resources/images/index/step_download.webp";
import stepImportPic from "resources/images/index/step_import.webp";
import constructorPic from "resources/images/index/constructor.webp";
import learnMorePic from "resources/images/index/learn_more.webp";

import osWinPic from "resources/icons/index/os_windows.webp";
import osLinPic from "resources/icons/index/os_linux.webp";
import osAndPic from "resources/icons/index/os_android.webp";
import osMacPic from "resources/icons/index/os_mac.webp";
import osBsdPic from "resources/icons/index/os_freebsd.webp";
import osRouPic from "resources/icons/index/os_router.webp";

function top() {
    window.scrollTo({ top: 0 })
}

export default function MainPage({state}) {
    let language = state.language;
    let theme = state.themeLight;

    return (
        <>
            <MainPanorama lang={language} theme={theme}/>
            <Letters state={state}/>
            <WeUseOVPN lang={language} theme={theme}/>
            <Pros lang={language} theme={theme}/>
            <LearnMore lang={language} theme={theme}/>
            <SupportedPlatforms lang={language} theme={theme}/>
            <Steps lang={language} theme={theme}/>
            <MainPrices lang={language} theme={theme}/>
        </>
    );
};

function MainPanorama({lang, theme}) {
    return (
        <section className={theme ? "common_section" : "common_section common_section_dark"}>
            <div className="common_footer_header_gap"></div>
            <div className="common_container">
                <div className="common_grid_2">
                    <div className="common_area">
                        <div className="common_panorama_name">PonVPN</div>
                        <div className="common_panorama_slogan">100% Pon, 100% VPN</div>
                        <div className="index_learn_more_text">
                            <p>{MainLang.top[lang]}<br/> <strong>{MainLang.topPrice[lang]}</strong></p>
                        </div>
                        <div className="common_button_mr">
                            <NavLink to={{pathname: "/prices", hash: "buy"}}
                                     onClick={() => top()}
                                     className={theme ? "common_panorama_button common_fancy_button" : "common_panorama_button common_fancy_button common_fancy_button_dark"}>{MainLang.topGetButton[lang]}</NavLink>
                        </div>
                    </div>
                    <img alt="#" className="common_panorama_image" src={mainPic}/>
                </div>
            </div>
        </section>
    );
}

function Letters({state}) {
    return (
        <div className="common_container">
            <div className="index_pon_letters">
                <div className={state.themeLight ? "index_pon_letter" : "index_pon_letter index_pon_letter_dark"}>P
                    <div className={state.themeLight ? "index_pon_meaning" : "index_pon_meaning index_pon_meaning_dark"}>private</div>
                </div>
                <div className={state.themeLight ? "index_pon_letter" : "index_pon_letter index_pon_letter_dark"}>O
                    <div className={state.themeLight ? "index_pon_meaning" : "index_pon_meaning index_pon_meaning_dark"}>open</div>
                </div>
                <div className={state.themeLight ? "index_pon_letter" : "index_pon_letter index_pon_letter_dark"}>N
                    <div className={state.themeLight ? "index_pon_meaning" : "index_pon_meaning index_pon_meaning_dark"}>new</div>
                </div>
            </div>
        </div>
    );
}

function WeUseOVPN({lang, theme}) {
    return (
        <div className={theme ? "index_we_use_ovpn_background" : "index_we_use_ovpn_background index_we_use_ovpn_background_dark"}>
            <div className="common_container">
                <h3 className={theme ? "common_h3_slogan" : "common_h3_slogan common_h3_slogan_dark"}>{MainLang.speechAboutProtocols[lang]}</h3>
            </div>
        </div>
    );
}

function Pros({lang, theme}) {
    return (
        <>
            <section className={theme ? "index_pros_area" : "index_pros_area index_pros_area_dark"}>
                <div className="common_container">
                    <div className="common_area">
                        <div className="common_grid_3">
                            <div className="index_pro">
                                <h4>{MainLang.pros_noLogsTitle[lang]}</h4>
                                <p>{MainLang.pros_noLogs[lang]}</p>
                            </div>
                            <div className="index_pro">
                                <h4>{MainLang.pros_dataCollectionTitle[lang]}</h4>
                                <p>{MainLang.pros_dataCollection[lang]}</p>
                            </div>
                            <div className="index_pro">
                                <h4>{MainLang.pros_opensourceTitle[lang]}</h4>
                                <p>{MainLang.pros_opensource[lang]}</p>
                            </div>
                            <div className="index_pro">
                                <h4>{MainLang.pros_cryptoTitle[lang]}</h4>
                                <p>{MainLang.pros_crypto[lang]}</p>
                            </div>
                            <div className="index_pro">
                                <h4>{MainLang.pros_botTitle[lang]}</h4>
                                <p>{MainLang.pros_bot[lang]}</p>
                            </div>
                            <div className="index_pro">
                                <h4>{MainLang.pros_countriesTitle[lang]}</h4>
                                <p>{MainLang.pros_countries[lang]}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

function LearnMore({lang, theme}) {
    return (
        <section className={theme ? "common_section" : "common_section common_section_dark"}>
            <div className="common_container">
                <div className="common_grid_2">

                    <div className="common_area">
                        <div className="index_learn_more_slogan">{MainLang.whyUsHeader[lang]}</div>
                        <div className="index_learn_more_text">
                            {MainLang.whyUsText[lang]}
                        </div>
                        <div className="common_button_mr">
                            <NavLink to={{pathname: "/about", hash: ""}}
                                 onClick={() => top()}
                                 className={theme ? "common_fancy_button common_panorama_button" : "common_fancy_button common_fancy_button_dark common_panorama_button"}>{MainLang.whyUsMoreButton[lang]}
                            </NavLink>
                        </div>
                    </div>
                    <img alt="#" className="common_image" src={learnMorePic}/>
                </div>
            </div>
        </ section>
    );
}

function SupportedPlatforms({lang, theme}) {
    return (
        <div className={theme ? "index_platform_background" : "index_platform_background index_platform_background_dark"}>
            <div className="common_container">
                <div className="index_platform_slogan">{MainLang.anyPlatformSlogan[lang]}</div>
                <div className="index_platform_area">
                    <div className="index_platform"><img alt="" src={osLinPic}/></div>
                    <div className="index_platform"><img alt="" src={osBsdPic}/></div>
                    <div className="index_platform"><img alt="" src={osWinPic}/></div>
                    <div className="index_platform"><img alt="" src={osRouPic}/></div>
                    <div className="index_platform"><img alt="" src={osMacPic}/></div>
                    <div className="index_platform"><img alt="" src={osAndPic}/></div>
                </div>
                <div className="index_platform_text">{MainLang.anyPlatformText[lang]}</div>
            </div>
        </div>
    );
}

function Steps({lang, theme}) {
    return (
        <div className={theme ? "index_steps" : "index_steps index_steps_dark"}>
            <div className="common_container">
                <div className="index_steps_slogan">{MainLang.stepsSlogan[lang]}</div>
                <div className="common_grid_3">
                    <div className={theme ? "index_step" : "index_step index_step_dark"}>
                        <div className="index_step_image"><img alt="#" src={stepBuyPic}/></div>
                        <div className="index_step_slogan">{MainLang.stepsStep_1Title[lang]}</div>
                        <div className="index_step_description">{MainLang.stepsStep_1Text[lang]}</div>
                    </div>
                    <div className={theme ? "index_step" : "index_step index_step_dark"}>
                        <div className="index_step_image"><img alt="#" src={stepDownloadPic}/></div>
                        <div className="index_step_slogan">{MainLang.stepsStep_2Title[lang]}</div>
                        <div className="index_step_description">{MainLang.stepsStep_2Text[lang]}</div>
                    </div>
                    <div className={theme ? "index_step" : "index_step index_step_dark"}>
                        <div className="index_step_image"><img alt="#" src={stepImportPic}/></div>
                        <div className="index_step_slogan">{MainLang.stepsStep_3Title[lang]}</div>
                        <div className="index_step_description">{MainLang.stepsStep_3Text[lang]}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function MainPrices({lang, theme}) {

    const navigate = useNavigate();

    return (
        <section className={theme ? "common_section" : "common_section common_section_dark"}>
            <div className="common_container">
                <div className="common_grid_2">
                    <img alt="#" className="common_image" src={constructorPic}/>
                    <div className="common_area">
                        <div className="common_slogan">{MainLang.rightTariffSlogan[lang]}</div>
                        <div className="common_text">{MainLang.rightTariffText[lang]}</div>
                        <ul>
                            <li>{MainLang.rightTariffPro_1[lang]}</li>
                            <li>{MainLang.rightTariffPro_2[lang]}</li>
                            <li>{MainLang.rightTariffPro_3[lang]}</li>
                            <li>{MainLang.rightTariffPro_4[lang]}</li>
                        </ul>
                        <div className="common_button_mr">
                            <NavLink to={{pathname: "/prices", hash: "buy"}}
                                  onClick={() => top()}
                                  className={theme ? "common_fancy_button index_prices_button" : "common_fancy_button common_fancy_button_dark index_prices_button"}>{MainLang.rightTariffButton[lang]}
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
            <div className="common_footer_header_gap"></div>
        </section>
    );
}