import { FaqContacts } from "js/languages/faq/contacts";
import BackToFaq from "js/pages/common/BackToFaq";


export default function Contacts({ state, setState, display_back }) {

    const lang = state.language;
    return (
        <>
            <div className="faq_body_internal">
            {display_back ? <BackToFaq state={state} setState={setState} /> : ""}
            <div className="common_protocol_title">{FaqContacts.contacts_title[lang]}</div>
            <div>{FaqContacts.contacts_any_questions[lang]}</div>
            <div>{FaqContacts.contacts_our_mailboxes[lang]}</div>
            <div className="faq_terms_conditions">
                <div><strong>support@ponvpn.com</strong>{FaqContacts.contacts_support[lang]}</div>
                <div><strong>abuse@ponvpn.com</strong>{FaqContacts.contacts_abuse[lang]}</div>
                <div><strong>manager@ponvpn.com</strong>{FaqContacts.contacts_manager[lang]}</div>
                <div><strong>feedback@ponvpn.com</strong>{FaqContacts.contacts_feedback[lang]}</div>
            </div>
            <div>{FaqContacts.contacts_english_please[lang]}</div>
            </div>
        </>
    )
}