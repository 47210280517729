// REACT
import React from "react";
import ReactDOM from "react-dom/client";

import "resources/css/config.css"

// about CSS
import "js/pages/about/about.css";
// cabinet CSS
import "js/pages/cabinet/certificates/certificates.css";
import "js/pages/enter/enter.css";
import "js/pages/cabinet/balance/profile.css";
import "js/pages/cabinet/cabinet.css";
// common CSS
import "resources/css/common.css";
import "js/pages/constructor/constructor.css";
import "js/pages/footer/footer.css";
import "js/pages/header/header.css";
// faq CSS
import "js/pages/faq/faq.css";
// index CSS
import "js/pages/index/index.css";
// prices CSS
import "js/pages/prices/prices.css";
import "resources/css/loading.css";
// APP
import App from "./js/App";
import { BrowserRouter } from "react-router-dom";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
);