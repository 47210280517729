import { lang } from "../common/lang_enum";

const rules_title = {
    [lang.RU]: `Правила использования сервиса`,
    [lang.EN]: `Service usage rules`,
    [lang.DE]: `Regeln zur Nutzung des Dienstes`,
}

const rules_info = {
    [lang.RU]: `Использование сервиса регулируется соответствующими Правилами и Политикой приватности.`,
    [lang.EN]: `The use of the service is regulated by the corresponding Terms of Use and Privacy Policy.`,
    [lang.DE]: `Die Nutzung des Dienstes wird durch die entsprechenden Nutzungsbedingungen und Datenschutzbestimmungen geregelt.`,
}

export const RulesLang = {
    rules_title,
    rules_info,
}