import React from "react";

import {NavLink} from "react-router-dom";

import {CabLang} from "js/languages/cabinet/cabinet";

export default function BackToMenu({state, setState}) {

    const lang = state.language;

    function setScreen(name) {
        let newState = {...state};
        newState.cabinetScreen = name;
        setState(newState);
    }

    return (
        <div className="cabinet_back_to_menu_wrapper">
            <NavLink className="common_fancy_button" to="/cabinet" onClick={() => {
                setScreen("none");
            }}>{CabLang.menu_back[lang]}</NavLink>
        </div>
    );
}