// REACT
import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
// PAGES
import MainPage from "js/pages/index/MainPage";
import AboutPage from "js/pages/about/AboutPage";
import FaqPage from "js/pages/faq/FaqPage";
import PricesPage from "js/pages/prices/PricesPage";
import TelegramPage from "js/pages/telegram/TelegramPage";
import EnterPage from "js/pages/enter/EnterPage";
import Cabinet from "js/pages/cabinet/Cabinet";
import ErrorPage from "js/pages/error/ErrorPage";
// JS
import { Cookier } from "js/logic/Cookier";
import { lang } from "js/languages/common/lang_enum";
import { ConstLang } from "js/languages/common/constructor";

import Footer from "./pages/footer/Footer";
import Header from "./pages/header/Header";


export default function App() {

    const location = useLocation();

    const [constructorData, setConstructorData] = React.useState({
        country: ConstLang.countries[0],
        protocol: ConstLang.protocols[0],
        bwSliderPosition: 1,
        periodSliderPosition: 1,
        priceToBuyMonth: 1,
        priceToBuyTotal: 1,
    });

    const [certificatesData, setCertificatesData] = React.useState({
        readyCerts: [],
        preparingCerts: [],
        errorCerts: [],
        stoppedCerts: [],
        needUpdateAllCerts: true,
        needUpdatePreparingCerts: false,
    });

    const [userData, setUserData] = React.useState({
        balance: "-",
        email: "",
        telegramId: "",
        moneroAddress: "",
        warning: false,
        customWarningText: ""
    });

    const [state, setState] = React.useState({
        logined: false,
        language: lang.EN,
        themeLight: Cookier.getTheme(),
        cabinetScreen: "none",
        faqScreen: "none",
        footerVisible: true,
        user: {
            data: userData,
            setMoneroAddress: function (address) {
                this.data.moneroAddress = address;
                setUserData({ ...this.data })
            },
            setTelegramId: function (telegramId) {
                this.data.telegramId = telegramId;
                setUserData({ ...this.data })
            },
            setEmail: function (email) {
                this.data.email = email;
                setUserData({ ...this.data })
            },
            setEmailAndBalance: function (email, balance) {
                this.data.email = email;
                this.data.balance = balance;
                setUserData({ ...this.data })
            },
            setWarning: function (warning) {
                this.data.warning = warning;
                setUserData({ ...this.data })
            },
            setCustomWarningText: function (text) {
                this.data.customWarningText = text;
                setUserData({ ...this.data })
            },
        },
        certs: {
            data: certificatesData,
            setReadyCerts: function (readyCerts) {
                this.data.readyCerts = readyCerts;
                setCertificatesData({ ...this.data });
            },
            setPreparingCerts: function (preparingCerts) {
                this.data.preparingCerts = preparingCerts;
                setCertificatesData({ ...this.data });
            },
            setErrorCerts: function (errorCerts) {
                this.data.errorCerts = errorCerts;
                setCertificatesData({ ...this.data });
            },
            setStoppedCerts: function (stoppedCerts) {
                this.data.stoppedCerts = stoppedCerts;
                setCertificatesData({ ...this.data });
            },
            setNeedUpdateAllCerts: function (needUpdateAllCerts) {
                this.data.needUpdateAllCerts = needUpdateAllCerts;
                setCertificatesData({ ...this.data });
            },
            setNeedUpdatePreparingCerts: function (needUpdatePreparingCerts) {
                this.data.needUpdatePreparingCerts = needUpdatePreparingCerts;
                setCertificatesData({ ...this.data });
            }
        },
        constructor: {
            data: constructorData,
            setCountry: function (country) {
                this.data.country = country;
                setConstructorData({ ...this.data });
            },
            setProtocol: function (protocol) {
                this.data.protocol = protocol;
                setConstructorData({ ...this.data });
            },
            setBwSliderPosition: function (pos) {
                this.data.bwSliderPosition = pos;
                setConstructorData({ ...this.data });
            },
            setPeriodSliderPosition: function (pos) {
                this.data.periodSliderPosition = pos;
                setConstructorData({ ...this.data });
            },
            setPriceToBuy: function (month, total) {
                this.data.priceToBuyMonth = month;
                this.data.priceToBuyTotal = total;
                setConstructorData({ ...this.data });
            },
        }
    });

    React.useEffect(() => {
        if (Cookier.checkToken()) state.logined = true;
        state.themeLight = Cookier.getTheme();
        state.language = Cookier.getLanguage();
        if (state.language == null || state.language === "")
            state.language = lang.EN;
        setState({ ...state });

        if (!state.themeLight) document.documentElement.style.background = "var(--color_background_dark)";
        else document.documentElement.style.background = "var(--color_background_light)";
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Scroll to Anchor
    const scrolledRef = React.useRef(false);
    const { hash } = useLocation();
    const hashRef = React.useRef(hash);

    React.useEffect(() => {
        // We want to reset if the hash has changed
        if (hashRef.current !== hash) {
            hashRef.current = hash;
            scrolledRef.current = false;
        }
        if (!scrolledRef.current) {
            // if not a hash link, scroll to top
            if (hash !== '') {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView();
                    scrolledRef.current = true;
                }
            }
        }
    });

    return (
        <div className={state.themeLight ? "" : "body_dark"}>
            <Header state={state} setState={setState} />
            <Routes>
                <Route path="/" element={<MainPage state={state} />} />
                <Route path="/about" element={<AboutPage state={state} />} />
                <Route path="/enter" element={<EnterPage state={state} setState={setState} />} />
                <Route path="/prices" element={<PricesPage state={state} setState={setState} />} />
                <Route path="/telegram" element={<TelegramPage state={state} />} />
                <Route path="/error" element={<ErrorPage state={state} />} />
                <Route path="/faq/*" element={<FaqPage state={state} setState={setState} />} />
                <Route path="/cabinet/*" element={<Cabinet state={state} setState={setState} />} />
            </Routes>
            {!location.pathname.includes("cabinet") ? <Footer state={state} /> : ""}
        </div>
    );
}