import React from "react";

import { FaqTelegram } from "js/languages/faq/telegram";

import qrImage from "../../../resources/images/telegram/tg_qr.webp";

export default function TelegramPage({ state }) {

    const lang = state.language;
    return (
        <section className={state.themeLight ? "common_section" : "common_section common_section_dark"}>
            <div className="common_footer_header_gap"></div>
            <div className="common_filler">
                <div className="common_container">
                    <div className="common_grid_2">
                        <div className="common_area">
                            <div className="common_panorama_name">{FaqTelegram.tg_title[lang]}</div>
                            <div className="common_panorama_slogan">{FaqTelegram.tg_manage_your_services[lang]}</div>
                            <p>{FaqTelegram.tg_main_text[lang]}: @ponvpnbot</p>
                            <div className="common_button_mr">
                                <a href="https://t.me/ponvpnbot"
                                   className={state.themeLight ? "common_panorama_button common_fancy_button" : "common_panorama_button common_fancy_button common_fancy_button_dark"}>
                                    {FaqTelegram.tg_main_button[lang]}
                                </a>
                            </div>
                        </div>
                        <img alt="#" className="common_panorama_image common_image_br_05" src={qrImage}/>
                    </div>
                </div>
            </div>
            <div className="common_footer_header_gap"></div>
        </section>
    );
}