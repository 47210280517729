import { FaqPayments } from "js/languages/faq/money";
import BackToFaq from "js/pages/common/BackToFaq";


export default function Monero({ state, setState }) {

    const lang = state.language;
    return (
        <>
            <div className="faq_body_internal">
            <BackToFaq state={state} setState={setState} />
            <div className="common_protocol_title">Monero</div>
            <div>{FaqPayments.monero_1[lang]}</div>
            <div>{FaqPayments.monero_2[lang]}</div>
            <div>{FaqPayments.monero_3[lang]}</div>
            </div>
        </>
    )
}