import { lang } from "../common/lang_enum";

const tg_title = {
    [lang.RU]: `У нас есть Telegram Bot!`,
    [lang.EN]: `We have Telegram Bot!`,
    [lang.DE]: `Wir haben Telegram Bot!`,
}

const tg_manage_your_services = {
    [lang.RU]: `Управляйте услугами легко!`,
    [lang.EN]: `Manage your services easily!`,
    [lang.DE]: `Verwalten Sie Ihre Dienste ganz einfach!`,
}

const tg_main_text = {
    [lang.RU]: `Самый быстрый и простой способ создать свой VPN`,
    [lang.EN]: `The fastest and easiest way to build your VPN`,
    [lang.DE]: `Der schnellste und einfachste Weg, Ihr VPN aufzubauen`,
}

const tg_main_button = {
    [lang.RU]: `Открыть Telegram`,
    [lang.EN]: `Open Telegram`,
    [lang.DE]: `Öffnen Sie Telegram`,
}

const err_something_wrong = {
    [lang.RU]: `Что-то пошло не так`,
    [lang.EN]: `Something went wrong`,
    [lang.DE]: `Irgendwas ist schief gelaufen`,
}

const err_error_info = {
    [lang.RU]: `При выполнении запроса произошла ошибка. Пожалуйста, свяжитесь с нашей поддержкой`,
    [lang.EN]: `An error occurred while executing the request. Please contact our support`,
    [lang.DE]: `Beim Ausführen der Anforderung ist ein Fehler aufgetreten. Bitte kontaktieren Sie unseren Support`,
}

const err_open_mail = {
    [lang.RU]: `Написать нам`,
    [lang.EN]: `Contact us`,
    [lang.DE]: `Kontaktieren Sie uns`,
}

export const FaqTelegram = {
    tg_title,
    tg_manage_your_services,
    tg_main_text,
    tg_main_button,
    err_something_wrong, err_open_mail, err_error_info
}