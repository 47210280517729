import React from "react";

import { NavLink } from "react-router-dom";

import { CabLang } from "js/languages/cabinet/cabinet";

export default function BackToFaq({ state, setState }) {

    const lang = state.language;

    function setScreen(name) {
        let newState = { ...state };
        newState.faqtScreen = name;
        setState(newState);
    }

    return (
        <div className="cabinet_back_to_menu_wrapper">
            <NavLink className="common_fancy_button" to="/faq" onClick={() => {
                setScreen("none");
                window.scrollTo({ top: 0 });
            }}>{CabLang.menu_back[lang]}</NavLink>
        </div>
    );
}