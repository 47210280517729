import { lang } from "../common/lang_enum";

const login = {
    [lang.RU]: `Вход`,
    [lang.EN]: `Sign in`,
    [lang.DE]: `Anmelden`,
}

const register = {
    [lang.RU]: `Регистрация`,
    [lang.EN]: `Sign up`,
    [lang.DE]: `Registrieren`,
}

const answer = {
    [lang.RU]: `Ответ:`,
    [lang.EN]: `Ansver:`,
    [lang.DE]: `Antwort:`,
}

const or_login = {
    [lang.RU]: `или войти`,
    [lang.EN]: `or sign in`,
    [lang.DE]: `oder anmelden`,
}

const or_register = {
    [lang.RU]: `создать новый аккаунт`,
    [lang.EN]: `or sign up`,
    [lang.DE]: `oder registrieren`,
}

const welcome_back = {
    [lang.RU]: `с возвращением`,
    [lang.EN]: `welcome back`,
    [lang.DE]: `willkommen zurück`,
}

const new_account = {
    [lang.RU]: `Новый аккаунт?`,
    [lang.EN]: `new account?`,
    [lang.DE]: `neues Konto?`,
}

const password = {
    [lang.RU]: `Пароль`,
    [lang.EN]: `Password`,
    [lang.DE]: `Passwort`,
}

const captcha = {
    [lang.RU]: `Вы должны решить пример, чтобы создать аккаунт`,
    [lang.EN]: `You must solve the equation to create an account`,
    [lang.DE]: `Sie müssen die Gleichung lösen, um ein Konto zu erstellen`,
}

const repeat = {
    [lang.RU]: `Повторите пароль`,
    [lang.EN]: `Repeat password`,
    [lang.DE]: `Passwort wiederholen`,
}

const email = {
    [lang.RU]: `Email (если хотите)`,
    [lang.EN]: `Email (if you want)`,
    [lang.DE]: `Email (falls Sie es wollen)`,
}

const username = {
    [lang.RU]: `Имя пользователя`,
    [lang.EN]: `Username`,
    [lang.DE]: `Nutzername`,
}

const passwords_dont_match = {
    [lang.RU]: `Пароли не совпадают!`,
    [lang.EN]: `Passwords don't match!`,
    [lang.DE]: `Passwörter stimmen nicht überein!`,
}

const password_requirements = {
    [lang.RU]: `Требования к паролю: минимум 8 и максимум 30 символов, одна большая буква, одна маленькая бука, одно число и один специальный символ.`,
    [lang.EN]: `Password requirements: minimum 8 and maximum 30 characters, one capital letter, one small letter, one number and one special character.`,
    [lang.DE]: `Passwortanforderungen: mindestens 8 und maximal 30 Zeichen, ein Großbuchstabe, ein Kleinbuchstabe, eine Zahl und ein Sonderzeichen.`,
}

const cant_start_with_digit = {
    [lang.RU]: `Имя пользователя не должно начинаться с цифры.`,
    [lang.EN]: `Username can't start with digit.`,
    [lang.DE]: `Der Benutzername darf nicht mit einer Ziffer beginnen.`,
}

const username_requirenments = {
    [lang.RU]: `Имя пользователя может содержать только латинские символы и цифры. Минимальная длина 4 символа, максимальная - 20.`,
    [lang.EN]: `The username can contain only Latin characters and numbers. The minimum length is 4 characters, the maximum is 20.`,
    [lang.DE]: `Der Benutzername darf nur lateinische Zeichen und Zahlen enthalten. Die Mindestlänge beträgt 4 Zeichen, die Höchstlänge 20.`,
}

const enter_creds = {
    [lang.RU]: `Пожалуйста, введите логин и пароль!`,
    [lang.EN]: `Please, enter login and password!`,
    [lang.DE]: `Bitte geben Sie Login und Passwort ein!`,
}

const account_created = {
    [lang.RU]: `Аккаунт был успешно создан! Теперь вы можете войти, `,
    [lang.EN]: `Account successfully created! You can login now, `,
    [lang.DE]: `Konto erfolgreich erstellt! Sie können sich jetzt anmelden, `,
}

export const EnterLang = {
    login,
    register,
    welcome_back,
    new_account, or_login,
    or_register,
    password,
    repeat,
    email,
    username,
    passwords_dont_match,
    password_requirements,
    cant_start_with_digit,
    username_requirenments,
    enter_creds,
    account_created,
    captcha,
    answer
}