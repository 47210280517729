import { lang } from "../common/lang_enum";

const monero_1 = {
    [lang.RU]: `Вы можете совершить прямой платеж с минимальными комиссиями, используя криптовалюту Monero (XMR).`,
    [lang.EN]: `You can make direct payment with minimum commissions using Monero (XMR) cryptocurrency.`,
    [lang.DE]: `Mit der Kryptowährung Monero (XMR) können Sie mit minimalen Provisionen direkt bezahlen.`,
}

const monero_2 = {
    [lang.RU]: `Monero (XMR) — это криптовалюта, ориентированная на конфиденциальность, цель которой — предоставить пользователям повышенную анонимность и безопасность в их финансовых транзакциях.`,
    [lang.EN]: `Monero (XMR) is a privacy-focused cryptocurrency that aims to provide users with enhanced anonymity and security in their financial transactions.`,
    [lang.DE]: `Monero (XMR) ist eine datenschutzorientierte Kryptowährung, die darauf abzielt, Benutzern mehr Anonymität und Sicherheit bei ihren Finanztransaktionen zu bieten.`,
}

const monero_3 = {
    [lang.RU]: `Он был запущен в апреле 2014 года как форк криптовалюты Bytecoin. Monero построен на принципах конфиденциальности, децентрализации и взаимозаменяемости.`,
    [lang.EN]: `It was launched in April 2014 as a fork of the Bytecoin cryptocurrency. Monero is built on the principles of privacy, decentralization, and fungibility.`,
    [lang.DE]: `Es wurde im April 2014 als Ableger der Kryptowährung Bytecoin eingeführt. Monero basiert auf den Prinzipien der Privatsphäre, Dezentralisierung und Fungibilität.`,
}

const cryptocloud_1 = {
    [lang.RU]: `Вы можете произвести оплату с помощью криптовалюты через платежную систему CryptoCloud. Мы начислим Ваш баланс в USD как только получим подтверждение от CryptoCloud. Рекомендуем Вам сохранить ссылку оплаты на случай возможных неполадок.`,
    [lang.EN]: `You can make a payment using  cryptocurrency through the CryptoCloud payment system. We will add your balance in USD as soon as we receive confirmation from CryptoCloud. We recommend that you save the payment link in case of possible problems.`,
    [lang.DE]: `Sie können eine Zahlung mit Kryptowährung über das CryptoCloud-Zahlungssystem vornehmen. Wir werden Ihr Guthaben in USD hinzufügen, sobald wir eine Bestätigung von CryptoCloud erhalten. Wir empfehlen Ihnen, den Zahlungslink bei möglichen Problemen zu speichern.`,
}

const cryptocloud_2 = {
    [lang.RU]: `CryptoCloud поддерживает широкий спектр валют, предоставляя вам свободу совершать транзакции по всему миру без необходимости конвертации валют.`,
    [lang.EN]: `CryptoCloud supports a wide range of currencies, giving you the freedom to transact globally without the hassle of currency conversions.`,
    [lang.DE]: `CryptoCloud unterstützt eine Vielzahl von Währungen und gibt Ihnen die Freiheit, weltweit Transaktionen durchzuführen, ohne den Aufwand einer Währungsumrechnung.`,
}

const cryptocloud_3 = {
    [lang.RU]: `Независимо от того, являетесь ли вы опытным энтузиастом криптовалют или новичком, эта платформа позволяет вам легко и уверенно совершать платежи.`,
    [lang.EN]: `Whether you're a seasoned crypto enthusiast or a newcomer, that platform makes it easy for you to make payments with confidence.`,
    [lang.DE]: `Egal, ob Sie ein erfahrener Krypto-Enthusiast oder ein Neuling sind, diese Plattform macht es Ihnen leicht, Zahlungen sicher durchzuführen.`,
}

const mb_title = {
    [lang.RU]: `Политика возврата средств`,
    [lang.EN]: `Money-Back Policy`,
    [lang.DE]: `Geld-zurück-Richtlinie`,
}

const mb_ve_provide_service = {
    [lang.RU]: `Мы стремимся предоставлять качественные услуги. В настоящей Политике изложены условия, на которых мы предлагаем возврат средств.`,
    [lang.EN]: `We strive to provide quality services and ensure your satisfaction. This Money-Back Policy outlines the terms and conditions under which we offer refunds.`,
    [lang.DE]: `Wir sind bestrebt, qualitativ hochwertige Dienstleistungen zu erbringen und Ihre Zufriedenheit sicherzustellen. Diese Geld-zurück-Richtlinie beschreibt die Bedingungen, unter denen wir Rückerstattungen anbieten.`,
}

const mb_criteria = {
    [lang.RU]: `Чтобы вернуть средства, услуга должна соответствовать следующим критериям:`,
    [lang.EN]: `To be eligible for a refund, you must meet the criteria specified in the respective product or service's terms and conditions.`,
    [lang.DE]: `Um Anspruch auf eine Rückerstattung zu haben, müssen Sie die in den Geschäftsbedingungen des jeweiligen Produkts oder Dienstes genannten Kriterien erfüllen.`,
}

const mb_timeframe_title = {
    [lang.RU]: `Временные рамки:`,
    [lang.EN]: `Timeframe:`,
    [lang.DE]: `Zeitfenster:`,
}

const mb_timeframe = {
    [lang.RU]: `Запрос на возврат средств должен быть подан в течение 3 дней с даты покупки или начала оказания услуги.`,
    [lang.EN]: `Refund requests must be submitted within 3 days from the date of purchase or service initiation.`,
    [lang.DE]: `Rückerstattungsanträge müssen innerhalb von 3 Tagen ab Kaufdatum oder Beginn der Dienstleistung eingereicht werden.`,
}

const mb_operability_title = {
    [lang.RU]: `Работоспособность:`,
    [lang.EN]: `Operability:`,
    [lang.DE]: `Bedienbarkeit:`,
}

const mb_operability = {
    [lang.RU]: `Приобретенная услуга должна находиться в неработоспособном состоянии (т.е. без возможности установить VPN-соединение)`,
    [lang.EN]: `The purchased service must be in an inoperable state (i.e. without the ability to establish a VPN connection)`,
    [lang.DE]: `Der erworbene Dienst muss sich in einem nicht funktionsfähigen Zustand befinden (d. h. ohne die Möglichkeit, eine VPN-Verbindung aufzubauen).`,
}

const mb_we_reserve_right = {
    [lang.RU]: `Возврат средств подлежит утверждению нашей командой. Мы оставляем за собой право отклонить запрос на возврат средств, если он не соответствует указанным критериям приемлемости.`,
    [lang.EN]: `Refunds are subject to approval by our team. We reserve the right to deny a refund request if it does not meet the specified eligibility criteria.`,
    [lang.DE]: `Rückerstattungen unterliegen der Genehmigung durch unser Team. Wir behalten uns das Recht vor, einen Rückerstattungsantrag abzulehnen, wenn er die angegebenen Berechtigungskriterien nicht erfüllt.`,
}

const mb_your_responsibility = {
    [lang.RU]: `Мы не несем ответственности за каждый протокол, работающий в вашем регионе. Если в вашем регионе заблокированы определенные протоколы, рекомендуем выбрать другой протокол.`,
    [lang.EN]: `We are not responsible for every protocol working in you location. If certain protocols are blocked in your location, we recommend to choose the appropriate protocol.`,
    [lang.DE]: `Wir sind nicht dafür verantwortlich, dass jedes Protokoll an Ihrem Standort funktioniert. Sollten bestimmte Protokolle an Ihrem Standort blockiert sein, empfehlen wir, das entsprechende Protokoll auszuwählen.`,
}

const mb_only_xmr = {
    [lang.RU]: `Возврат средств осуществляется только через Monero XMR.`,
    [lang.EN]: `Refunds are made only through Monero XMR.`,
    [lang.DE]: `Rückerstattungen erfolgen nur über Monero XMR.`,
}
const mb_contact_us = {
    [lang.RU]: `Чтобы запросить возврат средств, свяжитесь с нашей службой поддержки клиентов по адресу manager@ponvpn.com с подробным объяснением причины запроса на возврат средств.`,
    [lang.EN]: `To request a refund, please contact our customer support team at manager@ponvpn.com with the detailed reason explanation of the refund request.`,
    [lang.DE]: `Um eine Rückerstattung zu beantragen, wenden Sie sich bitte an unser Kundensupport-Team unter manager@ponvpn.com und geben Sie eine detaillierte Begründung für die Rückerstattungsanfrage an.`,
}

export const FaqPayments = {
    monero_1,
    monero_2,
    monero_3,
    cryptocloud_1,
    cryptocloud_2,
    cryptocloud_3,
    mb_title,
    mb_ve_provide_service,
    mb_criteria,
    mb_timeframe_title,
    mb_timeframe,
    mb_operability_title,
    mb_operability,
    mb_we_reserve_right,
    mb_your_responsibility,
    mb_only_xmr,
    mb_contact_us
}