import React from "react";

import Constructor from "js/pages/constructor/Constructor";
import BackToMenu from "js/pages/common/BackToMenu"

export default function Buy({ state, setState }) {

    return (
        <>
            <div className="">
                <BackToMenu state={state} setState={setState} />
                <Constructor state={state} setState={setState} />
            </div>
        </>
    );
};