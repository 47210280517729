import { RulesLang } from "js/languages/faq/rules";
import BackToFaq from "js/pages/common/BackToFaq";



export default function Rules({ state, setState }) {

    const lang = state.language;
    return (
        <>
            <div className="faq_body_internal">
            <BackToFaq state={state} setState={setState} />
            <div className="common_protocol_title">{RulesLang.rules_title[lang]}</div>
            <div>{RulesLang.rules_info[lang]}</div>
            </div>
        </>
    )
}