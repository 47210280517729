import { lang } from "../common/lang_enum";

const contacts_title = {
    [lang.RU]: `Контакты`,
    [lang.EN]: `Contacts`,
    [lang.DE]: `Kontakte`,
}

const contacts_any_questions = {
    [lang.RU]: `Мы стремимся предоставлять отличный сервис и хотим всегда оставаться на связи. Если у вас есть какие-либо вопросы, комментарии, предложения или жалобы, вы всегда можете связаться с нами, используя один из адресов электронной почты ниже.`,
    [lang.EN]: `We are aimed to provide excellent service and want always stay in touch. If you have any questions, comments, suggestions or complaints, you can always contact us using one of the emails below.`,
    [lang.DE]: `Unser Ziel ist es, exzellenten Service zu bieten und immer in Kontakt zu bleiben. Wenn Sie Fragen, Kommentare, Vorschläge oder Beschwerden haben, können Sie uns jederzeit über eine der untenstehenden E-Mails kontaktieren.`,
}

const contacts_our_mailboxes = {
    [lang.RU]: `Свяжитесь с нами через:`,
    [lang.EN]: `Contact us via:`,
    [lang.DE]: `Kontaktieren Sie uns über:`,
}

const contacts_support = {
    [lang.RU]: ` - cлужба поддержки`,
    [lang.EN]: ` - customer support`,
    [lang.DE]: ` - Kundendienst`,
}
const contacts_abuse = {
    [lang.RU]: ` - обработка жалоб`,
    [lang.EN]: ` - complaints`,
    [lang.DE]: ` - Beschwerden`,
}
const contacts_manager = {
    [lang.RU]: ` - по вопросам взаимодействия и сотрудничества`,
    [lang.EN]: ` - interaction and cooperation`,
    [lang.DE]: ` - Interaktion und Kooperation`,
}
const contacts_feedback = {
    [lang.RU]: ` - обратная связь`,
    [lang.EN]: ` - feedback`,
    [lang.DE]: ` - Rückmeldung`,
}

const contacts_english_please = {
    [lang.RU]: `Пожалуйста, используйте английский или русский язык при обращении к нам по электронной почте.`,
    [lang.EN]: `Please use English or Russian language when contacting us by email.`,
    [lang.DE]: `Bitte verwenden Sie bei der Kontaktaufnahme per E-Mail die englische oder russische Sprache.`,
}

export const FaqContacts = {
    contacts_title,
    contacts_any_questions,
    contacts_our_mailboxes,
    contacts_support,
    contacts_abuse,
    contacts_manager,
    contacts_feedback,
    contacts_english_please
}