import { FaqTerms } from "js/languages/faq/terms";
import BackToFaq from "js/pages/common/BackToFaq";



export default function Terms({ state, setState }) {

    const lang = state.language;
    return (
        <>
            <div className="faq_body_internal">
            <BackToFaq state={state} setState={setState} />
            <div className="common_protocol_title">{FaqTerms.terms_title[lang]}</div>
            <div>{FaqTerms.terms_you_have_agree[lang]}</div>
            <div className="faq_terms_conditions">
                <div>{FaqTerms.terms_1[lang]}</div>
                <div>{FaqTerms.terms_2[lang]}</div>
                <div>{FaqTerms.terms_3[lang]}</div>
                <div>{FaqTerms.terms_4[lang]}</div>
                <div>{FaqTerms.terms_5[lang]}</div>
                <div>{FaqTerms.terms_6[lang]}</div>
                <div>{FaqTerms.terms_7[lang]}</div>
                <div>{FaqTerms.terms_8[lang]}</div>
                <div>{FaqTerms.terms_9[lang]}</div>
            </div>

            <div>{FaqTerms.terms_you_will_be_banned[lang]}</div>
            </div>
        </>
    )
}