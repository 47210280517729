import BackToFaq from "js/pages/common/BackToFaq";


export default function PrivacyPolicy({ state, setState }) {
    return (
        <>
            <div className="faq_body_internal">
            <BackToFaq state={state} setState={setState} />
            <h1>Privacy Policy</h1>
            <p>Last updated: January 1, 2024</p>
            <p>This privacy policy for PonVPN describes how and why we might collect, store and process your information when you use our services, such as when you: </p>
            <ul>
                <li>Use our website at ponvpn.com</li>
                <li>Use our Telegram Bot @ponvpnbot</li>
            </ul>
            <p>If you do not agree with our policies and practices, do not use our services. If you still have any questions or concerns, contact us at feedback@ponvpn.com</p>

            <h1>Interpretation and Definitions</h1>
            <h2>Interpretation</h2>
            <p>The following definitions shall have the same meaning regardless of whether they appear in
                singular or in plural.</p>
            <h2>Definitions</h2>
            <p>For the purposes of this Privacy Policy:</p>
            <ul>
                <li>
                    <p><strong>Account</strong> means a unique account created for You to access our Service or parts of
                        our Service.</p>
                </li>
                <li>
                    <p><strong>Company</strong> (referred to as either &quot;the
                        Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to
                        PonVPN</p>
                </li>
                <li>
                    <p><strong>Cookies</strong> are small files that are placed on Your computer, mobile device or any
                        other device by a website, containing the details of Your browsing history on that website among
                        its many uses.</p>
                </li>
                <li>
                    <p><strong>Device</strong> means any device that can access the Service such as a computer, a
                        cellphone or a digital tablet.</p>
                </li>
                <li>
                    <p><strong>Personal Data</strong> is any information that relates to an identified or identifiable
                        individual.</p>
                </li>
                <li>
                    <p><strong>Service</strong> refers to the Website or Telegram Bot.</p>
                </li>
                <li>
                    <p><strong>Usage Data</strong> refers to data collected automatically, either generated by the use
                        of the Service or from the Service infrastructure itself (for example: logs about purchasing and balance popup. it is neccessary in case of software errros).</p>
                </li>
                <li>
                    <p><strong>Website</strong> refers to ponvpn.com</p>
                </li>
                <li>
                    <p><strong>You</strong> means the individual accessing or using the Service, or the company, or
                        other legal entity on behalf of which such individual is accessing or using the Service, as
                        applicable.</p>
                </li>
            </ul>
            <h1>Collecting and Using Your Personal Data</h1>
            <h2>Types of Data Collected</h2>
            <h3>Personal Data</h3>
            <p>While using Our Service, We may ask You to provide Us with certain personally identifiable information
                that can be used to contact or identify You. Personally identifiable information is
                limited to:</p>
            <ul>
                <li>
                    <p>Email address (if provided)</p>
                </li>
                <li>
                    <p>Telegram ID (if you are using our Telegram Bot)</p>
                </li>
                <li>
                    <p>Usage Data</p>
                </li>
            </ul>
            <h3>Usage Data</h3>
            <p>Usage Data is collected automatically when using the Service.</p>
            <p>Usage Data is information such as:</p>
            <ul>
                <li>
                    <p>Which services have you bought via our Service and when did you do that (it is neccessary for billing purposes)</p>
                </li>
            </ul>
            <p>We dont collect the type of device You use, Your device
                unique ID, Your operating system, the type of
                Internet browser You use, unique device identifiers and other diagnostic data.</p>
            <h3>Cookies</h3>
            <p>We use Cookies to store certain information. This information is limited to:</p>
            <ul>
                <li><strong>JWT authorization token</strong> A text string received after logging in to the site. Used so that we can identify you and give you access to your services
                </li>
                <li><strong>Preferred language</strong> Language of the Site.</li>
                <li><strong>Preferred theme</strong> Theme of the site (dark on light)</li>
            </ul>
            <h2>Use of Your Personal Data</h2>
            <p>The PonVPN may use Personal Data for the following purposes only:</p>
            <ul>
                <li>
                    <p><strong>To manage Your Account.</strong> Telegram ID (if telegram used) allows you to manage Your registration as a user of the Service.
                        Telegram ID is neccessary to give You access to different functionalities of the Service
                        that are available to You as a registered user.</p>
                </li>
                <li>
                    <p><strong>To contact You.</strong> To contact You by email when it is neccessary. We do not verify your email, so if it is incorrect, we will can not to contact you at all</p>
                </li>
            </ul>
            <h2>Retention of Your Personal Data</h2>
            <p>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in
                this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with
                our legal obligations (for example, if we are required to retain your data to comply with applicable
                laws), resolve disputes, and enforce our legal agreements and policies.</p>
            <h2>Delete Your Personal Data</h2>
            <p>You have the right to request that We assist in deleting the Personal Data that We have
                collected about You.</p>
            <p>You may update, amend, or delete Your information at any time by signing in to Your Account, if you have
                one, and visiting the account settings section that allows you to manage Your personal information. You
                may also contact Us to request access to, correct, or delete any personal information that You have
                provided to Us.</p>
            <p>Please note, however, that We may need to retain certain information when we have a legal obligation or
                lawful basis to do so.</p>
            <h2>Transfer and Disclosure of Your Personal Data</h2>
            <h3>Law enforcement</h3>
            <p>We can transfer or disclosure your personal data only if the Company is required to do
                so by law enforcement or in response to valid requests by public authorities (e.g. a court or a government
                agency).</p>
            <h2>Security of Your Personal Data</h2>
            <p>The security of Your Personal Data is important to Us, but remember that no method of transmission over
                the Internet, or method of electronic storage is 100% secure. While We strive to use commercially
                acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</p>
            <h1>Children's Privacy</h1>
            <p>Our Service does not address anyone under the age of 18. We do not knowingly collect personally
                identifiable information from anyone under the age of 18. If You are a parent or guardian and You are
                aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We
                have collected Personal Data from anyone under the age of 18 without verification of parental consent,
                We take steps to remove that information from Our servers.</p>
            <h1>Links to Other Websites</h1>
            <p>Our Service may contain links to other websites that are not operated by Us. If You click on a third
                party link, You will be directed to that third party's site. We strongly advise You to review the
                Privacy Policy of every site You visit.</p>
            <p>We have no control over and assume no responsibility for the content, privacy policies or practices of
                any third party sites or services.</p>
            <h1>Changes to this Privacy Policy</h1>
            <p>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new
                Privacy Policy on this page.</p>
            <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy
                Policy are effective when they are posted on this page.</p>
            <h1>Contact Us</h1>
            <p>If you have any questions about this Privacy Policy, You can contact us:</p>
            <ul>
                <li>By email: feedback@ponvpn.com</li>
            </ul>
            </div>
        </>
    )
}