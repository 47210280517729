import React from "react";

import { NavLink, useNavigate } from "react-router-dom";

import Loading from "js/pages/common/Loading";

import QRCode from "react-qr-code";

import { Requester } from "js/logic/Requester";
import { CabLang } from "js/languages/cabinet/cabinet";


export function Monero({ state }) {

    const lang = state.language;

    const navigate = useNavigate();

    const [loading, setLoading] = React.useState(state.user.data.moneroAddress === "");

    React.useEffect(() => {
        if (state.user.data.moneroAddress === "") getUser();
    }, []);

    async function getUser() {
        let ans = await Requester.me();
        if (ans === Requester.unathe) {
            navigate("/enter")
            return;
        }
        state.user.setMoneroAddress(ans.user.moneroAddress);
        setLoading(false);
    }

    return (
        <>
            <div className="cabinet_back_to_menu_wrapper">
                <NavLink className="common_fancy_button" to="/cabinet/profile">{CabLang.menu_back[lang]}</NavLink>
            </div>
            <div className="cabinet_body_internal">

                <div className="cabinet_title">Monero</div>

                <div className="cabinet_paragraph">
                    <span className="constructor_paragraph_text">
                        {CabLang.profile_monero_info_1[lang]}
                    </span>
                </div>
                <div className="cabinet_paragraph">
                    <span className="constructor_paragraph_text">
                        {CabLang.profile_monero_info_2[lang]}
                    </span>
                </div>


                {state.user.data.moneroAddress !== "" &&
                    <>
                        <div className="cabinet_paragraph">
                            <span className="monero_address">
                                {state.user.data.moneroAddress}
                            </span>
                        </div>
                        <div className="monero_qr">
                            <QRCode className="monero_qr_image"
                                size={256}
                                value={state.user.data.moneroAddress}
                                viewBox={`0 0 256 256`}
                            />
                        </div>
                    </>
                }

                {loading && <Loading text={"Грузим адрес"} state={state} />}
            </div>
        </>
    );
}