function parseToken(token) {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}


function setCookie(cname, cvalue) {
    const d = new Date();
    d.setTime(d.getTime() + (24 * 60 * 60 * 1000)); // 1 = day
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

function deleteCookie(name) {
    document.cookie = name + '=; Max-Age=0; path=/'
}

function checkToken() {
    const token = getCookie("token");
    if (token == null || token === "") {
        return false;
    }
    const now = Math.floor(new Date().getTime() / 1000);
    const expired = parseToken(token).exp < now;
    if (expired) {
        deleteCookie("token");
        return false;
    }
    return true;
}


export const Cookier = {
    checkToken,
    saveToken: (token) => {
        setCookie("token", token);
    },
    saveTokenAndSetStateLogined(statePair, token) {
        setCookie("token", token);
        let newState = { ...statePair.i };
        newState.logined = true;
        newState.token = token;
        statePair.set(newState);
    },
    getToken: () => getCookie("token"),
    getTheme: () => {
        let theme = getCookie("themeLight");
        return theme == null || theme === "" || theme === "true";
    },
    deleteToken: () => deleteCookie("token"),
    getUsername: () => parseToken(getCookie("token")).sub,

    saveThemeAndSetState: (statePair, themeLight) => {
        setCookie("themeLight", themeLight);
        let newState = { ...statePair.i };
        newState.themeLight = themeLight;
        statePair.set(newState);
    },
    getLanguage: () => getCookie("language"),
    setLanguage: (language) => setCookie("language", language),
}
