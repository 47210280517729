import { CabLang } from "js/languages/cabinet/cabinet";
import BackToFaq from "js/pages/common/BackToFaq";


export default function MainProtocols({ state, setState }) {

    const lang = state.language;
    return (
        <>
            <div className="faq_body_internal">
            <BackToFaq state={state} setState={setState} />
            <div className="common_protocol_title">PonVPN FAQ</div>

                <span className="constructor_paragraph_text">
                    {CabLang.faq_protocols_info[lang]}
                </span>
            </div>
        </>
    )
}